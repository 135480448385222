import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  FormLabel,
  Grid,
  LinearProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  BasicMenuItem,
  BasicSelect,
} from "../../ReuseComponents/Select/BasicSelect";
import {
  CancelButton,
  ContainedButton,
} from "../../ReuseComponents/Button/BasicButton";
import Controller from "../../../Controller/ApiController";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { OutlinedTextField } from "../../ReuseComponents/TextField/BasicTextField";
import validationRegExp from "../../../Configuration/ValidationConfig";
import { Alert } from "../../ReuseComponents/Alert/Alertmessage";
import QRIVRBookingPopup from "../../ReuseComponents/Popup/QRIVRBookingPopup";
import UtilDateTime from "../../../Constant/utillDateTime";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import QGLogo from "../../../Assets/qgliderlogo.png";
import InfoIcon from "../../../Assets/icons/infoIcon.svg";
import ConfirmationPop from "../../ReuseComponents/Popup/ConfirmationPop";
import { MuiOtpInput } from "mui-one-time-password-input";

function TimerCircularProgress(props) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: "#D9D9D9",
        }}
        size={90}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={(theme) => ({
          color: "#41BA8F",
          animationDuration: "1000ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
          ...theme.applyStyles("dark", {
            color: "#000",
          }),
        })}
        size={90}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

export default function RemoveClinic() {
  const initialFormData = {
    mobileNumber: {
      value: "",
      error: false,
    },
    otp: {
      value: "",
      error: false,
    },
  };
  const [loaderOpen, setLoaderOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [mobileVerified, setMobileVerified] = useState(false);
  const [popupData, setPopUpData] = useState({
    open: false,
    Purpose: "",
    message: "",
  });
  const [otpSentMessage, setOtpSentMessage] = useState("");

  const handleLoaderOpen = () => {
    setLoaderOpen(true);
  };

  const handleLoaderClose = () => {
    setLoaderOpen(false);
  };

  //VERIFY OTP
  const handleVerifyOTP = async (data) => {
    try {
      handleLoaderOpen();
      let response = await Controller.ApiController(
        "DELETE",
        `/verify/remove_account/${formData.mobileNumber.value}`,
        "",
        { otp: formData.otp.value }
      );
      handleLoaderClose();
      setPopUpData({
        open: false,
        Purpose: "",
        message: "",
      });
      if (response.type == "success") {
        setMobileVerified(false);
        setFormData(initialFormData);
        Alert("success", response?.message || "")
      } else {
        Alert(
          "error",
          response.error.message ||
            "An unexpected error occurred. Please try again."
        );
      }
    } catch (e) {
      handleLoaderClose();
      Alert("error", "An unexpected error occurred. Please try again.");
      console.log("Confirm OTP Error: ", e.message);
    }
  };

  //FUNCTION FOR GO BACK TO HOME PAGE
  const handleGoBack = () => {
    setFormData((prevData) => ({
      ...prevData,
      otp: {
        value: "",
        error: false,
      },
      mobileNumber: {
        value: "",
        error: false,
      },
    }));
    setMobileVerified(false);
  };

  const handleCloseModal = () => {
    if (popupData.type == "Confirmation") {
      setFormData(initialFormData);
    }
    setPopUpData({
      open: false,
      Purpose: "",
      message: "",
    });
  };

  const handleChange = (e) => {
    if (!e.target) {
      setFormData((val) => ({
        ...val,
        otp: { value: e, error: false },
      }));
      return;
    }

    const { name, value, id } = e.target;

    if (!validationRegExp.noEmptySpaceStart(e.target.value)) {
      return;
    }

    if (id && !validationRegExp[id](value)) {
      return;
    }
    let formValueUpdate = {};

    setFormData((val) => ({
      ...val,
      ...formValueUpdate,
      [name]: { value: value, error: false },
    }));
  };

  //FORM SUBMIT
  const handleSubmit = async () => {
    try {
      let formValue = formData;
      let formError = false;
      if (!mobileVerified) {
        if (!formValue?.mobileNumber?.value) {
          formValue.mobileNumber.error = true;
          formError = true;
        }

        if (formError) {
          setFormData({ ...formValue });
          return;
        }
        handleLoaderOpen();
        let response = await Controller.ApiController(
          "POST",
          "/remove_account_otp",
          "",
          { mobile_number: formData.mobileNumber.value }
        );
        handleLoaderClose();

        if (response.type == "success") {
          setMobileVerified(true);
          Alert("success", response?.message || "")
          setOtpSentMessage(
            `OTP has been sent to your mobile number ending with ${formData.mobileNumber.value.slice(
              -4
            )}`
          );
        } else {
          Alert(
            "error",
            response.error.message ||
              "An unexpected error occurred. Please try again."
          );
        }
      } else {

        handleVerifyOTP();
        
        // setPopUpData({
        //   open: true,
        //   Purpose: "Confirmation",
        //   message: `Are you sure you want to \n delete the account?`,
        // });
      }
    } catch (e) {
      handleLoaderClose();
      Alert("error", "An unexpected error occurred. Please try again.");
      console.log("Confirm Mobile Number Error: ", e.message);
    }
  };

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box sx={{ width: "100%" }} display={"flex"} justifyContent={"center"}>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: { xs: "20px", sm: "30px" },
          borderRadius: "10px",
          textAlign: "center",
          width: { xs: "320px", sm: "400px" },
        }}
      >
        <Box
          component={"img"}
          src={QGLogo}
          sx={{
            width: { xs: "159px", md: "256px" },
            height: { xs: "69px", md: "91px" },
            mb: 4,
          }}
        />
        <Typography
          fontSize={"25px"}
          mb={3}
          fontFamily={"Poppins"}
          color={"#000"}
          fontWeight={700}
        >
          Delete Account
        </Typography>
        <Box
          component={"form"}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Grid container mb={2} alignItems="center">
            <Grid item xs={5} sm={6} sx={{ textAlign: "left" }}>
              <FormLabel
                htmlFor="patientName"
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#000000",
                  whiteSpace: "nowrap",
                  fontFamily: "Poppins",
                }}
              >
                {isSm && "Enter the "}Mobile Number
              </FormLabel>
            </Grid>
            <Grid item xs={7} sm={6}>
              <OutlinedTextField
                id="mobileNumber"
                className="patientname"
                placeholder="Enter Here"
                name="mobileNumber"
                variant="outlined"
                size="small"
                error={formData?.mobileNumber?.error}
                value={formData?.mobileNumber?.value || ""}
                onChange={handleChange}
                disabled={mobileVerified}
                sx={{ fontFamily: "Poppins" }}
              />
            </Grid>
          </Grid>
          {mobileVerified && (
            <Grid container mb={2} alignItems="center">
              <Grid item xs={5} sm={6} mt={1} sx={{ textAlign: "left" }}>
                <FormLabel
                  htmlFor="patientName"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#000000",
                    whiteSpace: "nowrap",
                    fontFamily: "Poppins",
                  }}
                >
                  {isSm && "Enter the "}OTP
                </FormLabel>
              </Grid>
              <Grid item xs={7} sm={6} mt={1}>
                <MuiOtpInput
                  name="otp"
                  value={formData.otp.value}
                  onChange={handleChange}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "40px",
                    },
                    "& .MuiInputBase-input": {
                      padding: "0px"
                    }
                  }}
                  TextFieldsProps={{
                    placeholder: "-",
                    inputProps: {
                      style: { color: "#000000", fontSize: "17px" },
                    },
                  }}
                  height={70}
                />
              </Grid>
              {/* {otpSentMessage && (
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "400",
                      color: "#999999",
                      marginTop: "10px",
                    }}
                  >
                    {otpSentMessage}
                  </Typography>
                </Grid>
              )} */}
            </Grid>
          )}
          <Box mt={3}>
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              {mobileVerified && (
                <CancelButton
                  onClick={handleGoBack}
                  sx={{
                    mr: 2,
                    width: "130px",
                    height: "35px",
                    fontFamily: "Poppins",
                  }}
                >
                  Go Back
                </CancelButton>
              )}
              <ContainedButton
                type={"submit"}
                sx={{
                  fontSize: "15px",
                  width: "130px",
                  height: "35px",
                  lineHeight: 1,
                  fontFamily: "Poppins",
                }}
              >
                Submit
              </ContainedButton>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* {popupData.open && (
        <ConfirmationPop
          Data={popupData}
          yesSubmit={handleConfirmModal}
          NoSubmit={handleCloseModal}
        />
      )} */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 100000 }}
        open={loaderOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
