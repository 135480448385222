import React from 'react'
import { Routes, Route } from "react-router-dom";
import Loginpage from '../Components/pages/Login/Loginpage';
import ForgotPassword from '../Components/pages/Forgotpassword/ForgotPassword';
import SignupDoctor from '../Components/pages/SingUp/SignupDoctor';
import SignupOtp from '../Components/pages/SingUp/SignupOtp';
import ResetPassword from '../Components/pages/Forgotpassword/ResetPassword';
import VerifyEmail from '../Components/pages/VerifyEmail/VerifyEmail';
import QRIVRBooking from "../Components/pages/QRGenerator/QRIVRBooking";
import TermsAndConditions from '../Components/pages/TermsAndConditions/termsAndCondition';
import PrivacyPolicy from '../Components/pages/TermsAndConditions/privacyPolicy';
import ContactUS from '../Components/pages/ContactUs/contactUs';
import RemoveClinic from '../Components/pages/RemoveClinic/removeClinic';

export default function LoginRoute() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Loginpage />} />
                <Route path="/login" element={<Loginpage />} />
                <Route path="/signup" element={<SignupDoctor />} />
                <Route path="/signupotp" element={<SignupOtp />} />
                <Route path="/forgetpassword" element={<ForgotPassword />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path='/verify_email' element={<VerifyEmail />} />
                <Route path='/qr-ivr-booking/:doctor_uid' element={<QRIVRBooking />} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/contact-us' element={<ContactUS />} />
                <Route path='/delete-account' element={<RemoveClinic />} />
            </Routes>
        </div>
    )
}
