// ------------- React Library -----------

import * as React from "react";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
// ------------ custom component and Files -----------
import "./App.css";
import LoginRoute from "./Routes/LoginRoute";
import { useEffect, useState } from "react";
import HomePage from "./Components/pages/Home/HomePage";
import "./ActivePage/page";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Controller from "./Controller/ApiController";
import { useDispatch, useSelector } from "react-redux";
import { updateAppointmentData } from "./Components/Assets/Redux/features/StoreData/settingAppointmentData";
import { accessValue } from "./Components/Assets/Redux/features/Access/accessPermisson";
import { updateUserData } from "./Components/Assets/Redux/features/StoreData/userDetails";
import { updateFilterData } from "./Components/Assets/Redux/features/StoreData/filterData";
import dayjs from "dayjs";
// ------------ custom icons and images -------

const navItems = [
  {
    url: "/dashboard",
    key: "DASHBOARD",
  },

  {
    url: "/manageappointments",
    key: "MANAGE_APPOINTMENTS",
  },
  {
    url: "/manageusers",
    key: "MANAGE_USERS",
  },
  {
    url: "/all-settings",
    key: "SETTINGS",
  },
  {
    url: "/settings",
    key: "SETTINGS",
  },
  {
    url: "/generateQR",
    key: "QR_GENERATE",
  },
  {
    url: "/reports",
    key: "REPORTS",
  },
  {
    url: "/Profile",
    key: "PROFILE",
  },
];

const drawerWidth = 280;
function App() {
  let dispatch = useDispatch();
  const updateCount = useSelector((state) => state.updateRequest.update_count);
  const updateSetting = useSelector(
    (state) => state.updateRequest.update_setting
  );
  const storedDate = useSelector((state) => state.dateFilter.date);
  const userDetails = useSelector((state) => state.userDetails.data);
  const filterData = useSelector((state) => state?.filterData?.data || {});
  var accessPermissonValue = useSelector(
    (state) => state.accessPermissionValue.access_values
  );
  const Location = useLocation();
  const navigate = useNavigate();
  const isLogIn =
    Location.pathname === "/" ||
    Location.pathname === "/login" ||
    Location.pathname === "/signup" ||
    Location.pathname === "/signupotp" ||
    Location.pathname === "/forgetpassword" ||
    Location.pathname === "/resetpassword" ||
    Location.pathname === "/forgetpassword" ||
    Location.pathname === "/verify_email" ||
    Location.pathname.includes("/qr-ivr-booking") ||
    Location.pathname.includes("/terms-and-conditions") ||
    Location.pathname.includes("/privacy-policy") ||
    Location.pathname.includes("/contact-us") ||
    Location.pathname.includes("/delete-account");
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const currentDay = new Intl.DateTimeFormat("en-US", {
    weekday: "long",
  }).format(currentDateTime);
  const [availableToday, setAvailableToday] = useState("");

  let user_type = localStorage.getItem("user_type");

  const fetchUserData = async () => {
    let adminUid = localStorage.getItem("DoctorUid");
    let userUid = localStorage.getItem("user_uid");
    let updateUserDetails = userDetails;
    if (user_type == "SUPER_ADMIN") {
      let res = await Controller.ApiController(
        "GET",
        `/doctor/${adminUid}`,
        "",
        ""
      );
      if (res.type == "success") {
        const data = res.data;
        updateUserDetails = {
          ...updateUserDetails,
          admin_uid: data?.doctor_uid,
          admin_name: data?.full_name,
          user_uid: "",
          user_full_name: data?.full_name,
          user_type: data?.user_type,
          hospital_or_clinic_name: data?.hospital_or_clinic_name,
          filter: true,
          maped_doctor: [],
          finance_module: data?.finance_module || false,
          clinic_qr: data?.clinic_qr_code || "",
          qg_charge: data?.qg_charge,
        };
      }
      dispatch(
        accessValue([
          "SETTINGS",
          "MANAGE_USERS",
          "MANAGE_APPOINTMENTS",
          "DASHBOARD",
          "REPORTS",
          "QR_GENERATE",
          "GENERATED",
          "PROFILE",
        ])
      );
    } else if (user_type) {
      let res = await Controller.ApiController(
        "GET",
        `/manage_users/${userUid}`,
        "",
        ""
      );
      if (res.type == "success") {
        const data = res.data;
        updateUserDetails = {
          ...updateUserDetails,
          admin_uid: data?.doctor?.doctor_uid || "",
          admin_name: data?.doctor?.full_name || "",
          user_uid: data?.user_uid || "",
          user_full_name: data?.full_name || "",
          user_type: data?.user_type || "",
          hospital_or_clinic_name: data?.doctor?.hospital_or_clinic_name || "",
          filter: true,
          maped_doctor: data?.maped_doctor || [],
          finance_module: data?.doctor?.finance_module || false,
          clinic_qr: data?.doctor?.clinic_qr_code || "",
          qg_charge: data?.doctor?.qg_charge,
        };
        dispatch(
          accessValue([
            ...data.access_permissions,
            "QR_GENERATE",
            "GENERATED",
            "PROFILE",
          ])
        );
      }
    }
    dispatch(updateUserData(updateUserDetails));
  };

  useEffect(() => {
    // if(!isLogIn && !Location.pathname.includes("/patientqrscan")) {
    fetchUserData();
    // }
  }, [updateCount]);

  // console.log(isLogIn)

  useEffect(() => {
    async function consultationCheck() {
      let adminUid = localStorage.getItem("DoctorUid");
      let userUid = localStorage.getItem("user_uid");
      const date = new Date(storedDate);
      const options = { weekday: "long" };
      let dayString = date.toLocaleDateString("en-US", options);
      try {
        var queryParams = {
          "filter.date": storedDate,
        };
        let res = await Controller.ApiController(
          "GET",
          `/dashboard/${userUid || adminUid}`,
          "",
          queryParams
        );

        if (res.type == "success") {
          const getData = res.data;
          dispatch(
            updateAppointmentData(
              getData.consultation ? "APPOINTMENT" : "NO_APPOINTMENT"
            )
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
    // if(!isLogIn && !Location.pathname.includes("/patientqrscan")) {
    consultationCheck();
    // }
  }, [updateSetting, storedDate, updateCount]);

  useEffect(() => {
    if (!isLogIn && !Location.pathname.includes("/patientqrscan")) {
      let uid = localStorage.getItem("DoctorUid");
      if (uid == null || uid == undefined || uid == "") {
        navigate("/login");
      }
    }
  }, [Location]);

  useEffect(() => {
    if (
      accessPermissonValue.includes("GENERATED") &&
      !navItems.some((value) => Location.pathname.includes(value.url))
    ) {
      dispatch(accessValue([]));
    } else if (
      accessPermissonValue.includes("GENERATED") &&
      !navItems.some(
        (value) =>
          accessPermissonValue.includes(value.key) &&
          Location.pathname.includes(value.url)
      )
    ) {
      navigate(-1);
    }
  }, [Location, accessPermissonValue]);

  return (
    <>
      <div className="AppGlider">
        {isLogIn ? (
          <Box>
            <LoginRoute />
          </Box>
        ) : (
          <Box>
            <HomePage />
          </Box>
        )}
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          transition={Zoom}
          theme="colored"
          toastStyle={{ width: "125%" }}
        />
      </div>
    </>
  );
}

export default App;
