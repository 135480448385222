import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  TablePagination,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Button,
  Avatar,
  Typography,
  Paper,
  Tooltip,
} from "@mui/material";
import "./manageusers.css";

// ------ custom import files --------

import TableCardData from "../../../Constant/Dashboard.json";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import BioDetails from "../../ReuseComponents/Bio/BioDetails";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../ReuseComponents/BreadCrumbs/BreadCrumbs";
import Controller from "../../../Controller/ApiController";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert } from "../../ReuseComponents/Alert/Alertmessage";
import ConfirmationPop from "../../ReuseComponents/Popup/ConfirmationPop";
import Header from "../../ReuseComponents/Header/Header";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
};

export default function ManageUsers() {
  const [UserData, setUserData] = useState();
  const [open, setOpen] = useState(false);
  const [loaderopen, setloaderOpen] = React.useState(false);
  let doctor_id = localStorage.getItem("DoctorUid");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [popupData, setPopUpData] = useState({
    Purpose: "",
    message: "",
    DataDetails: "",
  });
  let UserTypeAccess = localStorage.getItem("user_type");
  const filterData = useSelector((state) => state.filterData.data);
  const userDetails = useSelector((state) => state.userDetails.data);
  const [reRenderFunction, setReRenderFunction] = useState(false);

  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };

  useEffect(() => {
    if (userDetails.filter && filterData.filter) {
      startOfThePage();
    }
  }, [count, filterData, userDetails]);

  useEffect(() => {
    if (reRenderFunction) {
      const currentUrl = new URL(window.location.href);
      let pageNumber = currentUrl.searchParams.get("page");
      let perPage = currentUrl.searchParams.get("perPage");
      pageNumber = parseInt(pageNumber) || 0;
      perPage = parseInt(perPage) || 0;
      if (pageNumber > 0 || perPage > 4) {
        FetchData(pageNumber, perPage);
        setPage(pageNumber);
        setRowsPerPage(perPage);
      } else {
        startOfThePage();
      }
    } else {
      setReRenderFunction(true);
    }
  }, []);

  const FetchData = async (currentPage, perPage) => {
    handleLoaderOpen();
    let queryParams = {
      "filter.doctor_uid": doctor_id,
      page: currentPage + 1,
      count: perPage,
    };

    if (
      userDetails.user_uid &&
      ["SCAN", "DOCTOR"].includes(userDetails.user_type)
    ) {
      queryParams = {
        ...queryParams,
        "filter.user_uid": userDetails.user_uid,
        "filter.user_uid_type": "MAPED_USER",
      };
    } else if (
      filterData.user_uid.length > 0 &&
      userDetails.user_type == "SUPER_ADMIN"
    ) {
      queryParams = {
        ...queryParams,
        "filter.user_uid": filterData.user_uid.join(","),
        "filter.user_uid_type": "MAPED_USER",
      };
    } else if (filterData.user_uid.length > 0) {
      queryParams = {
        ...queryParams,
        "filter.user_uid": filterData.user_uid.join(","),
      };
    } else if (filterData.maped_user_uid.length > 0) {
      queryParams = {
        ...queryParams,
        "filter.user_uid": filterData.maped_user_uid.join(","),
      };
    } else if (
      userDetails.user_uid &&
      !["SCAN", "DOCTOR"].includes(userDetails.user_type)
    ) {
      queryParams = {
        ...queryParams,
        "filter.user_uid": userDetails.user_uid,
        "filter.user_uid_type": "MAPED_DOCTOR",
      };
    }

    let response = await Controller.ApiController(
      "GET",
      "/manage_users",
      "",
      queryParams
    );
    handleLoaderClose();
    if (response.type == "success") {
      setUserData(response);
    }
  };

  const handleOpen = (deleteDetails) => {
    setPopUpData({
      Purpose: "Delete",
      message: `Are you sure you want to delete the user ${
        deleteDetails.full_name ? deleteDetails.full_name : ""
      }`,
      DataDetails: deleteDetails,
    });
    setOpen(true);
  };

  const yesSubmit = (Data) => {
    DeleteUser(Data.DataDetails);
  };

  const PopUpClose = () => {
    setOpen(false);
  };

  const DeleteUser = async (userData) => {
    handleLoaderOpen();

    if (userData) {
      let res = await Controller.ApiController(
        "DELETE",
        "/manage_users/" + userData.user_uid
      );
      handleLoaderClose();

      if (res.type == "success") {
        Alert("success", "User deleted successfully");
        FetchData();
        setOpen(false);
        setCount((current) => current + 1);
      } else {
        Alert("error", res?.error?.message || "Something went wrong");
        setOpen(false);
      }
    }
  };
  // const handleChangePage = () =>{

  // }

  const startOfThePage = () => {
    addURLQueryParam(0, 15);
    FetchData(0, 15);
    setPage(0);
    // setRowsPerPage(15);
  };

  const handleChange = (event, value) => {
    setPage(value);
    addURLQueryParam(value, rowsPerPage);
    FetchData(value, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    addURLQueryParam(0, parseInt(event.target.value));
    setPage(0);
    FetchData(0, event.target.value);
  };

  const addURLQueryParam = (page, rowsPerPage) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", page);
    url.searchParams.set("perPage", rowsPerPage);
    window.history.replaceState({}, "", url);
  };

  const convertFirstLetterToUpperCase = (inputString) => {
    return inputString
      .toLowerCase()
      .split(/[ _]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <Box>
      <Box>
        <Header />
      </Box>
      <div
        style={{ marginTop: { xs: "0px", sm: "17px" }, marginBottom: "25px" }}
      >
        <BreadCrumbs
          crumbs={["Dashboard", "Manage Users"]}
          paths={["/dashboard", "/manageusers"]}
        />
      </div>

      <Grid
        container
        spacing={0}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "block",
                lg: "block",
                xl: "block",
              },
            }}
          >
            <BioDetails />
          </Box>
          <Box
            sx={{
              padding: "15px 0px",
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            <h3>Manage User</h3>
          </Box>
        </Grid>
        <Grid
          sx={{ textAlign: { sm: "right", xs: "left" } }}
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Grid>
            <Button
              component={Link}
              to="/manageusers/addusers"
              sx={{
                backgroundColor: "#243665",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                color: "#fff !important",
                textTransform: "none",
                borderRadius: "50px",
                padding: "3px 10px",
                "&:hover": { background: "#243665" },
              }}
            >
              {/* <AddOutlinedIcon sx={{ color: "#fff", fontSize:"18px" }} /> */}
              <span style={{ display: "flex" }}>
                <AddOutlinedIcon sx={{ color: "#fff", fontSize: "18px" }} />
              </span>{" "}
              Add User
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0} mt={1}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          sx={{ marginBottom: { xs: "50px", md: "0px" } }}
        >
          <Paper sx={{ width: { sm: "100%", xs: "380px" } }}>
            <TableContainer>
              <Table
                // stickyHeader
                sx={{ minWidth: { sm: "800px", xs: "440px" } }}
                aria-label="customized table"
              >
                <colgroup>
                  {/* <col width="15%" />
                    <col width="15%" />
                    <col width="15%" />
                    <col width="15%" />
                    <col width="15%" />
                    <col width="15%" /> */}
                </colgroup>
                <TableHead>
                  <TableRow sx={{ whiteSpace: { sm: "normal", xs: "nowrap" } }}>
                    {TableCardData.tableRowHead3.map((RowHeadData, index) => (
                      <TableCell
                        style={{
                          color: "#676666",
                          fontSize: "14px",
                          fontWeight: 600,
                          padding: "5px",
                          backgroundColor: "#EEF0FA",
                          justifyContent: "center",
                          whiteSpace: "nowrap",
                        }}
                        key={index}
                      >
                        {RowHeadData.TblHead}
                      </TableCell>
                    ))}
                    {/* <TableCell  className="tableHeadStyl" >
                            Full Name
                          </TableCell>
                          <TableCell  className="tableHeadStyl" >
                            User-Type
                          </TableCell>
                          <TableCell className="tableHeadStyl" >
                            Mobile Number
                          </TableCell>
                          <TableCell  className="tableHeadStyl" >
                            For Doctor
                          </TableCell>
                          <TableCell  className="tableHeadStyl" >
                            Access To
                          </TableCell>
                          <TableCell sx={{width:"500px !important"}} className="tableHeadStyl" >
                              Actions
                          </TableCell> */}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {UserData && UserData?.data?.length > 0 ? (
                    UserData?.data?.map((RowCellData, index) => (
                      <TableRow key={index}>
                        <TableCell
                          sx={{ width: "10%" }}
                          className="RowCellData"
                        >
                          <Tooltip
                            title={RowCellData.full_name}
                            arrow
                            slotProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: "#f5f5f9",
                                  color: "rgba(0, 0, 0, 0.87)",
                                  maxWidth: 220,
                                  maxHeight: "30px",
                                  border: "1px solid #dadde9",
                                  "& .MuiTooltip-arrow": { color: "#f5f5f9" },
                                },
                              },
                            }}
                          >
                            <Typography
                              style={{ fontSize: "14px" }}
                              className="typoData"
                            >
                              {RowCellData.full_name}
                            </Typography>
                          </Tooltip>
                          {/* <div> <Typography sx={{textOverflow:"ellipsis",overflow:"hidden",whiteSpace:"nowrap",maxWidth:"25%"}} >checkkkkkkkkkkkkkkkkkkllllllllllll</Typography></div> */}
                        </TableCell>
                        <TableCell
                          sx={{ width: "10%" }}
                          className="RowCellData"
                        >
                          <Tooltip
                            title={convertFirstLetterToUpperCase(
                              RowCellData.user_type
                            )}
                            arrow
                            slotProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: "#f5f5f9",
                                  color: "rgba(0, 0, 0, 0.87)",
                                  maxWidth: 220,
                                  maxHeight: "auto",
                                  border: "1px solid #dadde9",
                                  "& .MuiTooltip-arrow": { color: "#f5f5f9" },
                                },
                              },
                            }}
                          >
                            <Typography
                              style={{ fontSize: "14px" }}
                              className="typoData"
                            >
                              {convertFirstLetterToUpperCase(
                                RowCellData.user_type
                              )}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          sx={{ width: "10%" }}
                          className="RowCellData"
                        >
                          <Tooltip
                            title={convertFirstLetterToUpperCase(
                              RowCellData.user_type
                            )}
                            arrow
                            slotProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: "#f5f5f9",
                                  color: "rgba(0, 0, 0, 0.87)",
                                  maxWidth: 220,
                                  maxHeight: "auto",
                                  border: "1px solid #dadde9",
                                  "& .MuiTooltip-arrow": { color: "#f5f5f9" },
                                },
                              },
                            }}
                          >
                            <Typography
                              style={{ fontSize: "14px" }}
                              className="typoData"
                            >
                              {convertFirstLetterToUpperCase(
                                RowCellData.department
                              ) || "-"}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          sx={{ width: "10%" }}
                          className="RowCellData"
                        >
                          <Typography
                            style={{ fontSize: "14px" }}
                            className="typoData"
                          >
                            {RowCellData?.mobile_number || "-"}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: "8%" }} className="RowCellData">
                          <Typography
                            style={{ fontSize: "14px" }}
                            className="typoData"
                          >
                            {Array.isArray(RowCellData?.maped_doctor) &&
                            RowCellData?.maped_doctor.length > 0
                              ? RowCellData?.maped_doctor
                                  ?.map((val) => val.full_name)
                                  .join(", ")
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{ width: "12%" }}
                          className="RowCellData"
                        >
                          <Tooltip
                            title={convertFirstLetterToUpperCase(
                              RowCellData.access_permissions.join(", ")
                            )}
                            arrow
                            slotProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: "#f5f5f9",
                                  color: "rgba(0, 0, 0, 0.87)",
                                  maxWidth: 220,
                                  maxHeight: "auto",
                                  border: "1px solid #dadde9",
                                  "& .MuiTooltip-arrow": { color: "#f5f5f9" },
                                },
                              },
                            }}
                          >
                            <Typography
                              style={{ fontSize: "14px" }}
                              className="typoData"
                            >
                              {convertFirstLetterToUpperCase(
                                RowCellData.access_permissions.join(", ")
                              ) || "-"}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          sx={{ width: "10%" }}
                          className="RowCellData"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "left",
                              marginLeft: "-5px",
                            }}
                          >
                            <Tooltip
                              title="View"
                              arrow
                              slotProps={{
                                tooltip: {
                                  sx: {
                                    backgroundColor: "#f5f5f9",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    border: "1px solid #dadde9",
                                    "& .MuiTooltip-arrow": { color: "#f5f5f9" },
                                  },
                                },
                              }}
                            >
                              {" "}
                              <Button size="small" sx={{ minWidth: "auto" }}>
                                <Link
                                  to={
                                    "/manageusers/Viewusers/" +
                                    RowCellData.user_uid
                                  }
                                >
                                  {" "}
                                  <Avatar
                                    sx={{
                                      boxShadow:
                                        "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                      width: "25px",
                                      height: "25px",
                                      backgroundColor: "#41BA8F",
                                    }}
                                  >
                                    <VisibilityOutlinedIcon
                                      sx={{ color: "#fff", fontSize: "18px" }}
                                    />
                                  </Avatar>
                                </Link>
                              </Button>
                            </Tooltip>

                            <Tooltip
                              title="Edit"
                              arrow
                              slotProps={{
                                tooltip: {
                                  sx: {
                                    backgroundColor: "#f5f5f9",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    border: "1px solid #dadde9",
                                    "& .MuiTooltip-arrow": { color: "#f5f5f9" },
                                  },
                                },
                              }}
                            >
                              <Button size="small" sx={{ minWidth: "auto" }}>
                                <Link
                                  to={
                                    "/manageusers/Editusers/" +
                                    RowCellData.user_uid
                                  }
                                >
                                  <Avatar
                                    sx={{
                                      boxShadow:
                                        "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                      width: "25px",
                                      height: "25px",
                                      backgroundColor: "#243665",
                                    }}
                                  >
                                    <EditIcon
                                      sx={{ color: "#fff", fontSize: "18px" }}
                                    />
                                  </Avatar>
                                </Link>
                              </Button>
                            </Tooltip>

                            <Tooltip
                              title="Delete"
                              arrow
                              slotProps={{
                                tooltip: {
                                  sx: {
                                    backgroundColor: "#f5f5f9",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    border: "1px solid #dadde9",
                                    "& .MuiTooltip-arrow": { color: "#f5f5f9" },
                                  },
                                },
                              }}
                            >
                              <Button
                                // disabled = {UserTypeAccess == "SUPER_ADMIN" || UserTypeAccess == "ADMIN" ? false : true}
                                onClick={() => handleOpen(RowCellData)}
                                size="small"
                                sx={{ minWidth: "auto" }}
                              >
                                <Avatar
                                  sx={{
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                    width: "25px",
                                    height: "25px",
                                    backgroundColor: "#BC123E",
                                  }}
                                >
                                  <DeleteOutlinedIcon
                                    sx={{ color: "#fff", fontSize: "18px" }}
                                  />
                                </Avatar>
                              </Button>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        sx={{ textAlign: { md: "center", xs: "start" } }}
                        colSpan={7}
                      >
                        No User Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {UserData && UserData?.data?.length > 0 && (
            <TablePagination
              component="div"
              rowsPerPageOptions={[15, 25, 50, 100]}
              count={UserData?.total_records}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Grid>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={loaderopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        open={open}
        onClose={PopUpClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ConfirmationPop
          Data={popupData}
          yesSubmit={yesSubmit}
          NoSubmit={() => setOpen(false)}
        />
      </Modal>
    </Box>
  );
}
