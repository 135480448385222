import React from "react";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Button, Modal, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { BasicButton } from "../Button/BasicButton";
import QuestionIcon from "../../../Assets/icons/questionIcon.svg";
import SuccessIcon from "../../../Assets/icons/successIcon.svg";
import AttentionIcon from "../../../Assets/icons/attentionIcon.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  minWidth: 300,
  maxWidth: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  "&:focus": {
    outline: "none",
  },
};

export default function QRIVRBookingPopup({ Data, confirmModal, cancelModal }) {
  return (
    <Modal open={true}>
      <Box sx={style}>
        <Box
          sx={{
            backgroundColor: "#EEF0FA",
            height: "60px",
            width: "100%",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",

            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              position: "relative",
              top: 35,
            }}
          >
            {["RE_CONFIRMATION", "CONFIRMATION", "NO_OF_SLOTS"].includes(Data.type) ? (
              <img
                src={QuestionIcon}
                alt="Question"
                style={{ width: "50px" }}
              />
            ) : Data.type == "BOOKED" ? (
              <img src={SuccessIcon} alt="Success" style={{ width: "50px" }} />
            ) : (
              <img
                src={AttentionIcon}
                alt="Attention"
                style={{ width: "50px" }}
              />
            )}
          </Box>
        </Box>
        <Box sx={{ padding: "20px 30px 30px", textAlign: "center" }}>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 2,
              fontWeight: "bold",
              whiteSpace: "pre-wrap",
              fontFamily: "Poppins",
            }}
            dangerouslySetInnerHTML={{ __html: Data.message }}
          />
        </Box>
        <Box mb={2}>
          {["RE_CONFIRMATION", "CONFIRMATION"].includes(Data.type) ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
                paddingBottom: "15px",
              }}
            >
              <Button
                onClick={() => cancelModal()}
                sx={{
                  backgroundColor: "#D13C3C",
                  textTransform: "none",
                  color: "white",
                  minWidth: "95px",
                  padding: "2px",
                  borderRadius: "20px",
                  "&:hover": {
                    background: "#D13C3C",
                  },
                }}
              >
                {Data.type == "RE_CONFIRMATION" ? "No" : "Go Back"}
              </Button>
              <Button
                onClick={() => confirmModal(Data)}
                sx={{
                  backgroundColor: "#41BA8F",
                  textTransform: "none",
                  color: "white",
                  minWidth: "95px",
                  padding: "2px",
                  borderRadius: "20px",
                  "&:hover": {
                    background: "#41BA8F",
                  },
                }}
              >
                {Data.type == "RE_CONFIRMATION" ? "Yes" : "Confirm"}
              </Button>
            </Box>
          ) : Data.type == "NO_OF_SLOTS" ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
                paddingBottom: "15px",
              }}
            >
              <BasicButton
                onClick={() => confirmModal("ONE")}
                sx={{
                  backgroundColor: "#243665 !important",
                  color: "#fff",
                  minWidth: "95px",
                }}
              >
                One
              </BasicButton>
              <BasicButton
                onClick={() => confirmModal("TWO")}
                sx={{
                  minWidth: "95px",
                }}
              >
                Two
              </BasicButton>
            </Box>
          ) : (
            ["Error_MSG", "ALREADY_BOOKED"].includes(Data.type) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => cancelModal()}
                  sx={{
                    backgroundColor: "#41BA8F",
                    color: "#fff",
                    minWidth: "95px",
                    padding: "2px",
                    borderRadius: "20px",
                    "&:hover": {
                      background: "#41BA8F",
                    },
                  }}
                >
                  Ok
                </Button>
              </Box>
            )
          )}
        </Box>
      </Box>
    </Modal>
  );
}
