import React, { useState, useEffect } from "react";
import BioDetails from "../../ReuseComponents/Bio/BioDetails";
import {
  Grid,
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  MenuItem,
  // Select,
  FormGroup,
  Checkbox,
  Card,
  TextField,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  FormHelperText,
  Tooltip,
  Popover,
  List,
  ListItem,
  ListItemText,
  Chip,
  Divider,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en";
import "dayjs/plugin/duration";
import "dayjs/plugin/utc";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";

import "./setting.css";
import BreadCrumbs from "../../ReuseComponents/BreadCrumbs/BreadCrumbs";
import Save from "../../../Assets/icons/addUserTick.svg";
import editIcon from "../../../Assets/icons/editIcon.svg";
import cancelIcon from "../../../Assets/icons/cancelIcon.svg";

// --------- custom icons ------------
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import Controller from "../../../Controller/ApiController";
import { Alert } from "../../ReuseComponents/Alert/Alertmessage";
import {
  DatePicker,
  DesktopDatePicker,
  DesktopTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmationPop from "../../ReuseComponents/Popup/ConfirmationPop";
import Header from "../../ReuseComponents/Header/Header";
import { updateSetting } from "../../Assets/Redux/features/updateRequest/updateRequest";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AES_ENCRYPT_DECRYPT from "../../../util/encryptDecrypt";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import Doctor from "../../../Assets/dashboard/doctor.svg";
import { BasicSelect } from "../../ReuseComponents/Select/BasicSelect";
import { OutlinedTextField } from "../../ReuseComponents/TextField/BasicTextField";
import SettingConfirmationPopup from "../../ReuseComponents/Popup/SettingConfirmationPop";

const isoDateTimeString1 = "2024-01-29T12:34:56.789Z";
const isoDateTimeString2 = "2024-02-29T12:34:56.789Z";

export default function SettingPage() {
  const [editMode, setEditMode] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [selectedRadioValue, setSelectedRadioValue] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectedDays, setSelectedDays] = useState([]);
  const [morningChecked, setMorningChecked] = useState(false);
  const [qrCheckin, setQrCheckin] = useState(false);
  const [multipleCheckIn, setMultipleCheckIn] = useState(false);
  const [multiplePatientQR, setMultiplePatientQR] = useState(false);
  const [threeTokenAway, setThreeTokenAway] = useState(false);
  const [whatsappNotification, setWhatsappNotification] = useState(false);
  const [whatsappQRNotification, setWhatsappQRNotification] = useState(false);
  const [morningStartTime, setMorningStartTime] = useState("");
  const [morningEndTime, setMorningEndTime] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");

  const [eveningChecked, setEveningChecked] = useState(false);
  const [eveningStartTime, setEveningStartTime] = useState("");
  const [eveningEndTime, setEveningEndTime] = useState("");
  const [selectedSlotDuration, setSelectedSlotDuration] = useState(0);
  const [morningSlots, setMorningSlots] = useState("");
  const [eveningSlots, setEveningSlots] = useState("");
  const [selectedTokenOption, setSelectedTokenOption] = useState("SEQUENTIAL");
  const [prefixSelection, setPrefixSelection] = useState("NO");
  const [ivrSlotLimit, setIvrSlotLimit] = useState("NO");
  const [qrCodeSlot, setQrCodeSlot] = useState("NO");
  const [onlineLimitedSlot, setOnlineLimitedSlot] = useState("NO");
  const [onlineLimitedSlotCount, setOnlineLimitedSlotCount] = useState("NO");
  const [ivrSlotDurationSelection, setIvrSlotDurationSelection] =
    useState("NO");
  const [ivrServerDurationSelection, setIvrServerDurationSelection] =
    useState("NO");
  const [qRSlotDurationSelection, setQRSlotDurationSelection] = useState("NO");
  const [prefixValue, setPrefixValue] = useState("");
  const [iVRSlotLimitValue, setIVRSlotLimitValue] = useState("");
  const [ivrConfigDay, setIvrConfigDay] = useState("1");
  const [ivrConfigSlot, setIvrConfigSlot] = useState("1");
  const [EmergencyDelay, setEmergencyDelay] = useState("");
  const [GeneralDelay, setGeneralDelay] = useState("");
  const [validationError, setValidationError] = useState("");
  const [startTimeError, setStartTimeError] = useState(null);
  const [endTimeError, setEndTimeError] = useState(null);
  const [prefixError, setPrefixError] = useState("");
  const [dataNotFound, setDataNotFound] = useState("");
  const [settingDataLength, setSettingDataLength] = useState(0);
  const [EmergencyNotApplicable, setEmergencyNotApplicable] = useState(false);
  const [GeneralNotApplicable, setGeneralNotApplicable] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fromDateError, setFromDateError] = useState(null);
  const [toDateError, setToDateError] = useState(null);
  const [dayTimeError, setDayTimeError] = useState(null);
  const [isDayCheckboxSelected, setIsDayCheckboxSelected] = useState(false);
  const [defaultFromDate, setDefaultFromDate] = useState(null);
  const [defaultToDate, setDefaultToDate] = useState(null);
  const [availabilityDaysError, setAvailabilityDaysError] = useState(null);
  const [appointmentAvailable, setAppointmentAvailable] = useState("");
  const [settingAvailable, setSettingAvailable] = useState("");
  const [appointmentUID, setAppointmentUID] = useState("");
  const [slotError, setSlotError] = useState("");
  const [currentSetting, setCurrentSetting] = useState(false);
  const [userType, setUserType] = useState("");
  const [setting, setSetting] = useState({});
  const updateSettingValue = useSelector(
    (state) => state.updateRequest.update_setting
  );
  const userDetails = useSelector((state) => state.userDetails.data);
  const [availabilityDays, setAvailableDays] = useState([]);
  const dispatch = useDispatch();
  const [popupData, setPopUpData] = useState({
    Purpose: "",
    message: "",
    DataDetails: "",
    method: "",
  });
  const [userUID, setUserUID] = useState("");
  let { user_uid } = useParams();
  const { pathname } = useLocation();
  const allSettings = pathname.includes("/view-settings");
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [customValues, setCustomValues] = useState([]);
  const [newValue, setNewValue] = useState("");
  const [addDuration, setAddDuration] = useState(true);
  const [timeRange, setTimeRange] = useState({ from: "", to: "" });
  const [serverTimeRange, setServerTimeRange] = useState({ from: "", to: "" });
  const [qrTimeRange, setQrTimeRange] = useState({ from: "", to: "" });
  const [qrSlotValue, setQrSlotValue] = useState("");
  const [notifications, setNotifications] = useState([]);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleSelectValue = (value) => {
    setNewValue(value);
    handleClosePopover();
  };

  const isValidTime = (value) => {
    const timeRegex = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/;
    return timeRegex.test(value);
  };

  const handleAddValue = () => {
    if (
      isValidTime(newValue) &&
      customValues.length < 5 &&
      !customValues.includes(newValue)
    ) {
      // Ensure the newValue is in a valid format
      const time = dayjs(newValue, "HH:mm"); // Adjust the format if necessary
      if (time.isValid()) {
        const hours = time.hour();
        const minutes = time.minute();
        const convertTimeinMinutes = hours * 60 + minutes;
        let value = [...customValues, convertTimeinMinutes];
        value = [...new Set(value)];
        if (convertTimeinMinutes > 240) {
          Alert("error", "Slot duration must be less then 4 hours");
          return;
        }
        setCustomValues(value.sort((a, b) => a - b));
        setNewValue("");
      } else {
        console.error("Invalid time format");
      }
    }
    setAddDuration(true);
  };

  const handleSlotTimeChange = (field, value) => {
    setTimeRange((prev) => ({ ...prev, [field]: time }));
    const today = dayjs(new Date()).startOf("day");
    const time = dayjs(value).startOf("minute");
    // Reset "To" time if "From" time changes to ensure consistency
    if (field === "from") {
      let toTime = dayjs(timeRange.to);
      const fiveMinBefore = dayjs(new Date())
        .hour(23)
        .minute(55)
        .startOf("minute");
      if (time.isAfter(fiveMinBefore)) {
        Alert("warning", "From time must be less then or equal to 11:55 PM");
        setTimeRange((prev) => ({
          ...prev,
          from: fiveMinBefore,
          to: fiveMinBefore.add(1, "minute"),
        }));
      } else if (time.isAfter(toTime) || time.isSame(toTime)) {
        setTimeRange((prev) => ({ ...prev, to: time.add(1, "minute") }));
      }
    }

    if (field === "to") {
      if (time.isSame(today)) {
        Alert("warning", "To time must be greater then from time");
      }
    }
  };

  const handleServerTimeChange = (field, value) => {
    setServerTimeRange((prev) => ({ ...prev, [field]: time }));
    const today = dayjs(new Date()).startOf("day");
    const time = dayjs(value).startOf("minute");
    // Reset "To" time if "From" time changes to ensure consistency
    if (field === "from") {
      let toTime = dayjs(timeRange.to);
      const fiveMinBefore = dayjs(new Date())
        .hour(23)
        .minute(55)
        .startOf("minute");
      if (time.isAfter(fiveMinBefore)) {
        Alert("warning", "From time must be less then or equal to 11:55 PM");
        setServerTimeRange((prev) => ({
          ...prev,
          from: fiveMinBefore,
          to: fiveMinBefore.add(1, "minute"),
        }));
      } else if (time.isAfter(toTime) || time.isSame(toTime)) {
        setServerTimeRange((prev) => ({ ...prev, to: time.add(1, "minute") }));
      }
    }

    if (field === "to") {
      if (time.isSame(today)) {
        Alert("warning", "To time must be greater then from time");
      }
    }
  };

  const handleQrSlotTimeChange = (field, value) => {
    setQrTimeRange((prev) => ({ ...prev, [field]: time }));
    const today = dayjs(new Date()).startOf("day");
    const time = dayjs(value).startOf("minute");
    // Reset "To" time if "From" time changes to ensure consistency
    if (field === "from") {
      let toTime = dayjs(qrTimeRange.to);
      const fiveMinBefore = dayjs(new Date())
        .hour(23)
        .minute(55)
        .startOf("minute");
      if (time.isAfter(fiveMinBefore)) {
        Alert("warning", "From time must be less then or equal to 11:55 PM");
        setQrTimeRange((prev) => ({
          ...prev,
          from: fiveMinBefore,
          to: fiveMinBefore.add(1, "minute"),
        }));
      } else if (time.isAfter(toTime) || time.isSame(toTime)) {
        setQrTimeRange((prev) => ({ ...prev, to: time.add(1, "minute") }));
      }
    }

    if (field === "to") {
      if (time.isSame(today)) {
        Alert("warning", "To time must be greater then from time");
      }
    }
  };

  const handleQrSlotsInputChanges = (event) => {
    const value = event.target.value;
    // Allow only numeric values and restrict spaces
    const numericValue = value.replace(/[^0-9]/g, "");
    setQrSlotValue(numericValue);
  };

  const handleOnlineLimitedSlotInputChanges = (event) => {
    const value = event.target.value;
    // Allow only numeric values and restrict spaces
    const numericValue = value.replace(/[^0-9]/g, "");
    setOnlineLimitedSlotCount(numericValue);
  };

  const handleRemoveValue = (index) => {
    setCustomValues(customValues.filter((item, i) => i != index));
  };

  const openTime = Boolean(anchorEl);

  useEffect(() => {
    if (user_uid) {
      const decryptedUserUID = AES_ENCRYPT_DECRYPT.URLdecrypt(user_uid);
      setUserUID(decryptedUserUID || "");
    }
  }, [user_uid]);

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  });

  const yesSubmit = (Data) => {
    if (Data.Purpose == "Cancel") {
      setEditMode(false);
      setCancelOpen(false);
      FetchData(selectedRadioValue, "");
      setFromDateError(null);
      setToDateError(null);
    }
    if (Data.Purpose == "Confirmation") {
      handleEditClick(true);
    }
    setOpen(false);
  };

  const confirmSubmit = (Data) => {
    if (Data.Purpose == "Cancel") {
      setEditMode(false);
      setCancelOpen(false);
      FetchData(selectedRadioValue, "");
      setFromDateError(null);
      setToDateError(null);
    }
    if (Data.Purpose == "Confirmation") {
      if (Data.method == "POST") {
        handlePostData(true);
      } else {
        handleUpdateSettings(true);
      }
    }
    setOpen(false);
  };

  const openPopup = (PopupType, Data) => {
    if (PopupType == "Cancel") {
      setPopUpData({
        ...popupData,
        Purpose: "Cancel",
        message: "Are you sure you want to cancel this activity?",
      });
    }
    if (PopupType == "Confirmation") {
      setPopUpData({
        ...popupData,
        Purpose: "Confirmation",
        message:
          "Another configuration setup is already available. Do you want to view and edit?",
      });
    }
    setOpen(true);
  };

  const closeOpenPopup = (PopupType, Data) => {
    if (PopupType == "Cancel") {
      setPopUpData({
        Purpose: "",
        message: "",
        DataDetails: "",
        method: "",
      });
    }
    if (PopupType == "Confirmation") {
      setPopUpData({
        Purpose: "",
        message: "",
        DataDetails: "",
        method: "",
      });
      FetchData(selectedRadioValue, "");
    }
    setOpen(false);
  };

  let Doctor_id = localStorage.getItem("DoctorUid");

  const handleEditClick = (event) => {
    setEditMode(true);
  };

  const handleRadioClick = async (event) => {
    if (editMode) {
      return;
    }
    const value = event.target.value;
    setSelectedRadioValue(value);
    FetchData(value, "");
  };

  function getDaysInArrayOfStrings(start, end) {
    const days = [];
    let currentDate = start.clone();
    let dayCount = 0;
    //Finding the days between given dates
    while (
      (currentDate.isBefore(end) || currentDate.isSame(end)) &&
      dayCount <= 7
    ) {
      days.push(currentDate.format("dddd").toLowerCase());
      dayCount++;
      currentDate = currentDate.add(1, "day");
    }

    const uniqueDays = days.filter((item, i) => days.indexOf(item) === i);
    setAvailableDays(uniqueDays);
    setSelectedDays(uniqueDays);
  }

  const handleFromDateChange = (date) => {
    setFromDate(date);
    if (toDate != null) {
      getDaysInArrayOfStrings(date, toDate);
    }
    setFromDateError(null);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    if (fromDate != null) {
      getDaysInArrayOfStrings(fromDate, date);
    }
    setToDateError(null);
  };

  const [loaderopen, setloaderOpen] = React.useState(false);

  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };
  const parseTimeString = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    return { hours, minutes };
  };

  function convertToHoursMinutes(number) {
    const hours = Math.floor(number / 60);
    const minutes = number % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
  }

  const FetchData = async (type, action) => {
    handleLoaderOpen();
    try {
      let uid = userUID;
      if (!["SUPER_ADMIN", "DOCTOR", "SCAN"].includes(userDetails.user_type)) {
        // uid = userDetails.maped_doctor_uid;
      }
      const filterType = { "filter.type": type, "filter.action": action };
      const url = `/setting/${uid}`;
      let res = await Controller.ApiController("GET", url, "", filterType);
      handleLoaderClose();
      if (res.type === "success") {
        const getData = res.data;
        setAppointmentAvailable(res.appointment_available);

        if (res.appointment_available == true) {
          openPopup("Confirmation");
        } else if (action == "EDIT" || action == "UPDATE") {
          handleEditClick();
        }
        setSetting(res.data.availability_data);
        setSettingAvailable(true);
        setAppointmentUID(getData.availability_data.availability_uid);
        setSettingDataLength(Object.keys(getData).length);
        setCurrentSetting(
          getData.availability_data.status == "CURRENT_SETTING"
        );
        setUserType(getData.availability_data.user_type);
        if (
          getData.availability_data.morning_duration_from ||
          getData.availability_data.morning_duration_to
        ) {
          setMorningChecked(true);
        } else {
          setMorningChecked(false);
        }

        if (
          getData.availability_data.evening_duration_from ||
          getData.availability_data.evening_duration_to
        ) {
          setEveningChecked(true);
        } else {
          setEveningChecked(false);
        }
        const formattedMorningStartTime = getData.availability_data
          .morning_duration_from
          ? dayjs(getData.availability_data.morning_duration_from, "HH:mm")
          : null;
        const formattedMorningEndTime = getData.availability_data
          .morning_duration_to
          ? dayjs(getData.availability_data.morning_duration_to, "HH:mm")
          : null;
        const formattedEveningStartTime = getData.availability_data
          .evening_duration_from
          ? dayjs(getData.availability_data.evening_duration_from, "HH:mm")
          : null;
        const formattedEveningEndTime = getData.availability_data
          .evening_duration_to
          ? dayjs(getData.availability_data.evening_duration_to, "HH:mm")
          : null;
        const formattedFromDate = getData.availability_data
          .specific_setting_from
          ? dayjs(getData.availability_data.specific_setting_from, "YYYY-MM-DD")
          : null;
        const formattedToDate = getData.availability_data.specific_setting_to
          ? dayjs(getData.availability_data.specific_setting_to, "YYYY-MM-DD")
          : null;
        if (
          getData.availability_data.type == "SPECIFIC_SETTING" &&
          formattedFromDate != null &&
          formattedToDate != null
        ) {
          getDaysInArrayOfStrings(
            dayjs(formattedFromDate),
            dayjs(formattedToDate)
          );
        }
        setSelectedDays(getData.availability_data.availability_days);
        // setMorningChecked();
        setMorningStartTime(formattedMorningStartTime);
        setMorningEndTime(formattedMorningEndTime);
        // setEveningChecked();
        setEveningStartTime(formattedEveningStartTime);
        setEveningEndTime(formattedEveningEndTime);
        setSelectedSlotDuration(
          getData.availability_data.slot_duration > 0
            ? String(getData.availability_data.slot_duration)
            : 15
        );
        setNewValue(
          convertToHoursMinutes(getData.availability_data.slot_duration)
          // String(getData.availability_data.slot_duration)
        );
        setCustomValues(
          getData.availability_data.scan_slot_duration?.map((val) => val)
        );
        setMorningSlots(getData.availability_data.morning_slots);
        setEveningSlots(getData.availability_data.evening_slots);
        setSelectedTokenOption(getData.token_data.type);
        setFromDate(formattedFromDate);
        setDefaultFromDate(formattedFromDate);
        setToDate(formattedToDate);
        setDefaultToDate(formattedToDate);
        setPrefixSelection(getData.token_data.prefix != "" ? "YES" : "NO");
        setPrefixValue(getData.token_data.prefix);
        setIvrConfigDay(getData.ivr_data.no_of_days);
        setIvrConfigSlot(getData.ivr_data.no_of_slots);
        setGeneralDelay(
          String(getData?.notification_data.general_delay_duration)
        );
        setEmergencyDelay(
          String(getData?.notification_data.emergency_delay_duration)
        );

        setQrSlotValue(getData.availability_data.qr_booking_slot_count);
        setQrCodeSlot(
          !!getData.availability_data.qr_booking_slot_count ? "YES" : "NO"
        );
        setOnlineLimitedSlot(
          !!getData.availability_data.online_appointment_count ? "YES" : "NO"
        );
        setOnlineLimitedSlotCount(
          getData.availability_data.online_appointment_count
        );
        if (
          !!getData?.availability_data?.qr_booking_start_time &&
          !!getData?.availability_data?.qr_booking_end_time
        ) {
          let startTime =
            getData?.availability_data?.qr_booking_start_time.split(":");
          let endTime =
            getData?.availability_data?.qr_booking_end_time.split(":");
          setQrTimeRange({
            from: dayjs(new Date())
              .set("hour", startTime[0])
              .set("minute", startTime[1]),
            to: dayjs(new Date())
              .set("hour", endTime[0])
              .set("minute", endTime[1]),
          });
          setQRSlotDurationSelection("YES");
        }
        setMultipleCheckIn(getData.availability_data.multiple_check_in);
        setMultiplePatientQR(
          getData.availability_data.multiple_appoinment_booking_qr
        );
        setIVRSlotLimitValue(getData.ivr_data.ivr_slot_count);
        setIvrSlotLimit(!!getData.ivr_data.ivr_slot_count ? "YES" : "NO");
        if (
          !!getData?.ivr_data?.ivr_slot_start_time &&
          !!getData?.ivr_data?.ivr_slot_end_time
        ) {
          let startTime = getData?.ivr_data?.ivr_slot_start_time.split(":");
          let endTime = getData?.ivr_data?.ivr_slot_end_time.split(":");
          setTimeRange({
            from: dayjs(new Date())
              .set("hour", startTime[0])
              .set("minute", startTime[1]),
            to: dayjs(new Date())
              .set("hour", endTime[0])
              .set("minute", endTime[1]),
          });
          setIvrSlotDurationSelection("YES");
        }
        if (
          !!getData?.ivr_data?.ivr_call_start_time &&
          !!getData?.ivr_data?.ivr_call_end_time
        ) {
          let startTime = getData?.ivr_data?.ivr_call_start_time.split(":");
          let endTime = getData?.ivr_data?.ivr_call_end_time.split(":");
          setServerTimeRange({
            from: dayjs(new Date())
              .set("hour", startTime[0])
              .set("minute", startTime[1]),
            to: dayjs(new Date())
              .set("hour", endTime[0])
              .set("minute", endTime[1]),
          });
          setIvrServerDurationSelection("YES");
        }
        [
          "three_token_away",
          "whatsapp_trigger",
          "qr_notification",
          "whatsapp_confirmation",
          "ivr_call_confirmation",
          "bulk_delay_notification",
          "bulk_cancel_notification",
        ].map((val) => {
          if (getData.notification_data[val]) {
            setNotifications((prevData) => [...prevData, val]);
          }
        });

        setSelectedRadioValue(getData.availability_data.type);
      } else if (res.status === "FAILED") {
        setAppointmentAvailable(res.error.appointment_available);
        if (
          res.error.appointment_available == true &&
          res.error.setting_available == true
        ) {
          openPopup("Confirmation");
        } else if (action == "EDIT" || action == "UPDATE") {
          handleEditClick();
        }
        setAppointmentUID("");
        setSettingAvailable(res.error.setting_available);
        setCurrentSetting(false);
        if (res.status_code == 404 && action != "EDIT") {
          // Alert("warning", "Please update your settings");
          setAvailableDays([]);
          setSelectedDays([]);
          setMorningChecked(false);
          setMorningStartTime(null);
          setMorningEndTime(null);
          setEveningChecked(false);
          setEveningStartTime(null);
          setEveningEndTime(null);
          setSelectedSlotDuration(0);
          setNewValue("");
          setMorningSlots("");
          setEveningSlots("");
          setQrSlotValue("");
          setQrCodeSlot("NO");
          setQrTimeRange({ from: "", to: "" });
          setTimeRange({ from: "", to: "" });
          setQRSlotDurationSelection("NO");
          setIvrSlotDurationSelection("NO");
          setIvrSlotLimit("NO");
          setNotifications([]);
          setCustomValues([]);
          setIVRSlotLimitValue("");
          setMultipleCheckIn(false);
          setSelectedTokenOption("SEQUENTIAL");
          setFromDate(null);
          setToDate(null);
          setDefaultFromDate(null);
          setDefaultToDate(null);
          setPrefixSelection("NO");
          setPrefixValue("");
          setIvrConfigDay("1");
          setIvrConfigSlot("1");
          setGeneralDelay("");
          setSettingDataLength(0);
          setEmergencyDelay("");
          setEmergencyNotApplicable(true);
          setGeneralNotApplicable(true);
        } else if (action != "EDIT") {
          Alert("error", res.error.message);
          setDayTimeError(res.error.message);
        }
        const DataNotFound = res.error.message;
        setDataNotFound(DataNotFound);
      }
    } catch (error) {
      handleLoaderClose();
      console.log(error);
    }
  };

  useEffect(() => {
    if (userUID && userDetails) {
      FetchData("", "");
    }
  }, [userUID, userDetails]);

  const handleCancelClick = () => {
    setEditMode(false);
    if (editMode && initialData) {
      setSelectedDays(initialData.selectedDays);
      setMorningChecked(initialData.morningChecked);
      setMorningStartTime(initialData.morningStartTime);
      setMorningEndTime(initialData.morningEndTime);
      setEveningChecked(initialData.eveningChecked);
      setEveningStartTime(initialData.eveningStartTime);
      setEveningEndTime(initialData.eveningEndTime);
      setSelectedSlotDuration(initialData.selectedSlotDuration);
      setMorningSlots(initialData.morningSlots);
      setEveningSlots(initialData.eveningSlots);
      setSelectedTokenOption(initialData.selectedTokenOption);
      setPrefixSelection(initialData.prefixSelection);
      setPrefixValue(initialData.prefixValue);
      setIvrConfigDay(initialData.ivrConfigDay);
      setIvrConfigSlot(initialData.ivrConfigSlot);
      setGeneralDelay(initialData.GeneralDelay);
      setEmergencyDelay(initialData.EmergencyDelay);
    }
  };

  useEffect(() => {
    const MorningSlots = calculateSlots(
      morningStartTime,
      morningEndTime,
      selectedSlotDuration
    );
    setMorningSlots(MorningSlots || "" || morningSlots);
    const EveningSlots = calculateSlots(
      eveningStartTime,
      eveningEndTime,
      selectedSlotDuration
    );
    setEveningSlots(EveningSlots || "" || eveningSlots);
  }, [
    morningStartTime,
    morningEndTime,
    eveningStartTime,
    eveningEndTime,
    selectedSlotDuration,
  ]);

  const handleSelectDayCheckbox = (event) => {
    const { value, checked } = event.target;
    setIsDayCheckboxSelected(checked);
    if (editMode) {
      setSelectedDays((prevSelectedDays) => {
        if (!Array.isArray(prevSelectedDays)) {
          setAvailabilityDaysError(null);
          return [value];
        }

        if (checked) {
          // Check if the value is already in the array to avoid duplicates
          if (!prevSelectedDays.includes(value)) {
            setAvailabilityDaysError(null);
            return [...prevSelectedDays, value];
          }
        } else {
          // Remove the value from the array
          const updatedSelectedDays = prevSelectedDays.filter(
            (day) => day !== value
          );
          setAvailabilityDaysError(null);
          if (selectedDays?.length == 1) {
            setMorningChecked(false);
            setEveningChecked(false);
            setNewValue("");
            setSelectedSlotDuration(0);
            setMorningSlots("");
            setEveningSlots("");

            setMorningStartTime(null);
            setMorningEndTime(null);
            setEveningStartTime(null);
            setEveningEndTime(null);
          }
          return updatedSelectedDays;
          // return prevSelectedDays.filter((day) => day !== value);
        }

        // If no changes, return the unchanged array
        return prevSelectedDays;
      });
    }
  };

  const handleQrCheckin = (event) => {
    const { value, checked } = event.target;
    if (editMode) {
      setQrCheckin(checked);
    }
  };

  const handleDurationCheckbox = (event) => {
    const { value, checked } = event.target;
    if (editMode) {
      if (value === "morning") {
        setMorningChecked(checked);
        if (!checked) {
          setMorningStartTime(null);
          setMorningEndTime(null);
        } else {
          setMorningStartTime(
            dayjs().set("hour", 8).set("minute", 0).set("second", 0)
          );
          setMorningEndTime(
            dayjs().set("hour", 14).set("minute", 0).set("second", 0)
          );
        }
        setDayTimeError(null);
      } else if (value === "evening") {
        setEveningChecked(checked);
        if (!checked) {
          setEveningStartTime(null);
          setEveningEndTime(null);
        } else {
          setEveningStartTime(
            dayjs()
              .set("hours", 14)
              .set("minutes", 0)
              .set("seconds", 0)
              .set("milliseconds", 0)
          );
          setEveningEndTime(
            dayjs()
              .set("hours", 23)
              .set("minutes", 0)
              .set("seconds", 0)
              .set("milliseconds", 0)
          );
        }
        setDayTimeError(null);
      }
    }
  };

  const calculateSlots = (
    newMorningStartTime,
    newMorningEndTime,
    selectedSlotDuration
  ) => {
    if (editMode) {
      if (!newMorningStartTime || !newMorningEndTime || !selectedSlotDuration) {
        return 0;
      }

      // const [hours, minutes] = selectedSlotDuration.split(':').map(Number);
      // const slotDurationInMinutes = hours + minutes / 60;
      const startMoment = dayjs(newMorningStartTime) || 0;
      const endMoment = dayjs(newMorningEndTime) || 0;
      const durationInMinutes = endMoment.diff(startMoment, "minutes");
      const slotDuration =
        selectedSlotDuration != "0" ? selectedSlotDuration : 0;
      const slots = Math.floor(durationInMinutes / selectedSlotDuration);

      return slotDuration ? slots : 0;
    }
  };

  const handleStartTimeChange = (type, time) => {
    try {
      const isWithinRange =
        dayjs(time).isAfter(dayjs().set("hours", 8).set("minutes", 0)) &&
        dayjs(time).isBefore(dayjs().set("hours", 14).set("minutes", 0));

      const endTimeWithinRange =
        dayjs(morningEndTime).isAfter(
          dayjs().set("hours", 8).set("minute", 0)
        ) &&
        dayjs(morningEndTime).isBefore(
          dayjs().set("hours", 14).set("minute", 0)
        );
      const startTimeAfter = dayjs(morningStartTime).isAfter(
        dayjs(morningEndTime)
      );

      if (editMode) {
        if (!Array.isArray(time)) {
          time = [time, null];
        }

        const [startTime] = time;

        if (type === "morning" && morningChecked) {
          setMorningStartTime(startTime);
          if (startTimeAfter) {
            setStartTimeError("Start time should be lesser than end time");
          }
          // Check if start time is less than end time
          if (isWithinRange) {
            const newMorningStartTime = startTime;
            setMorningStartTime(newMorningStartTime);
            setStartTimeError(null);

            // Calculate and set morning slots
            const slots = calculateSlots(
              newMorningStartTime,
              morningEndTime,
              selectedSlotDuration
            );
            setMorningSlots(slots);
          }
        }

        if (type === "evening" && eveningChecked) {
          setEveningStartTime(startTime);
        }
      }
    } catch (e) {
      console.log("startTimeErr: ", e);
    }
  };
  const handleEndTimeChange = (type, time) => {
    const isWithinRange =
      dayjs(time).isAfter(dayjs().set("hours", 8).set("minutes", 0)) &&
      dayjs(time).isBefore(dayjs().set("hours", 14).set("minutes", 0));
    const startTimeWithinRange =
      dayjs(morningStartTime).isAfter(
        dayjs().set("hours", 8).set("minutes", 0)
      ) &&
      dayjs(morningStartTime).isBefore(
        dayjs().set("hours", 14).set("minutes", 0)
      );
    const endTimeBefore = dayjs(morningEndTime).isBefore(
      dayjs(morningStartTime)
    );

    if (editMode) {
      if (!Array.isArray(time)) {
        time = [time, null];
      }

      const [endTime] = time;
      if (type === "morning" && morningChecked) {
        setMorningEndTime(time);
        // Check if end time is greater than start time
        if (morningStartTime) {
          const newMorningEndTime = endTime;
          setMorningEndTime(newMorningEndTime);
          setEndTimeError(null);

          // Calculate and set morning slots
          const slots = calculateSlots(
            morningStartTime,
            newMorningEndTime,
            selectedSlotDuration
          );
          setEveningSlots(slots);
        }
      } else if (type === "evening" && eveningChecked) {
        setEveningEndTime(endTime);
        // Similar logic for evening
        // ...
      }
    }
  };

  useEffect(() => {
    const startTimeWithinRange =
      dayjs(morningStartTime).isAfter(
        dayjs().set("hour", 7).set("minute", 59).set("seconds", 59)
      ) &&
      dayjs(morningStartTime).isBefore(
        dayjs().set("hours", 14).set("minute", 1).set("seconds", 1)
      );

    const endTimeWithinRange =
      dayjs(morningEndTime).isAfter(
        dayjs().set("hour", 7).set("minute", 59).set("second", 59)
      ) &&
      dayjs(morningEndTime).isBefore(
        dayjs().set("hours", 14).set("minute", 1).set("seconds", 1)
      );

    const endTimeBefore = dayjs(morningEndTime).isBefore(
      dayjs(morningStartTime)
    );
    const startTimeAfter = dayjs(morningStartTime).isAfter(
      dayjs(morningEndTime)
    );
    if (
      morningChecked &&
      morningStartTime !== null &&
      morningEndTime !== null
    ) {
      if (endTimeWithinRange === false && startTimeWithinRange === true) {
        setStartTimeError("End time should be within the allowed range");
      } else if (
        startTimeWithinRange === false &&
        endTimeWithinRange === true
      ) {
        setStartTimeError("Start time should be within the allowed range");
      } else if (!endTimeWithinRange && !startTimeWithinRange) {
        setStartTimeError(
          "Start time and End time should be within the allowed range"
        );
      } else if (endTimeBefore) {
        setStartTimeError("End time should be greater than start time");
      } else if (startTimeAfter) {
        setStartTimeError("Start time should be lesser than end time");
      } else {
        setStartTimeError(null);
      }
    } else {
      setStartTimeError(null);
    }
  }, [morningStartTime, morningEndTime, morningChecked, eveningChecked]);

  useEffect(() => {
    const startTimeWithinRange =
      dayjs(eveningStartTime).isAfter(
        dayjs()
          .set("hours", 13)
          .set("minutes", 59)
          .set("seconds", 59)
          .set("milliseconds", 59)
      ) &&
      dayjs(eveningStartTime).isBefore(
        dayjs()
          .set("hours", 23)
          .set("minutes", 1)
          .set("seconds", 1)
          .set("milliseconds", 1)
      );

    const endTimeWithinRange =
      dayjs(eveningEndTime).isAfter(
        dayjs()
          .set("hours", 14)
          .set("minutes", 0)
          .set("seconds", 0)
          .set("milliseconds", 0)
      ) &&
      dayjs(eveningEndTime).isBefore(
        dayjs()
          .set("hours", 23)
          .set("minutes", 1)
          .set("seconds", 1)
          .set("milliseconds", 1)
      );

    const endTimeBefore = dayjs(eveningEndTime).isBefore(
      dayjs(eveningStartTime)
    );
    const startTimeAfter = dayjs(eveningStartTime).isAfter(
      dayjs(eveningEndTime)
    );

    if (eveningChecked) {
      if (endTimeWithinRange === false && startTimeWithinRange === true) {
        setStartTimeError("End time should be within the allowed range");
      } else if (
        startTimeWithinRange === false &&
        endTimeWithinRange === true
      ) {
        setStartTimeError("Start time should be within the allowed range");
      } else if (!endTimeWithinRange && !startTimeWithinRange) {
        setStartTimeError(
          "Start time and End time should be within the allowed range"
        );
      } else if (endTimeBefore) {
        setStartTimeError("End time should be greater than start time");
      } else if (startTimeAfter) {
        setStartTimeError("Start time should be lesser than end time");
      } else {
        setStartTimeError(null);
      }
    } else {
      setStartTimeError(null);
    }
  }, [eveningEndTime, eveningStartTime, eveningChecked]);

  const handleSlotDurationChange = (event) => {
    if (editMode) {
      setSlotError("");
      setSelectedSlotDuration(event.target.value);
    }
  };

  const handleTimeChange = (value) => {
    if (value) {
      setAddDuration(false);

      const hours = dayjs(value).hour();
      const minutes = dayjs(value).minute();
      if (
        userType == "SCAN" &&
        ((hours == 0 && minutes == 0) || isNaN(hours) || isNaN(minutes))
      ) {
        setAddDuration(true);
        return;
      }

      if (userType == "SCAN") {
        const convertTimeinMinutes = hours * 60 + minutes;

        const formattedTime = dayjs(value).format("HH:mm");

        setSelectedSlotDuration(convertTimeinMinutes);
        setNewValue(formattedTime);
      } else {
        const convertTimeinMinutes = hours * 60 + minutes;
        const formattedTime = `${String(hours).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}`;

        setSelectedSlotDuration(convertTimeinMinutes);
        setNewValue(formattedTime);
      }
    } else {
      setAddDuration(true);
    }
  };

  const handleMorningSlotsChange = (event) => {
    if (editMode) {
      setMorningSlots(event.target.value);
    }
  };

  const handleEveningSlotsChange = (event) => {
    if (editMode) {
      setEveningSlots(event.target.value);
    }
  };

  const handleRadioChange = (event) => {
    if (editMode) {
      const selectedValue = event.target.value.toUpperCase();
      setSelectedTokenOption(selectedValue);
    }
  };

  const handlePrefixChange = (event) => {
    if (editMode) {
      setPrefixSelection(event.target.value);
      setPrefixError("");
    }
  };

  const handleIvrSlotDurationChange = (event) => {
    if (editMode) {
      setIvrSlotDurationSelection(event.target.value);
    }
  };

  const handleQRSlotDurationChange = (event) => {
    if (editMode) {
      setQRSlotDurationSelection(event.target.value);
    }
  };

  const handleIVRSlotLimitChange = (event) => {
    if (editMode) {
      setIvrSlotLimit(event.target.value);
      setPrefixError("");
    }
  };

  const handleQrCodeSlotChange = (event) => {
    if (editMode) {
      setQrCodeSlot(event.target.value);
      setPrefixError("");
    }
  };

  const handleOnlineLimitedSlotChange = (event) => {
    if (editMode) {
      setOnlineLimitedSlot(event.target.value);
    }
  };
  const handlePrefixValueChange = (event) => {
    if (editMode) {
      setPrefixValue(event.target.value);
      setPrefixError("");
    }
  };

  const handleIVRSlotLimitValueChange = (event) => {
    if (editMode) {
      setIVRSlotLimitValue(event.target.value);
      setPrefixError("");
    }
  };

  const handleChangeNotification = (value) => {
    if (notifications.includes(value)) {
      setNotifications((prevData) => prevData.filter((val) => val != value));
    } else {
      setNotifications((prevData) => [...prevData, value]);
    }
  };

  const generateExampleText = () => {
    if (prefixSelection === "YES" && prefixValue) {
      const exampleNumbers = ["1", "2", "3"]; // You can customize these numbers
      const exampleText = exampleNumbers
        .map((number) => `${prefixValue}${number}`)
        .join(", ");
      return exampleText;
    }
    return "";
  };

  const handleIvrConfigDayChange = (event) => {
    if (editMode) {
      setIvrConfigDay(event.target.value);
    }
  };

  const handleIvrConfigSlotChange = (event) => {
    if (editMode) {
      setIvrConfigSlot(event.target.value);
    }
  };

  const handleEmergencyDurationChange = (event) => {
    if (editMode) {
      setEmergencyDelay(event.target.value);
    }
  };

  const handleEmergencyNotApplicableChange = (event) => {
    if (editMode) {
      setEmergencyNotApplicable(event.target.checked);

      // If "Not Applicable" is checked for Emergency, disable and clear the Emergency Delay Tolerance
      if (event.target.checked) {
        setEmergencyDelay({ hours: 0, minutes: 0 });
      } else {
        setEmergencyDelay(initialData?.EmergencyDelay);
      }
    }
  };

  const handleDurationChange = (event) => {
    if (editMode) {
      setGeneralDelay(event.target.value);
    }
  };

  const handleGeneralNotApplicableChange = (event) => {
    if (editMode) {
      setGeneralNotApplicable(event.target.checked);

      // If "Not Applicable" is checked for General, disable and clear the General Delay Tolerance
      if (event.target.checked) {
        setGeneralDelay({ hours: 0, minutes: 0 });
      } else {
        if (
          initialData &&
          typeof initialData === "object" &&
          "GeneralDelay" in initialData
        ) {
          setGeneralDelay(initialData?.GeneralDelay);
        } else {
          setGeneralDelay({ hours: 0, minutes: 0 });
        }
      }
    }
  };

  const validatePrefix = () => {
    if (prefixSelection === "YES") {
      if (prefixValue.trim().length === 0) {
        setPrefixError("Prefix text is required when Prefix is set to 'Yes'");
      } else if (prefixValue.trim().length > 3) {
        setPrefixError("Prefix text should be 1 to 3 characters");
      } else {
        setPrefixError("");
      }
    } else {
      setPrefixError("");
    }
  };

  const formatTime = ({ hours, minutes }) => {
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
  };

  const handleUpdateSettings = async (appointment_pre_booked = false) => {
    handleLoaderOpen();
    try {
      let requestBody = {
        confirm: appointment_pre_booked,
        availability_data: {
          available_days: selectedDays,
          morning_duration_from: morningStartTime
            ? morningStartTime.format("HH:mm")
            : "",
          morning_duration_to: morningEndTime
            ? morningEndTime.format("HH:mm")
            : "",
          evening_duration_from: eveningStartTime
            ? eveningStartTime.format("HH:mm")
            : "",
          evening_duration_to: eveningEndTime
            ? eveningEndTime.format("HH:mm")
            : "",
          slot_duration:
            selectedSlotDuration != "0" ? String(selectedSlotDuration) : "",
          scan_slot_duration: customValues.length > 0 ? customValues : [],
          morning_slots: String(morningSlots),
          evening_slots: String(eveningSlots),
          type: selectedRadioValue,
          qr_booking_slot_count: qrCodeSlot == "YES" ? qrSlotValue : null,
          online_appointment_count:
            onlineLimitedSlot == "YES" ? onlineLimitedSlotCount : null,
          qr_booking_start_time:
            qRSlotDurationSelection == "YES" && qrTimeRange.from
              ? qrTimeRange.from.format("HH:mm")
              : "",
          qr_booking_end_time:
            qRSlotDurationSelection == "YES" && qrTimeRange.to
              ? qrTimeRange.to.format("HH:mm")
              : "",
          multiple_check_in: multipleCheckIn,
          multiple_appoinment_booking_qr: multiplePatientQR,
        },
        ivr_data: {
          no_of_days: ivrConfigDay,
          no_of_slots: ivrConfigSlot,
          ivr_slot_count: ivrSlotLimit == "YES" ? iVRSlotLimitValue : null,
          ivr_call_start_time:
            ivrServerDurationSelection == "YES" && serverTimeRange.from
              ? serverTimeRange.from.format("HH:mm")
              : "",
          ivr_call_end_time:
            ivrServerDurationSelection == "YES" && serverTimeRange.to
              ? serverTimeRange.to.format("HH:mm")
              : "",
          ivr_slot_start_time:
            ivrSlotDurationSelection == "YES" && timeRange.from
              ? timeRange.from.format("HH:mm")
              : "",
          ivr_slot_end_time:
            ivrSlotDurationSelection == "YES" && timeRange.to
              ? timeRange.to.format("HH:mm")
              : "",
        },
        token_data: {
          type: selectedTokenOption.toUpperCase(),
          prefix: prefixSelection === "YES" ? prefixValue : "",
        },
        notification_data: {
          emergency_delay_duration: String(EmergencyDelay),
          general_delay_duration: String(GeneralDelay),
          three_token_away: notifications.includes("three_token_away"),
          whatsapp_trigger: notifications.includes("whatsapp_trigger"),
          qr_notification: notifications.includes("qr_notification"),
          whatsapp_confirmation: notifications.includes(
            "whatsapp_confirmation"
          ),
          ivr_call_confirmation: notifications.includes(
            "ivr_call_confirmation"
          ),
          bulk_delay_notification: notifications.includes(
            "bulk_delay_notification"
          ),
          bulk_cancel_notification: notifications.includes(
            "bulk_delay_notification"
          ),
        },
      };

      if (selectedRadioValue === "SPECIFIC_SETTING") {
        requestBody.availability_data.specific_setting_from =
          fromDate?.format("YYYY-MM-DD");
        requestBody.availability_data.specific_setting_to =
          toDate?.format("YYYY-MM-DD");
      }

      if (selectedRadioValue === "DEFAULT_SETTING") {
        // Only update morning-related values if the morning checkbox is selected
        if (morningChecked) {
          requestBody.availability_data.evening_duration_from = null;
          requestBody.availability_data.evening_duration_to = null;
          requestBody.availability_data.evening_slots = null;
        }

        // Only update evening-related values if the evening checkbox is selected
        if (eveningChecked) {
          requestBody.availability_data.morning_duration_from = null;
          requestBody.availability_data.morning_duration_to = null;
          requestBody.availability_data.morning_slots = null;
        }

        if (morningChecked && eveningChecked) {
          requestBody.availability_data.morning_duration_from = morningStartTime
            ? morningStartTime.format("HH:mm")
            : null;
          requestBody.availability_data.morning_duration_to = morningEndTime
            ? morningEndTime.format("HH:mm")
            : null;
          requestBody.availability_data.evening_slots = String(eveningSlots);
          requestBody.availability_data.evening_duration_from = eveningStartTime
            ? eveningStartTime.format("HH:mm")
            : null;
          requestBody.availability_data.evening_duration_to = eveningEndTime
            ? eveningEndTime.format("HH:mm")
            : null;
          requestBody.availability_data.morning_slots = String(morningSlots);
        }
      }

      const res = await Controller.ApiController(
        "PUT",
        `/setting/${appointmentUID}`,
        "",
        requestBody
      );
      handleLoaderClose();
      if (res.type === "success") {
        Alert("success", "Settings updated successfully.");
        setEditMode(false); // Set edit mode to false after successful update
        FetchData(selectedRadioValue, "");
        dispatch(updateSetting(updateSettingValue + 1));
        if (pathname.includes("view-settings")) {
          navigate(-1);
        }
      } else if (res.status === "FAILED") {
        if (res.error.message === "Mandatory Data Missing") {
          setAvailabilityDaysError("Please check availability days");
        } else if (
          res.error.message ===
          "Morning or evening start duration and end duration is mandatory"
        ) {
          setDayTimeError("Please select morning either evening");
        } else if (res.error.message === "Slot duration is mandatory") {
          setSlotError("Slot duration is mandatory");
        } else {
          if (res.error.appointment_pre_booked == true) {
            setConfirmOpen(true);
            setPopUpData({
              ...popupData,
              message: res.error.message,
              Purpose: "Confirmation",
              method: "UPDATE",
            });
          } else {
            Alert("error", res?.error?.message || "Something went wrong");
          }
        }
      } else {
        Alert("warning", "Something went wrong");
      }
    } catch (error) {
      handleLoaderClose();
      console.error("Error in handleUpdateSettings:", error);
      setValidationError("An error occurred while updating the settings");
    }
  };

  const handlePostData = async (appointment_pre_booked = false) => {
    try {
      let requestBody = {
        user_uid: userUID,
        confirm: appointment_pre_booked,
        availability_data: {
          available_days: selectedDays,
          morning_duration_from: morningStartTime
            ? morningStartTime.$H + ":" + morningStartTime.$m
            : "",
          morning_duration_to: morningEndTime
            ? morningEndTime.$H + ":" + morningEndTime.$m
            : "",
          evening_duration_from: eveningStartTime
            ? eveningStartTime.$H + ":" + eveningStartTime.$m
            : "",
          evening_duration_to: eveningEndTime
            ? eveningEndTime.$H + ":" + eveningEndTime.$m
            : "",
          slot_duration:
            selectedSlotDuration != "0" ? String(selectedSlotDuration) : "",
          scan_slot_duration: customValues.length > 0 ? customValues : [],
          user_type: userType,
          morning_slots: String(morningSlots),
          evening_slots: String(eveningSlots),
          type: selectedRadioValue,
          qr_booking_slot_count: qrCodeSlot == "YES" ? qrSlotValue : null,
          online_appointment_count:
            onlineLimitedSlot == "YES" ? onlineLimitedSlotCount : null,
          qr_booking_start_time:
            qRSlotDurationSelection == "YES" && qrTimeRange.from
              ? qrTimeRange.from.format("HH:mm")
              : "",
          qr_booking_end_time:
            qRSlotDurationSelection == "YES" && qrTimeRange.to
              ? qrTimeRange.to.format("HH:mm")
              : "",
          multiple_check_in: multipleCheckIn,
          multiple_appoinment_booking_qr: multiplePatientQR,
        },

        ivr_data: {
          no_of_days: ivrConfigDay,
          no_of_slots: ivrConfigSlot,
          ivr_slot_count: ivrSlotLimit == "YES" ? iVRSlotLimitValue : null,
          ivr_call_start_time:
            ivrServerDurationSelection == "YES" && serverTimeRange.from
              ? serverTimeRange.from.format("HH:mm")
              : "",
          ivr_call_end_time:
            ivrServerDurationSelection == "YES" && serverTimeRange.to
              ? serverTimeRange.to.format("HH:mm")
              : "",
          ivr_slot_start_time:
            ivrSlotDurationSelection == "YES" && timeRange.from
              ? timeRange.from.format("HH:mm")
              : "",
          ivr_slot_end_time:
            ivrSlotDurationSelection == "YES" && timeRange.to
              ? timeRange.to.format("HH:mm")
              : "",
        },
        token_data: {
          type: selectedTokenOption.toUpperCase(),
          prefix: prefixSelection === "YES" ? prefixValue : "",
        },
        notification_data: {
          emergency_delay_duration: String(EmergencyDelay),
          general_delay_duration: String(GeneralDelay),
          three_token_away: notifications.includes("three_token_away"),
          whatsapp_trigger: notifications.includes("whatsapp_trigger"),
          qr_notification: notifications.includes("qr_notification"),
          whatsapp_confirmation: notifications.includes(
            "whatsapp_confirmation"
          ),
          ivr_call_confirmation: notifications.includes(
            "ivr_call_confirmation"
          ),
          bulk_delay_notification: notifications.includes(
            "bulk_delay_notification"
          ),
          bulk_cancel_notification: notifications.includes(
            "bulk_delay_notification"
          ),
        },
      };

      if (selectedRadioValue === "SPECIFIC_SETTING") {
        requestBody.availability_data.specific_setting_from =
          fromDate?.format("YYYY-MM-DD");
        requestBody.availability_data.specific_setting_to =
          toDate?.format("YYYY-MM-DD");
      }

      let res = await Controller.ApiController(
        "POST",
        "/setting",
        "",
        requestBody
      );
      if (res.type === "success") {
        Alert("success", "Setting created successfully");
        setEditMode(false);
        FetchData(selectedRadioValue, "");
        dispatch(updateSetting({ update_setting: updateSettingValue + 1 }));
        if (pathname.includes("view-settings")) {
          navigate(-1);
        }
      } else if (res.status === "FAILED") {
        //
        if (
          res.error.message ===
          "Morning or evening start duration and end duration is mandatory"
        ) {
          setDayTimeError("Please select morning either evening");
        } else if (res.error.message === "Slot duration is mandatory") {
          setSlotError("Slot duration is mandatory");
        } else {
          if (res.error.appointment_pre_booked == true) {
            setConfirmOpen(true);
            setPopUpData({
              ...popupData,
              message: res.error.message,
              Purpose: "Confirmation",
              method: "POST",
            });
          } else {
            Alert("error", res.error.message);
          }
        }
        // setErrorMessage(res.error.message)
      } else {
        Alert("warning", "Something went Wrong");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setValidationError("An error occurred while submitting the form");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let validationErrors = [];
    let currentDate = new Date().setHours(0, 0, 0, 0);
    if (isDayCheckboxSelected && !morningChecked && !eveningChecked) {
      validationErrors.push("Please select either Morning or Evening");
      setDayTimeError("Please select either Morning or Evening");
    }

    if (morningChecked && !morningStartTime) {
      validationErrors.push("Morning start time is required");
    }

    if (morningChecked && !morningEndTime) {
      validationErrors.push("Morning end time is required");
    }

    if (eveningChecked && !eveningStartTime) {
      validationErrors.push("Evening start time is required");
    }

    if (eveningChecked && !eveningEndTime) {
      validationErrors.push("Evening end time is required");
    }

    if (selectedRadioValue === "SPECIFIC_SETTING") {
      if (!fromDate) {
        validationErrors.push("From date is required");
        setFromDateError("From date is required");
      } else if (
        !(new Date(fromDate.$d) >= new Date(currentDate)) &&
        settingDataLength == 0
      ) {
        validationErrors.push("From date is required");
        setFromDateError("Current date is cannot be from date");
      } else if (
        settingDataLength > 0 &&
        new Date(defaultFromDate.$d) > new Date(fromDate.$d) &&
        !(new Date(defaultFromDate.$d) > new Date(currentDate))
      ) {
        validationErrors.push("From dataaae is required");
        setFromDateError("Current date is cannot be from date");
      }

      if (!toDate) {
        validationErrors.push("To date is required");
        setToDateError("To date is required");
      } else if (!(new Date(toDate.$d) >= new Date(fromDate.$d))) {
        validationErrors.push("To date is required");
        setToDateError("Current date is cannot be to date");
      }

      if (
        (!selectedSlotDuration ||
          parseInt(selectedSlotDuration) < 1 ||
          parseInt(selectedSlotDuration) > 240) &&
        selectedDays.length > 0
      ) {
        if (!selectedSlotDuration || parseInt(selectedSlotDuration) < 1) {
          Alert("error", "Invalid Slot Duration");
        } else if (parseInt(selectedSlotDuration) > 240) {
          Alert("error", "Slot Duration must be less then 4 hours");
        }
        return;
      }

      if (
        settingDataLength > 0 &&
        toDate &&
        new Date(toDate.$d) >= new Date(fromDate.$d) &&
        new Date(toDate.$d) < new Date(currentDate)
      ) {
        setToDateError("Current date is cannot be to date");
        validationErrors.push("To date is required");
      }
    } else {
      if (selectedDays.length == 0) {
        validationErrors.push("Please check availability days");
        setAvailabilityDaysError("Please check availability days");
      }
    }

    if (validationErrors.length > 0) {
      setErrorMessage(validationErrors);
      return;
    }

    if (
      qrTimeRange?.to &&
      qrTimeRange?.from &&
      (qrTimeRange?.to?.isBefore(qrTimeRange.from) ||
        qrTimeRange?.to?.isSame(qrTimeRange.from))
    ) {
      Alert(
        "error",
        "QR booking duration to time is must be greater than from time"
      );
      return;
    } else if (
      qRSlotDurationSelection == "YES" &&
      (!qrTimeRange?.to || !qrTimeRange?.from)
    ) {
      Alert("error", "Please select QR booking duration");
      return;
    }

    if (
      serverTimeRange?.to &&
      serverTimeRange?.from &&
      (serverTimeRange?.to?.isBefore(serverTimeRange.from) ||
        serverTimeRange?.to?.isSame(serverTimeRange.from))
    ) {
      Alert(
        "error",
        "IVR server duration to time is must be greater than from time"
      );
      return;
    } else if (
      ivrServerDurationSelection == "YES" &&
      (!serverTimeRange?.to || !serverTimeRange?.from)
    ) {
      Alert("error", "Please select IVR server duration");
      return;
    }
    if (
      timeRange?.to &&
      timeRange?.from &&
      (timeRange?.to?.isBefore(timeRange.from) ||
        timeRange?.to?.isSame(timeRange.from))
    ) {
      Alert(
        "error",
        "IVR slot duration to time is must be greater than from time"
      );
      return;
    } else if (
      ivrSlotDurationSelection == "YES" &&
      (!timeRange?.to || !timeRange?.from)
    ) {
      Alert("error", "Please select IVR slot duration");
      return;
    }

    if (
      (!selectedSlotDuration ||
        parseInt(selectedSlotDuration) < 1 ||
        parseInt(selectedSlotDuration) > 240) &&
      selectedRadioValue === "DEFAULT_SETTING"
    ) {
      if (!selectedSlotDuration || parseInt(selectedSlotDuration) < 1) {
        Alert("error", "Invalid Slot Duration");
      } else if (parseInt(selectedSlotDuration) > 240) {
        Alert("error", "Slot Duration must be less then 4 hours");
      }
      return;
    }

    setErrorMessage(null);
    setFromDateError(null);
    setToDateError(null);
    setDayTimeError(null);

    if (editMode) {
      if (settingAvailable) {
        handleUpdateSettings();
      } else {
        if (startTimeError) {
          Alert("error", startTimeError);
        } else if (endTimeError) {
          Alert("error", endTimeError);
        }
        handlePostData();
      }
    }
  };

  const tommorowDate = () => {
    let tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate());
    return tomorrowDate;
  };

  const disableTimes = (time, clockType) => {
    if (clockType == "hours") {
      return time.$H > 4;
    } else if (clockType == "minutes") {
      return time.$H >= 4 && time.$m > 0;
    }
    return false;
  };

  const disableDates = (date) => {
    const today = dayjs(new Date()).startOf("day");
    const tomorrow = today.add(1, "day");
    let disable = true;

    if (date.isBefore(qrTimeRange.from)) {
      disable = true;
    } else {
      disable = false;
    }

    // if (formData.consultation_type.value == "NORMAL") {
    //   disable =
    //     !date.isSame(formData.edit_appointment_date.value) &&
    //     !(date.isSame(today, "day") || date.isSame(tomorrow, "day"));
    // } else if (formData.consultation_type.value == "EMERGENCY") {
    //   disable =
    //     !date.isSame(formData.edit_appointment_date.value) &&
    //     !date.isSame(today, "day");
    // } else if (formData.consultation_type.value == "REVIEW_APPOINTMENT") {
    //   disable =
    //     !date.isSame(formData.edit_appointment_date.value) &&
    //     date.isBefore(today);
    // }

    return disable;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Header />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ marginTop: { xs: "0px", sm: "0px" }, marginBottom: "25px" }}
      >
        <BreadCrumbs
          crumbs={
            allSettings
              ? ["Dashboard", "Settings", "View Settings"]
              : ["Dashboard", "Settings"]
          }
          paths={
            allSettings
              ? ["/dashboard", "/all-settings"]
              : ["/dashboard", "/settings"]
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <BioDetails />
      </Grid>
      {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display:{xs:"", sm:""}}}> */}
      <Box
        component="form"
        onSubmit={handleSubmit}
        mt={3}
        sx={{ padding: "0px 0px 0px 8.5px" }}
      >
        <Grid container spacing={1}>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4.5}
              xl={6}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: { md: "0px", sm: "" },
                  marginBottom: "10px",
                  marginLeft: { md: "10px", sm: "0px" },
                }}
              >
                <h3>Settings</h3>
                <div
                  style={{
                    marginLeft: "5px",
                    display: "flex",
                    position: "relative",
                  }}
                >
                  {editMode ? (
                    <>
                      <Tooltip
                        title="Cancel"
                        disableRipple
                        placement="top"
                        arrow
                      >
                        {/* <img src={cancelIcon}  onClick={handleCancelClick} style={{cursor:"pointer"}}  /> */}
                        <img
                          src={cancelIcon}
                          onClick={() => openPopup("Cancel")}
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>

                      <Tooltip title="Save" disableRipple placement="top" arrow>
                        <button
                          type="submit"
                          style={{
                            width: "30px",
                            marginLeft: "20px",
                            padding: "0px",
                            height: "28px",
                            borderRadius: "50px",
                            border: "none",
                            backgroundColor: "#fff",
                          }}
                        >
                          <img
                            src={Save}
                            style={{ marginTop: "2px", cursor: "pointer" }}
                          />
                        </button>
                      </Tooltip>
                    </>
                  ) : (
                    <Tooltip
                      title="Edit"
                      disableRipple
                      placement="top"
                      arrow
                      sx={{ marginLeft: "-20px" }}
                    >
                      <img
                        src={editIcon}
                        onClick={() =>
                          selectedRadioValue == "DEFAULT_SETTING"
                            ? FetchData(selectedRadioValue, "EDIT")
                            : handleEditClick()
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  )}{" "}
                </div>
                {userDetails.user_type != "DOCTOR" &&
                  userDetails.user_type != "SCAN" && (
                    <div
                      className="flx"
                      style={{
                        marginLeft: height > width ? "0px" : "30px",
                        // maxWidth: "350p/x",
                      }}
                    >
                      <img
                        src={Doctor}
                        sx={{ height: { sm: "25px", xs: "25px" } }}
                        alt={"Calender.logo"}
                      />
                      <Typography
                        // className="center-image-txt"
                        sx={{ fontSize: { sm: "14px", xs: "12px" }, ml: 2 }}
                      >
                        {setting?.manage_user?.full_name || "-"}
                      </Typography>
                    </div>
                  )}
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={7.5}
              xl={6}
              sx={{
                display: { md: "flex", xl: "flex", lg: "flex", sm: "", xs: "" },
                justifyContent: { lg: "right", sm: "left", xs: "left" },
              }}
            >
              <div style={{ marginTop: "-4px", marginBottom: "5px" }}>
                <FormControl
                  sx={{ ".MuiFormControlLabel-label": { fontSize: "14px" } }}
                >
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={selectedRadioValue}
                    name="radio-buttons-group"
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      flexDirection: { xs: "row", sm: "row", md: "row" },
                      whiteSpace: "nowrap",
                    }}
                    onChange={handleRadioClick}
                  >
                    <FormControlLabel
                      labelPlacement="end"
                      value="DEFAULT_SETTING"
                      control={
                        <Radio
                          sx={{ "&.Mui-checked": { color: "#243665" } }}
                          disabled={
                            editMode && selectedRadioValue == "SPECIFIC_SETTING"
                          }
                          checked={selectedRadioValue == "DEFAULT_SETTING"}
                        />
                      }
                      label="Default Settings"
                    />
                    <FormControlLabel
                      labelPlacement="end"
                      value="SPECIFIC_SETTING"
                      control={
                        <Radio
                          sx={{ "&.Mui-checked": { color: "#243665" } }}
                          disabled={
                            editMode && selectedRadioValue == "DEFAULT_SETTING"
                          }
                          checked={selectedRadioValue == "SPECIFIC_SETTING"}
                        />
                      }
                      label="Specific Settings"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              {selectedRadioValue === "SPECIFIC_SETTING" && (
                <div style={{ display: "flex" }}>
                  <FormControl sx={{ marginRight: "5px" }}>
                    {/* <label style={{ fontSize: "14px" }}>From</label> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {/* <DemoContainer
                        sx={{ width: "140px", overflow: "hidden" }}
                        components={["DatePicker"]}
                      > */}
                      <DesktopDatePicker
                        format="DD-MM-YYYY"
                        label={"From"}
                        value={fromDate}
                        onChange={handleFromDateChange}
                        minDate={dayjs(tommorowDate())}
                        disabled={!editMode}
                        slotProps={{
                          textField: {
                            InputLabelProps: {
                              style: {
                                fontSize: fromDate != null ? "15px" : "13px",
                              },
                            },
                            sx: {
                              "& .MuiOutlinedInput-root": {
                                width: "145px",
                                height: "35px",
                                color: "#000",
                                fontSize: "14px",
                                paddingRight: "0px",
                              },
                              ".MuiIconButton-root": {
                                marginRight: "8px",
                                padding: "0px",
                              },
                            },
                            size: "small",
                            error: !!fromDateError,
                          },
                        }}
                      />
                      {/* </DemoContainer> */}
                    </LocalizationProvider>
                  </FormControl>

                  <FormControl>
                    {/* <label style={{ fontSize: "14px" }}>To</label> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {/* <DemoContainer
                        sx={{ width: "140px", overflow: "hidden" }}
                        components={["DatePicker"]}
                      > */}
                      <DesktopDatePicker
                        format="DD-MM-YYYY"
                        label={"To"}
                        value={toDate}
                        onChange={handleToDateChange}
                        minDate={dayjs(fromDate || tommorowDate())}
                        disabled={!editMode}
                        slotProps={{
                          textField: {
                            InputLabelProps: {
                              style: {
                                fontSize: toDate != null ? "15px" : "13px",
                              },
                            },
                            sx: {
                              "& .MuiOutlinedInput-root": {
                                width: "145px",
                                height: "35px",
                                color: "#000",
                                fontSize: "14px",
                                paddingRight: "0px",
                              },
                              ".MuiIconButton-root": {
                                marginRight: "8px",
                                padding: "0px",
                              },
                            },
                            size: "small",
                            error: !!toDateError,
                          },
                        }}
                      />
                      {/* </DemoContainer> */}
                    </LocalizationProvider>
                  </FormControl>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {appointmentAvailable == true && (
              <p
                style={{
                  paddingLeft: "0px",
                  marginTop: "0px",
                  marginBottom: "0px",
                  color: "#BC123E",
                }}
              >
                Changes will be effective from tomorrow
              </p>
            )}
          </Grid>
        </Grid>

        {/* <Card
              sx={{
                backgroundColor: "#EEF0FA",
                padding: "28px",
                borderRadius: "10px",
                // overflow: "auto",
                // height: "100%",
              }}
            > */}
        <Grid container sx={{ marginTop: "10px" }}>
          <Grid item xs={12} lg={5.5} xl={5}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className="heading">Availability</Typography>
                <FormGroup
                  className="labelgroup"
                  sx={{
                    fontSize: "14px",
                    display: "flex",
                    flexDirection: "row",
                    textAlign: "left",
                  }}
                >
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": { color: "#243665" },
                          color:
                            availabilityDaysError &&
                            selectedRadioValue == "DEFAULT_SETTING"
                              ? "#d32f2f"
                              : "",
                        }}
                        value="monday"
                        onChange={handleSelectDayCheckbox}
                        checked={selectedDays.includes("monday")}
                        disabled={
                          selectedRadioValue == "SPECIFIC_SETTING" &&
                          !availabilityDays.includes("monday")
                        }
                      />
                    }
                    label={<span style={{ fontSize: "14px" }}>Mon</span>}
                  />
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": { color: "#243665" },
                          color:
                            availabilityDaysError &&
                            selectedRadioValue == "DEFAULT_SETTING"
                              ? "#d32f2f"
                              : "",
                        }}
                        value="tuesday"
                        onChange={handleSelectDayCheckbox}
                        checked={selectedDays.includes("tuesday")}
                        disabled={
                          selectedRadioValue == "SPECIFIC_SETTING" &&
                          !availabilityDays.includes("tuesday")
                        }
                      />
                    }
                    label={<span style={{ fontSize: "14px" }}>Tue</span>}
                  />
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": { color: "#243665" },
                          color:
                            availabilityDaysError &&
                            selectedRadioValue == "DEFAULT_SETTING"
                              ? "#d32f2f"
                              : "",
                        }}
                        value="wednesday"
                        onChange={handleSelectDayCheckbox}
                        checked={selectedDays.includes("wednesday")}
                        disabled={
                          selectedRadioValue == "SPECIFIC_SETTING" &&
                          !availabilityDays.includes("wednesday")
                        }
                      />
                    }
                    label={<span style={{ fontSize: "14px" }}>Wed</span>}
                  />
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": { color: "#243665" },
                          color:
                            availabilityDaysError &&
                            selectedRadioValue == "DEFAULT_SETTING"
                              ? "#d32f2f"
                              : "",
                        }}
                        value="thursday"
                        onChange={handleSelectDayCheckbox}
                        checked={selectedDays.includes("thursday")}
                        disabled={
                          selectedRadioValue == "SPECIFIC_SETTING" &&
                          !availabilityDays.includes("thursday")
                        }
                      />
                    }
                    label={<span style={{ fontSize: "14px" }}>Thu</span>}
                  />
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": { color: "#243665" },
                          color:
                            availabilityDaysError &&
                            selectedRadioValue == "DEFAULT_SETTING"
                              ? "#d32f2f"
                              : "",
                        }}
                        value="friday"
                        onChange={handleSelectDayCheckbox}
                        checked={selectedDays.includes("friday")}
                        disabled={
                          selectedRadioValue == "SPECIFIC_SETTING" &&
                          !availabilityDays.includes("friday")
                        }
                      />
                    }
                    label={<span style={{ fontSize: "14px" }}>Fri</span>}
                  />
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": { color: "#243665" },
                          color:
                            availabilityDaysError &&
                            selectedRadioValue == "DEFAULT_SETTING"
                              ? "#d32f2f"
                              : "",
                        }}
                        value="saturday"
                        onChange={handleSelectDayCheckbox}
                        checked={selectedDays.includes("saturday")}
                        disabled={
                          selectedRadioValue == "SPECIFIC_SETTING" &&
                          !availabilityDays.includes("saturday")
                        }
                      />
                    }
                    label={<span style={{ fontSize: "14px" }}>Sat</span>}
                  />
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": { color: "#243665" },
                          color:
                            availabilityDaysError &&
                            selectedRadioValue == "DEFAULT_SETTING"
                              ? "#d32f2f"
                              : "",
                        }}
                        value="sunday"
                        onChange={handleSelectDayCheckbox}
                        checked={selectedDays.includes("sunday")}
                        disabled={
                          selectedRadioValue == "SPECIFIC_SETTING" &&
                          !availabilityDays.includes("sunday")
                        }
                      />
                    }
                    label={<span style={{ fontSize: "14px" }}>Sun</span>}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                {/* _________________________________________MORNING SECTION______________________________ */}
                <Box sx={{ display: "flex", justifyContent: "left" }}>
                  <Box style={{ marginTop: "4px", marginRight: "5px" }}>
                    <FormControlLabel
                      value="morning"
                      sx={{ fontStyle: "14px" }}
                      control={
                        <Checkbox
                          sx={{
                            "&.Mui-checked": { color: "#243665" },
                            fontStyle: "14px",
                            color: dayTimeError ? "#d32f2f" : "",
                          }}
                          checked={morningChecked}
                          onChange={handleDurationCheckbox}
                          disabled={!selectedDays.length == 0 ? false : true}
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>Morning</span>}
                      labelPlacement="start"
                      style={{ marginLeft: "0px" }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: { xs: "", sm: "flex" },
                      justifyContent: "left",
                      marginLeft: { xs: "10px", md: "" },
                    }}
                  >
                    <Typography
                      sx={{
                        marginTop: "15px",
                        marginRight: "15px",
                        fontSize: "14px",
                      }}
                    >
                      Duration
                    </Typography>
                    <Box sx={{ display: { sm: "flex", xs: "none" } }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        style={{ marginRight: "29px" }}
                      >
                        <DemoContainer
                          sx={{
                            width: { xs: "100px", sm: "140px" },
                            overflow: "hidden",
                            marginRight: "5px",
                          }}
                          components={["TimePicker"]}
                        >
                          <DesktopTimePicker
                            closeOnSelect={false}
                            readOnly={!morningChecked && !editMode}
                            minTime={dayjs()
                              .set("hours", 8)
                              .set("minutes", 0)
                              .set("second", 0)}
                            maxTime={dayjs()
                              .set("hours", 14)
                              .set("minutes", 0)
                              .set("second", 0)}
                            value={
                              morningChecked && selectedDays.length !== 0
                                ? morningStartTime
                                : null
                            }
                            slotProps={{
                              textField: {
                                InputLabelProps: {
                                  style: {
                                    fontSize:
                                      morningChecked &&
                                      selectedDays.length !== 0 &&
                                      morningStartTime
                                        ? "15px"
                                        : "13px",
                                  },
                                },
                                sx: {
                                  "& .MuiOutlinedInput-root": {
                                    width: "125px",
                                    height: "35px",
                                    color: "#000",
                                    fontSize: "14px",
                                  },
                                },
                                size: "small",
                              },
                            }}
                            label={
                              <span style={{ fontSize: "14px" }}>From</span>
                            }
                            onChange={(time) => {
                              setMorningStartTime(time);
                              handleStartTimeChange("morning", time); // Call handleStartTimeChange on change
                            }}
                            disabled={
                              !morningChecked ||
                              !editMode ||
                              (!selectedDays.length == 0 ? false : true)
                            }
                            onError={errorMessage}
                          />
                        </DemoContainer>
                      </LocalizationProvider>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          sx={{ width: "140px", overflow: "hidden" }}
                          components={["TimePicker"]}
                        >
                          <DesktopTimePicker
                            closeOnSelect={false}
                            minTime={dayjs().set("hours", 8).set("minutes", 0)}
                            maxTime={dayjs().set("hours", 14).set("minutes", 0)}
                            slotProps={{
                              textField: {
                                InputLabelProps: {
                                  style: {
                                    fontSize:
                                      morningChecked &&
                                      selectedDays.length !== 0 &&
                                      morningEndTime
                                        ? "15px"
                                        : "13px",
                                  },
                                },
                                sx: {
                                  "& .MuiOutlinedInput-root": {
                                    width: "125px",
                                    height: "35px",
                                    color: "#000",
                                    fontSize: "14px",
                                  },
                                },
                                size: "small",
                              },
                            }}
                            label={<span style={{ fontSize: "14px" }}>To</span>}
                            value={
                              morningChecked && selectedDays.length !== 0
                                ? morningEndTime
                                : null
                            }
                            onChange={(time) => {
                              setMorningEndTime(time);
                              handleEndTimeChange("morning", time); // Call handleStartTimeChange on change
                            }}
                            disabled={
                              !morningChecked ||
                              !editMode ||
                              (!selectedDays.length == 0 ? false : true)
                            }
                            onError={errorMessage}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: { sm: "none", xs: "flex" } }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    // sx={{ marginRight: "29px" }}
                  >
                    <DemoContainer
                      sx={{
                        width: "125px",
                        overflow: "hidden",
                        marginRight: "5px",
                      }}
                      components={["TimePicker"]}
                    >
                      <DesktopTimePicker
                        closeOnSelect={false}
                        readOnly={!morningChecked && !editMode}
                        minTime={dayjs()
                          .set("hours", 8)
                          .set("minutes", 0)
                          .set("second", 0)}
                        maxTime={dayjs()
                          .set("hours", 14)
                          .set("minutes", 0)
                          .set("second", 0)}
                        value={
                          morningChecked && selectedDays.length !== 0
                            ? morningStartTime
                            : null
                        }
                        slotProps={{
                          textField: {
                            InputLabelProps: {
                              style: {
                                fontSize:
                                  morningChecked &&
                                  selectedDays.length !== 0 &&
                                  morningStartTime
                                    ? "15px"
                                    : "13px",
                              },
                            },
                            sx: {
                              "& .MuiOutlinedInput-root": {
                                width: "125px",
                                height: "35px",
                                color: "#000",
                                fontSize: "14px",
                              },
                            },
                            size: "small",
                          },
                        }}
                        label={<span style={{ fontSize: "14px" }}>From</span>}
                        onChange={(time) => {
                          setMorningStartTime(time);
                          handleStartTimeChange("morning", time); // Call handleStartTimeChange on change
                        }}
                        disabled={
                          !morningChecked ||
                          !editMode ||
                          (!selectedDays.length == 0 ? false : true)
                        }
                        onError={errorMessage}
                      />
                    </DemoContainer>
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      sx={{ width: "125px", overflow: "hidden" }}
                      components={["TimePicker"]}
                    >
                      <DesktopTimePicker
                        closeOnSelect={false}
                        minTime={dayjs().set("hours", 8).set("minutes", 0)}
                        maxTime={dayjs().set("hours", 14).set("minutes", 0)}
                        slotProps={{
                          textField: {
                            InputLabelProps: {
                              style: {
                                fontSize:
                                  morningChecked &&
                                  selectedDays.length !== 0 &&
                                  morningEndTime
                                    ? "15px"
                                    : "13px",
                              },
                            },
                            sx: {
                              "& .MuiOutlinedInput-root": {
                                width: "125px",
                                height: "35px",
                                color: "#000",
                                fontSize: "14px",
                              },
                            },
                            size: "small",
                          },
                        }}
                        label={<span style={{ fontSize: "14px" }}>To</span>}
                        value={
                          morningChecked && selectedDays.length !== 0
                            ? morningEndTime
                            : null
                        }
                        onChange={(time) => {
                          setMorningEndTime(time);
                          handleEndTimeChange("morning", time); // Call handleStartTimeChange on change
                        }}
                        disabled={
                          !morningChecked ||
                          !editMode ||
                          (!selectedDays.length == 0 ? false : true)
                        }
                        onError={errorMessage}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
                {/* ________________________________EVENING SECTION_____________________________ */}

                <Box sx={{ display: "flex", justifyContent: "left" }}>
                  <Box style={{ marginTop: "4px", marginRight: "5px" }}>
                    <FormControlLabel
                      value="evening"
                      sx={{
                        fontStyle: "14px",
                        ".MuiInputBase-root": {
                          width: "135px",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={eveningChecked}
                          onChange={handleDurationCheckbox}
                          sx={{
                            "&.Mui-checked": { color: "#243665" },
                            fontStyle: "14px",
                            color: dayTimeError ? "#d32f2f" : "",
                          }}
                          disabled={!selectedDays.length == 0 ? false : true}
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>Evening</span>}
                      labelPlacement="start"
                      style={{ marginLeft: "0px" }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: { xs: "", sm: "flex" },
                      justifyContent: "left",
                      marginLeft: { xs: "10px", md: "" },
                    }}
                  >
                    <Typography
                      style={{
                        marginTop: "15px",
                        marginRight: "15px",
                        fontSize: "14px",
                      }}
                    >
                      Duration
                    </Typography>
                    <Box sx={{ display: { sm: "flex", xs: "none" } }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          sx={{
                            width: { xs: "100px", sm: "140px" },
                            overflow: "hidden",
                            marginRight: "5px",
                          }}
                          components={["TimePicker"]}
                        >
                          <DesktopTimePicker
                            closeOnSelect={false}
                            minTime={dayjs()
                              .set("hours", 14)
                              .set("minutes", 0)
                              .set("seconds", 0)}
                            maxTime={dayjs()
                              .set("hours", 23)
                              .set("minutes", 0)
                              .set("seconds", 0)}
                            slotProps={{
                              textField: {
                                InputLabelProps: {
                                  style: {
                                    fontSize:
                                      eveningChecked &&
                                      selectedDays.length !== 0 &&
                                      eveningStartTime
                                        ? "15px"
                                        : "13px",
                                  },
                                },
                                sx: {
                                  "& .MuiOutlinedInput-root": {
                                    width: "125px",
                                    height: "35px",
                                    color: "#000",
                                    fontSize: "14px",
                                  },
                                },
                                size: "small",
                              },
                            }}
                            label={
                              <span style={{ fontSize: "14px" }}>From</span>
                            }
                            value={
                              eveningChecked && selectedDays.length !== 0
                                ? eveningStartTime
                                : null
                            }
                            onAccept={(time) =>
                              handleStartTimeChange("evening", time)
                            }
                            onChange={(time) => {
                              setEveningStartTime(time);
                              handleStartTimeChange("evening", time); // Call handleStartTimeChange on change
                            }}
                            disabled={
                              !eveningChecked ||
                              !editMode ||
                              (!selectedDays.length == 0 ? false : true)
                            }
                            onError={errorMessage}
                          />
                        </DemoContainer>
                      </LocalizationProvider>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          sx={{ width: "140px", overflow: "hidden" }}
                          components={["TimePicker"]}
                        >
                          <DesktopTimePicker
                            closeOnSelect={false}
                            minTime={dayjs()
                              .set("hours", 14)
                              .set("minutes", 0)
                              .set("seconds", 0)
                              .set("milliseconds", 0)}
                            maxTime={dayjs()
                              .set("hours", 23)
                              .set("minutes", 0)
                              .set("seconds", 0)
                              .set("milliseconds", 0)}
                            slotProps={{
                              textField: {
                                InputLabelProps: {
                                  style: {
                                    fontSize:
                                      eveningChecked &&
                                      selectedDays.length !== 0 &&
                                      eveningEndTime
                                        ? "15px"
                                        : "13px",
                                  },
                                },
                                sx: {
                                  "& .MuiOutlinedInput-root": {
                                    width: "125px",
                                    height: "35px",
                                    color: "#000",
                                    fontSize: "14px",
                                  },
                                },
                                size: "small",
                              },
                            }}
                            label={<span style={{ fontSize: "14px" }}>To</span>}
                            value={
                              eveningChecked && selectedDays.length !== 0
                                ? eveningEndTime
                                : null
                            }
                            onChange={(time) => {
                              setEveningEndTime(time);
                              handleEndTimeChange("evening", time); // Call handleStartTimeChange on change
                            }}
                            disabled={!eveningChecked || !editMode}
                            onError={errorMessage}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                  </Box>

                  {/* {startTimeError && (
                      <div style={{ color: "#d32f2f" }}>{startTimeError}</div>
                    )}
                    {endTimeError && (
                      <div style={{ color: "#d32f2f" }}>{endTimeError}</div>
                    )} */}
                </Box>
                <Box sx={{ display: { sm: "none", xs: "flex" } }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      sx={{
                        width: "125px",
                        overflow: "hidden",
                        marginRight: "5px",
                      }}
                      components={["TimePicker"]}
                    >
                      <DesktopTimePicker
                        closeOnSelect={false}
                        minTime={dayjs()
                          .set("hours", 14)
                          .set("minutes", 0)
                          .set("seconds", 0)}
                        maxTime={dayjs()
                          .set("hours", 23)
                          .set("minutes", 0)
                          .set("seconds", 0)}
                        slotProps={{
                          textField: {
                            InputLabelProps: {
                              style: {
                                fontSize:
                                  eveningChecked &&
                                  selectedDays.length !== 0 &&
                                  eveningStartTime
                                    ? "15px"
                                    : "13px",
                              },
                            },
                            sx: {
                              "& .MuiOutlinedInput-root": {
                                width: "125px",
                                height: "35px",
                                color: "#000",
                                fontSize: "14px",
                              },
                            },
                            size: "small",
                          },
                        }}
                        label={<span style={{ fontSize: "14px" }}>From</span>}
                        value={
                          eveningChecked && selectedDays.length !== 0
                            ? eveningStartTime
                            : null
                        }
                        onAccept={(time) =>
                          handleStartTimeChange("evening", time)
                        }
                        onChange={(time) => {
                          setEveningStartTime(time);
                          handleStartTimeChange("evening", time); // Call handleStartTimeChange on change
                        }}
                        disabled={
                          !eveningChecked ||
                          !editMode ||
                          (!selectedDays.length == 0 ? false : true)
                        }
                        onError={errorMessage}
                      />
                    </DemoContainer>
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      sx={{ width: "125px", overflow: "hidden" }}
                      components={["TimePicker"]}
                    >
                      <DesktopTimePicker
                        closeOnSelect={false}
                        minTime={dayjs()
                          .set("hours", 14)
                          .set("minutes", 0)
                          .set("seconds", 0)
                          .set("milliseconds", 0)}
                        maxTime={dayjs()
                          .set("hours", 23)
                          .set("minutes", 0)
                          .set("seconds", 0)
                          .set("milliseconds", 0)}
                        slotProps={{
                          textField: {
                            InputLabelProps: {
                              style: {
                                fontSize:
                                  eveningChecked &&
                                  selectedDays.length !== 0 &&
                                  eveningEndTime
                                    ? "15px"
                                    : "13px",
                              },
                            },
                            sx: {
                              "& .MuiOutlinedInput-root": {
                                width: "125px",
                                height: "35px",
                                color: "#000",
                                fontSize: "14px",
                              },
                            },
                            size: "small",
                          },
                        }}
                        label={<span style={{ fontSize: "14px" }}>To</span>}
                        value={
                          eveningChecked && selectedDays.length !== 0
                            ? eveningEndTime
                            : null
                        }
                        onChange={(time) => {
                          setEveningEndTime(time);
                          handleEndTimeChange("evening", time); // Call handleStartTimeChange on change
                        }}
                        disabled={!eveningChecked || !editMode}
                        onError={errorMessage}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
                <Box mt={1}>
                  {startTimeError && (
                    <div style={{ color: "#d32f2f", fontSize: "12px" }}>
                      {startTimeError}
                    </div>
                  )}
                </Box>
                {/* ______________SLOT SECTION_____________________ */}

                <div style={{ marginTop: "25px", marginLeft: "0px" }}>
                  <Typography className="heading">Slot Duration</Typography>
                  {userType == "SCAN" ? (
                    <>
                      <Typography style={{ fontSize: "14px" }}>
                        Configure multiple duration options for appointment
                        bookings. Select or enter up to (5) different durations.
                        (e.g., 15 mins, 30 mins, 45 mins).
                      </Typography>
                      <Box sx={{ display: "flex", mt: 2, height: "35px" }}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            textWrap: "nowrap",
                            alignContent: "center",
                          }}
                        >
                          Slot duration
                        </Typography>
                        <Box sx={{ display: "flex", position: "relative" }}>
                          <FormControl sx={{ ml: 2, minWidth: 110 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopTimePicker
                                views={["hours", "minutes"]}
                                format="HH:mm"
                                label="HH:MM"
                                ampm={false}
                                disabled={!editMode}
                                value={
                                  newValue
                                    ? dayjs(`2000-01-01T${newValue}:00`)
                                    : null
                                }
                                onChange={handleTimeChange}
                                shouldDisableTime={disableTimes}
                                slotProps={{
                                  textField: {
                                    InputLabelProps: {
                                      style: {
                                        fontSize: "13px",
                                      },
                                    },
                                    sx: {
                                      "& .MuiOutlinedInput-root": {
                                        width: "115px",
                                        height: "35px",
                                        color: "#000",
                                        fontSize: "14px",
                                        paddingRight: "30px",
                                      },
                                    },
                                    size: "small",
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          </FormControl>
                          <Box
                            sx={{ position: "absolute", right: 0, top: "5px" }}
                          >
                            <IconButton
                              sx={{
                                height: "25px",
                                padding: "0px",
                                alignContent: "center",
                              }}
                              onClick={handleOpenPopover}
                            >
                              <ArrowDropDownIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        {customValues.length < 5 && (
                          <IconButton
                            disabled={addDuration}
                            sx={{ marginLeft: "5px" }}
                          >
                            <Tooltip>
                              <ControlPointRoundedIcon
                                sx={{
                                  color: !addDuration ? "#41BA8F" : "#dbdbdb",
                                }}
                                onClick={handleAddValue}
                              />
                            </Tooltip>
                          </IconButton>
                        )}

                        {/* <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft:"20px" }}>
                    {customValues.map((value, index) => (
                      <Chip disabled={!editMode}
                        key={index}
                        label={convertToHoursMinutes(value)}
                        onDelete={() => handleRemoveValue(index)}
                        deleteIcon={<CancelRoundedIcon sx={{ color: "#BC123E !important" }} />}
                        sx={{  backgroundColor:"white", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                      />
                    ))}
                  </div> */}
                        {/* <Typography
                        style={{
                          marginTop: "25px",
                          marginLeft: "-15px",
                          fontSize: "14px",
                        }}
                      >
                        Mins
                      </Typography> */}
                      </Box>
                    </>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <Typography
                        style={{ marginTop: "25px", fontSize: "14px" }}
                      >
                        Slot duration
                      </Typography>
                      <FormControl sx={{ ml: 2, mt: 2, minWidth: 120 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopTimePicker
                            views={["hours", "minutes"]}
                            format="HH:mm"
                            label="HH:MM"
                            ampm={false}
                            disabled={!editMode}
                            value={dayjs(`2000-01-01T${newValue}:00`)}
                            shouldDisableTime={disableTimes}
                            onChange={handleTimeChange}
                            slotProps={{
                              textField: {
                                sx: {
                                  "& .MuiOutlinedInput-root": {
                                    width: "105px",
                                    height: "35px",
                                    color: "#000",
                                    fontSize: "14px",
                                  },
                                },
                                size: "small",
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      {/* <Typography
                      style={{
                        marginTop: "25px",
                        marginLeft: "-15px",
                        fontSize: "14px",
                      }}
                    >
                      Mins
                    </Typography> */}
                    </div>
                  )}
                </div>
                {userType == "SCAN" ? (
                  ""
                ) : (
                  <div
                    style={{
                      marginTop: "15px",
                      marginLeft: "0px",
                      display: "flex",
                    }}
                  >
                    <Typography sx={{ mt: 0.7, fontSize: "14px" }}>
                      Morning slots
                    </Typography>
                    <TextField
                      size="small"
                      id="morningSlot"
                      variant="outlined"
                      value={
                        morningChecked &&
                        selectedDays.length !== 0 &&
                        newValue != ""
                          ? morningSlots
                          : "0"
                      }
                      disabled
                      //  onChange={handleMorningSlotsChange}
                      inputProps={{
                        style: {
                          height: "15px",
                          fontSize: "14px",
                        },
                      }}
                      sx={{
                        minWidth: "46px",
                        maxWidth: "56px",
                        margin: "0px 0px 0px 10px",
                        height: "15px",
                        paddingBottom: "0px",
                        "&:hover": { border: "1px solid #000000" },
                        "&:hover": {
                          "&& fieldset": { border: "1px solid #000000" },
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #000000",
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          { border: "1px solid #000000" },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          { border: "1px solid #000000" },
                      }}
                    />
                    <Typography sx={{ mt: 0.7, ml: 2, fontSize: "14px" }}>
                      {" "}
                      Evening slots{" "}
                    </Typography>
                    <TextField
                      size="small"
                      id="eveningSlot"
                      variant="outlined"
                      value={
                        eveningChecked &&
                        selectedDays.length !== 0 &&
                        newValue != ""
                          ? eveningSlots
                          : "0"
                      }
                      disabled
                      // onChange={handleEveningSlotsChange}
                      inputProps={{
                        style: {
                          height: "15px",
                          fontSize: "14px",
                        },
                      }}
                      sx={{
                        minWidth: "46px",
                        maxWidth: "56px",
                        margin: "0px 0px 0px 10px",
                        height: "15px",
                        "&:hover": { border: "1px solid #000000" },
                        "&:hover": {
                          "&& fieldset": { border: "1px solid #000000" },
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #000000",
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          { border: "1px solid #000000" },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          { border: "1px solid #000000" },
                      }}
                    />
                  </div>
                )}

                <Box sx={{ marginTop: "15px" }}>
                  <Typography className="heading">General Settings</Typography>
                  <Box mt={1}>
                    <Typography style={{ fontSize: "14px" }}>
                      Allow multiple check-in
                    </Typography>
                    <FormControlLabel
                      value="morning"
                      sx={{ fontStyle: "14px" }}
                      control={
                        <Checkbox
                          sx={{
                            "&.Mui-checked": { color: "#243665" },
                            fontStyle: "14px",
                            color: dayTimeError ? "#d32f2f" : "",
                          }}
                          checked={multipleCheckIn}
                          onChange={(event) =>
                            setMultipleCheckIn(event.target.checked)
                          }
                          disabled={
                            !selectedDays.length == 0 && editMode ? false : true
                          }
                        />
                      }
                      label={
                        <Typography
                          style={{
                            fontSize: "14px",
                            color: !multipleCheckIn && "#00000061",
                          }}
                        >
                          Enable this option to allow multiple check-ins
                        </Typography>
                      }
                      labelPlacement="end"
                    />
                  </Box>
                  {userType == "DOCTOR" && (
                    <Box mt={1}>
                      <Typography style={{ fontSize: "14px" }}>
                        Allow booking for two patient
                      </Typography>
                      <FormControlLabel
                        value="morning"
                        sx={{ fontStyle: "14px" }}
                        control={
                          <Checkbox
                            sx={{
                              "&.Mui-checked": { color: "#243665" },
                              fontStyle: "14px",
                              color: dayTimeError ? "#d32f2f" : "",
                            }}
                            checked={multiplePatientQR}
                            onChange={(event) =>
                              setMultiplePatientQR(event.target.checked)
                            }
                            disabled={
                              !selectedDays.length == 0 && editMode
                                ? false
                                : true
                            }
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: "14px",
                              color: !multiplePatientQR && "#00000061",
                            }}
                          >
                            Enable this option to allow booking for two patient
                          </Typography>
                        }
                        labelPlacement="end"
                      />
                    </Box>
                  )}
                  <Box mt={1} sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        height: "35px",
                        alignContent: "center",
                        width: "210px",
                        mr: 2,
                      }}
                    >
                      <h5 style={{ fontSize: "15px", color: "#243665" }}>
                        Online Limited Appointment
                      </h5>
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <BasicSelect
                        value={onlineLimitedSlot}
                        displayEmpty
                        disabled={!editMode}
                        size="small"
                        onChange={handleOnlineLimitedSlotChange}
                        sx={{
                          width: "70px",
                          height: "35px",
                        }}
                      >
                        <MenuItem sx={{ fontSize: "14px" }} value={"YES"}>
                          Yes
                        </MenuItem>
                        <MenuItem sx={{ fontSize: "14px" }} value={"NO"}>
                          No
                        </MenuItem>
                      </BasicSelect>
                      <OutlinedTextField
                        size="small"
                        id="qrSlot"
                        placeholder="0"
                        disabled={!editMode || onlineLimitedSlot == "NO"}
                        autoComplete="off"
                        value={onlineLimitedSlotCount}
                        onChange={handleOnlineLimitedSlotInputChanges}
                        sx={{
                          width: "70px",
                          height: "35px",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} mt={3}>
                <Typography className="heading">QR Code Settings</Typography>

                <Typography style={{ fontSize: "14px" }}>
                  Configure your preferences for QR Code-based bookings.
                </Typography>
                <Box sx={{ alignItems: "center", gap: 1, mt: 2 }}>
                  <FormControl sx={{ mr: 2 }} fullWidth>
                    <Typography
                      sx={{
                        height: "35px",
                        alignContent: "center",
                        width: "156px",
                      }}
                    >
                      <h5 style={{ fontSize: "15px", color: "#243665" }}>
                        QR Booking Duration
                      </h5>
                    </Typography>
                  </FormControl>
                  <FormControl>
                    <Box
                      sx={{ display: { md: "flex", xs: "flex" }, gap: "10px" }}
                    >
                      <BasicSelect
                        value={qRSlotDurationSelection}
                        displayEmpty
                        disabled={!editMode}
                        size="small"
                        onChange={handleQRSlotDurationChange}
                        onBlur={validatePrefix}
                        sx={{
                          width: "70px",
                          height: "35px",
                        }}
                      >
                        <MenuItem sx={{ fontSize: "14px" }} value={"YES"}>
                          Yes
                        </MenuItem>
                        <MenuItem sx={{ fontSize: "14px" }} value={"NO"}>
                          No
                        </MenuItem>
                      </BasicSelect>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {/* From Time */}
                        <DesktopTimePicker
                          closeOnSelect={false}
                          value={qrTimeRange?.from || null}
                          onChange={(time) =>
                            handleQrSlotTimeChange("from", time)
                          }
                          slotProps={{
                            textField: {
                              InputLabelProps: {
                                style: {
                                  fontSize:
                                    qrTimeRange?.from != "" ? "15px" : "13px",
                                },
                              },
                              sx: {
                                "& .MuiOutlinedInput-root": {
                                  width: "125px",
                                  height: "35px",
                                  color: "#000",
                                  fontSize: "14px",
                                },
                              },
                              size: "small",
                            },
                          }}
                          label={"From"}
                          disabled={
                            !editMode || qRSlotDurationSelection == "NO"
                          }
                        />

                        {/* To Time */}
                        <DesktopTimePicker
                          closeOnSelect={false}
                          // minTime={qrTimeRange.from}
                          // shouldDisableTime={disableDates}
                          value={qrTimeRange?.to || null}
                          onChange={(time) =>
                            handleQrSlotTimeChange("to", time)
                          }
                          slotProps={{
                            textField: {
                              InputLabelProps: {
                                style: {
                                  fontSize:
                                    qrTimeRange?.to != "" ? "15px" : "13px",
                                },
                              },
                              sx: {
                                "& .MuiOutlinedInput-root": {
                                  width: "125px",
                                  height: "35px",
                                  color: "#000",
                                  fontSize: "14px",
                                },
                              },
                              size: "small",
                            },
                          }}
                          label={"To"}
                          disabled={
                            !editMode ||
                            !qrTimeRange.from ||
                            qRSlotDurationSelection == "NO"
                          }
                        />
                      </LocalizationProvider>
                    </Box>
                  </FormControl>
                  <Typography sx={{ fontSize: "14px", marginTop: 2 }}>
                    If enabled, specify the time duration during which
                    appointments can be booked via QR Code.
                  </Typography>
                </Box>
                <Box mt={3} sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      height: "35px",
                      alignContent: "center",
                      width: "156px",
                      mr: 2,
                    }}
                  >
                    <h5 style={{ fontSize: "15px", color: "#243665" }}>
                      QR Limited Slots
                    </h5>
                  </Typography>

                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    <BasicSelect
                      value={qrCodeSlot}
                      displayEmpty
                      disabled={!editMode}
                      size="small"
                      onChange={handleQrCodeSlotChange}
                      onBlur={validatePrefix}
                      sx={{
                        width: "70px",
                        height: "35px",
                      }}
                    >
                      <MenuItem sx={{ fontSize: "14px" }} value={"YES"}>
                        Yes
                      </MenuItem>
                      <MenuItem sx={{ fontSize: "14px" }} value={"NO"}>
                        No
                      </MenuItem>
                    </BasicSelect>
                    <OutlinedTextField
                      size="small"
                      id="qrSlot"
                      placeholder="0"
                      disabled={!editMode || qrCodeSlot == "NO"}
                      autoComplete="off"
                      value={qrSlotValue}
                      onChange={handleQrSlotsInputChanges}
                      sx={{
                        width: "70px",
                        height: "35px",
                      }}
                    />
                  </Box>
                </Box>
                <Typography sx={{ fontSize: "14px", marginTop: 2 }}>
                  Restrict the number of bookings allowed through QR Code for a
                  specific time frame.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item display={{ xs: "none", lg: "block" }} xs={1} xl={1}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
              }}
            >
              <Divider
                orientation="vertical"
                sx={{ backgroundColor: "#C6C7C7" }}
                flexItem
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={5.5} xl={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className="heading">Token Generation</Typography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    value={selectedTokenOption}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      labelPlacement="end"
                      value="SEQUENTIAL"
                      control={
                        <Radio sx={{ "&.Mui-checked": { color: "#243665" } }} />
                      }
                      label={
                        <Typography sx={{ fontSize: "14px" }}>
                          {" "}
                          Sequential Number (1, 2, 3…){" "}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      labelPlacement="end"
                      value="RANDOM"
                      control={
                        <Radio sx={{ "&.Mui-checked": { color: "#243665" } }} />
                      }
                      label={
                        <Typography sx={{ fontSize: "14px" }}>
                          {" "}
                          Random Number (2 digits){" "}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>

                <div style={{ display: "flex", justifyContent: "left" }}>
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                      marginTop: "15px",
                      marginRight: "20px",
                    }}
                  >
                    Prefix&nbsp;?
                  </p>

                  <FormControl sx={{ m: 1, mr: 2 }}>
                    <BasicSelect
                      value={prefixSelection}
                      displayEmpty
                      disabled={!editMode}
                      inputProps={{ "aria-label": "Without label" }}
                      size="small"
                      onChange={handlePrefixChange}
                      onBlur={validatePrefix}
                      sx={{
                        width: "90px",
                        height: "35px",
                      }}
                    >
                      <MenuItem sx={{ fontSize: "14px" }} value={"YES"}>
                        Yes
                      </MenuItem>
                      <MenuItem sx={{ fontSize: "14px" }} value={"NO"}>
                        No
                      </MenuItem>
                    </BasicSelect>
                  </FormControl>
                  <div>
                    <TextField
                      size="small"
                      id="morningSlot"
                      variant="outlined"
                      autoComplete="off"
                      disabled={prefixSelection === "NO"}
                      onChange={handlePrefixValueChange}
                      value={prefixValue}
                      onBlur={validatePrefix}
                      error={Boolean(prefixError)}
                      inputProps={{
                        maxLength: 3,
                        style: { height: "13px", fontSize: "14px" },
                      }}
                      sx={{
                        width: "120px",
                        height: "35px",
                        marginTop: "8px",
                        // marginLeft: "-25px",
                        "&:hover": { border: "1px solid #000000" },
                        "&:hover": {
                          "&& fieldset": { border: "1px solid #000000" },
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #000000",
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          { border: "1px solid #000000" },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          { border: "1px solid #000000" },
                      }}
                    />
                    <FormHelperText sx={{ paddingTop: "5px" }}>
                      Min. 1 to Max. 3 chars
                    </FormHelperText>
                  </div>
                </div>
                {prefixSelection === "YES" && (
                  <Typography sx={{ fontSize: "14px" }}>
                    Example: ABC1, ABC2, ABC3
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography className="heading">IVR Configuration</Typography>
                <Typography sx={{ marginTop: "5px", fontSize: "14px" }}>
                  Based on the settings here, the IVR system will check the
                  availability of open appointments and prompt the caller
                  accordingly.
                </Typography>

                <Box>
                  <FormControl sx={{ mt: 2, mr: "10px" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <h5 style={{ fontSize: "15px", color: "#243665" }}>No. of days</h5> */}
                      <Typography
                        sx={{
                          height: "35px",
                          width: "126px",
                          alignContent: "center",
                          mr: 2,
                        }}
                      >
                        <h5 style={{ fontSize: "15px", color: "#243665" }}>
                          No. of days
                        </h5>
                      </Typography>
                      <Box>
                        <FormControl fullWidth>
                          <BasicSelect
                            value={ivrConfigDay}
                            onChange={handleIvrConfigDayChange}
                            displayEmpty
                            disabled={!editMode}
                            sx={{
                              width: "70px",
                              height: "35px",
                            }}
                          >
                            <MenuItem value="1">
                              <Typography sx={{ fontSize: "14px" }}>
                                1
                              </Typography>
                            </MenuItem>
                            <MenuItem value="2">
                              <Typography sx={{ fontSize: "14px" }}>
                                2
                              </Typography>
                            </MenuItem>
                          </BasicSelect>
                        </FormControl>
                      </Box>
                    </Box>
                  </FormControl>
                  <FormControl sx={{ mt: 2 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          height: "35px",
                          width: "126px",
                          alignContent: "center",
                          mr: 2,
                        }}
                      >
                        <h5 style={{ fontSize: "15px", color: "#243665" }}>
                          No. of slots
                        </h5>
                      </Typography>
                      <Box>
                        <FormControl fullWidth>
                          <BasicSelect
                            value={ivrConfigSlot}
                            onChange={handleIvrConfigSlotChange}
                            displayEmpty
                            disabled={!editMode}
                            sx={{
                              width: "70px",
                              height: "35px",
                            }}
                          >
                            <MenuItem value="1">
                              <Typography sx={{ fontSize: "14px" }}>
                                1
                              </Typography>
                            </MenuItem>
                            <MenuItem value="2">
                              <Typography sx={{ fontSize: "14px" }}>
                                2
                              </Typography>
                            </MenuItem>
                          </BasicSelect>
                        </FormControl>
                      </Box>
                    </Box>
                  </FormControl>
                </Box>

                <Box sx={{ alignItems: "center", mt: 2 }}>
                  <FormControl sx={{ mr: 2 }} fullWidth>
                    <Typography
                      sx={{
                        height: "35px",
                        width: "145px",
                        alignContent: "center",
                      }}
                    >
                      <h5 style={{ fontSize: "15px", color: "#243665" }}>
                        IVR Server Duration
                      </h5>
                    </Typography>
                  </FormControl>
                  <FormControl>
                    <Box
                      sx={{ display: { md: "flex", xs: "flex" }, gap: "10px" }}
                    >
                      <BasicSelect
                        value={ivrServerDurationSelection}
                        displayEmpty
                        disabled={!editMode}
                        size="small"
                        onChange={(e) =>
                          setIvrServerDurationSelection(e.target.value)
                        }
                        onBlur={validatePrefix}
                        sx={{
                          width: "70px",
                          height: "35px",
                        }}
                      >
                        <MenuItem sx={{ fontSize: "14px" }} value={"YES"}>
                          Yes
                        </MenuItem>
                        <MenuItem sx={{ fontSize: "14px" }} value={"NO"}>
                          No
                        </MenuItem>
                      </BasicSelect>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {/* From Time */}
                        <DesktopTimePicker
                          closeOnSelect={false}
                          value={serverTimeRange?.from || null}
                          onChange={(time) =>
                            handleServerTimeChange("from", time)
                          }
                          slotProps={{
                            textField: {
                              InputLabelProps: {
                                style: {
                                  fontSize:
                                    serverTimeRange?.from != ""
                                      ? "15px"
                                      : "13px",
                                },
                              },
                              sx: {
                                "& .MuiOutlinedInput-root": {
                                  width: "125px",
                                  height: "35px",
                                  color: "#000",
                                  fontSize: "14px",
                                },
                                ".MuiIconButton-root": {
                                  marginRight: "0px",
                                  padding: "0px",
                                },
                              },
                              size: "small",
                            },
                          }}
                          label={"From"}
                          disabled={
                            !editMode || ivrServerDurationSelection === "NO"
                          }
                        />

                        {/* To Time */}
                        <DesktopTimePicker
                          closeOnSelect={false}
                          value={serverTimeRange?.to || null}
                          onChange={(time) =>
                            handleServerTimeChange("to", time)
                          }
                          slotProps={{
                            textField: {
                              InputLabelProps: {
                                style: {
                                  fontSize:
                                    serverTimeRange?.to != "" ? "15px" : "13px",
                                },
                              },
                              sx: {
                                "& .MuiOutlinedInput-root": {
                                  width: "125px",
                                  height: "35px",
                                  color: "#000",
                                  fontSize: "14px",
                                },
                              },
                              size: "small",
                            },
                          }}
                          label={"To"}
                          disabled={
                            !editMode ||
                            !serverTimeRange.from ||
                            ivrServerDurationSelection === "NO"
                          }
                        />
                      </LocalizationProvider>
                    </Box>
                  </FormControl>
                  <Typography sx={{ fontSize: "14px", marginTop: 1 }}>
                    If enabled, specify the time duration during which the IVR
                    server and system will remain active and operational.
                  </Typography>
                </Box>

                <Box sx={{ alignItems: "center", mt: 2 }}>
                  <FormControl sx={{ mr: 2 }} fullWidth>
                    <Typography
                      sx={{
                        height: "35px",
                        width: "145px",
                        alignContent: "center",
                      }}
                    >
                      <h5 style={{ fontSize: "15px", color: "#243665" }}>
                        IVR Slot Duration
                      </h5>
                    </Typography>
                  </FormControl>
                  <FormControl>
                    <Box
                      sx={{ display: { md: "flex", xs: "flex" }, gap: "10px" }}
                    >
                      <BasicSelect
                        value={ivrSlotDurationSelection}
                        displayEmpty
                        disabled={!editMode}
                        size="small"
                        onChange={handleIvrSlotDurationChange}
                        onBlur={validatePrefix}
                        sx={{
                          width: "70px",
                          height: "35px",
                        }}
                      >
                        <MenuItem sx={{ fontSize: "14px" }} value={"YES"}>
                          Yes
                        </MenuItem>
                        <MenuItem sx={{ fontSize: "14px" }} value={"NO"}>
                          No
                        </MenuItem>
                      </BasicSelect>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {/* From Time */}
                        <DesktopTimePicker
                          closeOnSelect={false}
                          value={timeRange?.from || null}
                          onChange={(time) =>
                            handleSlotTimeChange("from", time)
                          }
                          slotProps={{
                            textField: {
                              InputLabelProps: {
                                style: {
                                  fontSize:
                                    timeRange?.from != "" ? "15px" : "13px",
                                },
                              },
                              sx: {
                                "& .MuiOutlinedInput-root": {
                                  width: "125px",
                                  height: "35px",
                                  color: "#000",
                                  fontSize: "14px",
                                },
                                ".MuiIconButton-root": {
                                  marginRight: "0px",
                                  padding: "0px",
                                },
                              },
                              size: "small",
                            },
                          }}
                          label={"From"}
                          disabled={
                            !editMode || ivrSlotDurationSelection === "NO"
                          }
                        />

                        {/* To Time */}
                        <DesktopTimePicker
                          closeOnSelect={false}
                          value={timeRange?.to || null}
                          onChange={(time) => handleSlotTimeChange("to", time)}
                          slotProps={{
                            textField: {
                              InputLabelProps: {
                                style: {
                                  fontSize:
                                    timeRange?.to != "" ? "15px" : "13px",
                                },
                              },
                              sx: {
                                "& .MuiOutlinedInput-root": {
                                  width: "125px",
                                  height: "35px",
                                  color: "#000",
                                  fontSize: "14px",
                                },
                              },
                              size: "small",
                            },
                          }}
                          label={"To"}
                          disabled={
                            !editMode ||
                            !timeRange.from ||
                            ivrSlotDurationSelection === "NO"
                          }
                        />
                      </LocalizationProvider>
                    </Box>
                  </FormControl>
                  <Typography sx={{ fontSize: "14px", marginTop: 1 }}>
                    If enabled, specify the time duration during which
                    appointments can be booked via IVR.
                  </Typography>
                </Box>

                <Box sx={{ mt: 2, display: "flex" }}>
                  <Typography
                    sx={{
                      height: "35px",
                      width: "145px",
                      alignContent: "center",
                      mr: 2,
                    }}
                  >
                    <h5 style={{ fontSize: "15px", color: "#243665" }}>
                      IVR Limited Slots
                    </h5>
                  </Typography>

                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <BasicSelect
                      value={ivrSlotLimit}
                      displayEmpty
                      disabled={!editMode}
                      size="small"
                      onChange={handleIVRSlotLimitChange}
                      onBlur={validatePrefix}
                      sx={{
                        width: "70px",
                        height: "35px",
                      }}
                    >
                      <MenuItem sx={{ fontSize: "14px" }} value={"YES"}>
                        Yes
                      </MenuItem>
                      <MenuItem sx={{ fontSize: "14px" }} value={"NO"}>
                        No
                      </MenuItem>
                    </BasicSelect>
                    <OutlinedTextField
                      disabled={ivrSlotLimit === "NO"}
                      id="morningSlot"
                      placeholder="0"
                      onChange={handleIVRSlotLimitValueChange}
                      value={iVRSlotLimitValue}
                      onBlur={validatePrefix}
                      sx={{
                        width: "70px",
                        height: "35px",
                      }}
                    />
                  </Box>
                </Box>
                <Typography sx={{ fontSize: "14px", marginTop: 1 }}>
                  Restrict the number of bookings allowed through IVR for a
                  specific time frame.
                </Typography>
              </Grid>
              <Grid item xs={12} mt={2}>
                <Typography className="heading">Notifications</Typography>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{ fontSize: "14px", marginTop: 1 }}>
                    Our dual notification system prioritizes WhatsApp for
                    instant communication. If WhatsApp is unavailable, it
                    quickly switches to SMS to ensure all set alerts are
                    delivered.
                  </Typography>
                  <FormControlLabel
                    value="whatsapp_trigger"
                    sx={{ fontStyle: "14px" }}
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": { color: "#243665" },
                          fontStyle: "14px",
                        }}
                        checked={notifications.includes("whatsapp_trigger")}
                        onChange={() =>
                          handleChangeNotification("whatsapp_trigger")
                        }
                        disabled={editMode ? false : true}
                      />
                    }
                    label={
                      <Typography
                        style={{
                          fontSize: "14px",
                          color:
                            !notifications.includes("whatsapp_trigger") &&
                            "#00000061",
                        }}
                      >
                        Enable WhatsApp Notifications
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="three_token_away"
                    sx={{ fontStyle: "14px" }}
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": { color: "#243665" },
                          fontStyle: "14px",
                        }}
                        checked={notifications.includes("three_token_away")}
                        onChange={() =>
                          handleChangeNotification("three_token_away")
                        }
                        disabled={editMode ? false : true}
                      />
                    }
                    label={
                      <Typography
                        style={{
                          fontSize: "14px",
                          color:
                            !notifications.includes("three_token_away") &&
                            "#00000061",
                        }}
                      >
                        Enable Three Token Away Notifications
                      </Typography>
                    }
                    labelPlacement="end"
                  />

                  <FormControlLabel
                    value="qr_notification"
                    sx={{ fontStyle: "14px" }}
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": { color: "#243665" },
                          fontStyle: "14px",
                        }}
                        checked={notifications.includes("qr_notification")}
                        onChange={() =>
                          handleChangeNotification("qr_notification")
                        }
                        disabled={editMode ? false : true}
                      />
                    }
                    label={
                      <Typography
                        style={{
                          fontSize: "14px",
                          color:
                            !notifications.includes("qr_notification") &&
                            "#00000061",
                        }}
                      >
                        Enable QR Codes in the Booking Confirmation on WhatsApp.
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="whatsapp_confirmation"
                    sx={{ fontStyle: "14px" }}
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": { color: "#243665" },
                          fontStyle: "14px",
                        }}
                        checked={notifications.includes(
                          "whatsapp_confirmation"
                        )}
                        onChange={() =>
                          handleChangeNotification("whatsapp_confirmation")
                        }
                        disabled={editMode ? false : true}
                      />
                    }
                    label={
                      <Typography
                        style={{
                          fontSize: "14px",
                          color:
                            !notifications.includes("whatsapp_confirmation") &&
                            "#00000061",
                        }}
                      >
                        Enable Patient confirmation for next day appointment on
                        WhatsApp (Review Appointments Only)
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="ivr_call_confirmation"
                    sx={{ fontStyle: "14px" }}
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": { color: "#243665" },
                          fontStyle: "14px",
                        }}
                        checked={notifications.includes(
                          "ivr_call_confirmation"
                        )}
                        onChange={() =>
                          handleChangeNotification("ivr_call_confirmation")
                        }
                        disabled={editMode ? false : true}
                      />
                    }
                    label={
                      <Typography
                        style={{
                          fontSize: "14px",
                          color:
                            !notifications.includes("ivr_call_confirmation") &&
                            "#00000061",
                        }}
                      >
                        Enable Patient confirmation for next day appointment on
                        IVR Call (Review Appointments Only)
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="bulk_delay_notification"
                    sx={{ fontStyle: "14px" }}
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": { color: "#243665" },
                          fontStyle: "14px",
                        }}
                        checked={notifications.includes(
                          "bulk_delay_notification"
                        )}
                        onChange={() =>
                          handleChangeNotification("bulk_delay_notification")
                        }
                        disabled={editMode ? false : true}
                      />
                    }
                    label={
                      <Typography
                        style={{
                          fontSize: "14px",
                          color:
                            !notifications.includes(
                              "bulk_delay_notification"
                            ) && "#00000061",
                        }}
                      >
                        Enable Notification for Bulk Delay or Cancelled.
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* </Card> */}
      </Box>
      {/* </Grid> */}

      <Popover
        open={openTime}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {customValues.length > 0 ? (
          customValues.map((value, index) => (
            <MenuItem key={index} sx={{ padding: 0 }}>
              <ListItem
                sx={{ padding: "8px 52px 8px 8px" }}
                secondaryAction={
                  editMode && (
                    <IconButton
                      edge="end"
                      onClick={() => handleRemoveValue(index)}
                      sx={{ padding: "3px" }}
                    >
                      <CancelRoundedIcon
                        sx={{ color: "red", fontSize: "20px" }}
                      />
                    </IconButton>
                  )
                }
              >
                <ListItemText
                  primary={
                    <Typography style={{ fontSize: "15px" }}>
                      {convertToHoursMinutes(value)}
                    </Typography>
                  }
                />
              </ListItem>
            </MenuItem>
          ))
        ) : (
          <MenuItem style={{ fontSize: "15px" }}>No duration added</MenuItem>
        )}
      </Popover>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loaderopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ConfirmationPop
          Data={popupData}
          yesSubmit={yesSubmit}
          NoSubmit={() => {
            closeOpenPopup(popupData.Purpose);
          }}
        />
      </Modal>
      <Modal
        open={confirmOpen}
        // onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SettingConfirmationPopup
          Data={popupData}
          confirmSubmit={confirmSubmit}
          NoSubmit={() => {
            setConfirmOpen(false);
            setPopUpData({
              Purpose: "",
              message: "",
              DataDetails: "",
              method: "",
            });
          }}
        />
      </Modal>
    </Grid>
  );
}
