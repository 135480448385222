import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Controller from "../../../Controller/ApiController";
import CircularProgress from "@mui/material/CircularProgress";
import QGLogo from "../../../Assets/qgliderlogo.png";
import { SubmitButton } from "../../ReuseComponents/Button/BasicButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Alert } from "../../ReuseComponents/Alert/Alertmessage";
import validationRegExp from "../../../Configuration/ValidationConfig";

export default function ContactUS() {
  const [loaderOpen, setLoaderOpen] = useState(false);
  const initialFormData = {
    firstName: {
      value: "",
      error: "",
    },
    lastName: {
      value: "",
      error: "",
    },
    email: {
      value: "",
      error: "",
    },
    mobileNumber: {
      value: "",
      error: "",
    },
    description: {
      value: "",
      error: "",
    },
  };
  const [formData, setFormData] = useState(initialFormData);
  const [expanded, setExpanded] = React.useState(false);

  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const submitContactUs = async () => {
    let errorUpdateData = formData;
    let error = false;

    if (errorUpdateData.firstName.value == "") {
      errorUpdateData.firstName.error = "First Name is Mandatory";
      error = true;
    }

    if (errorUpdateData.lastName.value == "") {
      errorUpdateData.lastName.error = "First Name is Mandatory";
      error = true;
    }

    if (errorUpdateData.email.value == "") {
      errorUpdateData.email.error = "First Name is Mandatory";
      error = true;
    }

    if (
      errorUpdateData.mobileNumber.value != "" &&
      errorUpdateData.mobileNumber.value.length != 10
    ) {
      errorUpdateData.mobileNumber.error = "Invalid Mobile Number";
      error = true;
    }

    if (error) {
      setFormData({ ...errorUpdateData });
    }

    const submitData = {
      first_name: formData.firstName.value || "",
      last_name: formData.lastName.value || "",
      email: formData.email.value || "",
      mobile_number: formData.mobileNumber.value || "",
      description: formData.description.value || "",
    };
    try {
      setLoaderOpen(true);
      const response = await Controller.ApiController(
        "POST",
        "/contact_email",
        "",
        submitData
      );
      setLoaderOpen(false);
      console.log(response, "responseoiresponse");
      if (response.type == "success") {
        Alert("success", "Contacted Successfully");
        setTimeout(() => {
          setFormData(initialFormData);
        }, 2000);
      } else if (response.status == "FAILED") {
        Alert("error", response?.error?.message || "Something went wrong");
      } else {
        console.log("Contact US Response Error: ", response.error.message);
        Alert("error", "Something went wrong");
      }
    } catch (e) {
      setLoaderOpen(false);
      Alert("error", "Something went wrong");
      console.log("Contact US Error: ", e.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (!validationRegExp.noEmptySpaceStart(value)) {
      return;
    }

    if (name == "mobileNumber" && !validationRegExp.mobileNumber(value)) {
      return;
    }

    if (
      (name == "firstName" || name == "lastName") &&
      !validationRegExp.fullNameReg(value)
    ) {
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: {
        value: value,
        error: "",
      },
    }));
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={2}
          xl={2}
          textAlign={{
            xs: "center",
            sm: "left",
          }}
        >
          <Box
            component={"img"}
            src={QGLogo}
            sx={{
              width: { xs: "159px", md: "256px" },
              height: { xs: "69px", md: "91px" },
            }}
          />
        </Grid>
      </Grid>
      {/* <Grid container spacing={2}>
        <Grid item xs={12} md={6} mt={3} sx={{borderRight: {md: "1px solid #000"}}}>
          <div style={{ fontFamily: "Poppins", fontSize: "16px" }}>
            <h1 style={{ fontSize: "30px" }}>
              Frequently Asked Questions (FAQ) – Contact Us
            </h1>
            <h1 style={{ fontSize: "24px" }}>
              1. How can I contact customer support?
            </h1>
            <p>
              You can reach our customer support team via email at [your email],
              by phone at [your phone number], or by filling out the contact
              form on our website.
            </p>
            <h1 style={{ fontSize: "24px" }}>
              2. What are your customer service hours?
            </h1>
            <p>
              Our support team is available [days of the week] from [time] (your
              timezone). We strive to respond to all inquiries within [response
              time, e.g., 24-48 hours].
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign={"center"}>
              <Typography
                sx={{
                  fontSize: "30px",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                }}
              >
                Contact Us
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: "14px", fontFamily: "Poppins" }}>
                First Name
              </Typography>
              <TextField
                fullWidth
                size="small"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px"
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: "14px", fontFamily: "Poppins" }}>
                Last Name
              </Typography>
              <TextField
                fullWidth
                size="small"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px"
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: "14px", fontFamily: "Poppins" }}>
                Email Address
              </Typography>
              <TextField
                fullWidth
                size="small"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px"
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: "14px", fontFamily: "Poppins" }}>
                Mobile Number
              </Typography>
              <TextField
                fullWidth
                size="small"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px"
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "14px", fontFamily: "Poppins" }}>
                Description
              </Typography>
              <TextField
                fullWidth
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  "& .MuiInputBase-input": {
                    padding: "12px", // Padding for the text inside the multiline field
                  },
                }}
                InputProps={{
                  sx: { padding: "12px" }, // Adjusts padding for the input container
                }}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
              <SubmitButton onClick={submitContactUs}>Submit</SubmitButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} mt={3}>
          <Typography variant="h4" sx={{ fontWeight: 600 }} mb={2}>
            Frequently Asked Questions
          </Typography>

          {[
            {
              question: "What is QGlider?",
              answer:
                "QGlider is a software solution designed to streamline the process of booking appointments, managing queues, visiting doctors, and exiting medical premises with ease.",
            },
            {
              question: "Can I book an appointment in advance?",
              answer:
                "Yes, you can schedule an appointment with your preferred doctor in advance through the QGlider app or web portal.",
            },
            {
              question: "How does QGlider reduce waiting time?",
              answer:
                "QGlider provides real-time updates on queue status and notifies patients when their turn is approaching, allowing them to arrive just in time for their appointment.",
            },
            {
              question: "Do I need to visit the clinic to book an appointment?",
              answer:
                "No, you can book your appointment online from anywhere using the QGlider app or website.",
            },
            {
              question: "Can I reschedule or cancel my appointment?",
              answer:
                "Yes, you can reschedule or cancel your appointment through the app based on the doctor’s availability and cancellation policies.",
            },
          ].map((faq, index) => (
            // <Card key={index} sx={{ minWidth: 275, mt: 2 }}>
            //   <CardContent>
            //     <Typography
            //       variant="h6"
            //       component="div"
            //       sx={{ fontWeight: 600 }}
            //     >
            //       {faq.question}
            //     </Typography>
            //     <Typography variant="body2" color="text.secondary">
            //       {faq.answer}
            //     </Typography>
            //   </CardContent>
            // </Card>
            <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleAccordianChange(`panel${index}`)}
              sx={{backgroundColor: expanded === `panel${index}` && "#e6e6e6"}}
              mt={1}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ fontWeight: 500 }}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" color="text.secondary">
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: "#E6E6E6",
              margin: { md: "10px 50px" },
              padding: "20px 60px",
              borderRadius: "10px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign={"center"}>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
                  Contact Us
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="First Name *"
                  name="firstName"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      height: "40px !important",
                    },
                  }}
                  value={formData.firstName.value}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Last Name *"
                  variant="outlined"
                  name="lastName"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      height: "40px !important",
                    },
                  }}
                  value={formData.lastName.value}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email *"
                  variant="outlined"
                  name="email"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      height: "40px !important",
                    },
                  }}
                  value={formData.email.value}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Mobile Number *"
                  variant="outlined"
                  name="mobileNumber"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      height: "40px !important",
                    },
                  }}
                  value={formData.mobileNumber.value}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description *"
                  multiline
                  rows={2} // Adjust number of visible rows
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "70px", // Adjust padding inside the text field
                    },
                  }}
                  name="description"
                  value={formData.description.value}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} textAlign={"center"}>
                <SubmitButton onClick={submitContactUs} sx={{ width: "100%" }}>
                  Send Message
                </SubmitButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 100000 }}
        open={loaderOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
