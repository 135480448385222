// Name and Mobile Number validation

const validationRegExp = {
   mobileNumberReg: new RegExp(/^[\d]{0,10}$/),
   mobileNumberEditReg: new RegExp(/^[\d]{0,11}$/),
   emailPatternReg: new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
   passwordReg: new RegExp(/^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^0-9A-Za-z]).{8,12}$/),
   noEmptySpaceStart: (value) => {
      try {
         return new RegExp(/^(?! ).{0,}$/).test(value);
      } catch (error) {
         console.log(error.message);
         return false;
      }
   },
   fullName: (value) => {
       try {
           return new RegExp(/^(?! )[a-zA-Z ]{0,}$/).test(value);
       } catch (error) {
           console.log(error.message);
           return false;
       }
   },
   fullNameRegEdit: (value) => {
       try {
           return new RegExp(/^(?! )[a-zA-Z -]{0,}$/).test(value);
       } catch (error) {
           console.log(error.message);
           return false;
       }
   },
   fullNameReg: (value) => {
       try {
           return new RegExp(/^(?! )[a-zA-Z -.]{0,}$/).test(value);
       } catch (error) {
           console.log(error.message);
           return false;
       }
   },
   mobileNumber: (value) => {
       try {
           return new RegExp(/^[\d]{0,10}$/).test(value);
       } catch (error) {
           console.log(error.message);
           return false;
       }
   },
   number: (value) => {
       try {
           return new RegExp(/^[\d]{0,}$/).test(value);
       } catch (error) {
           console.log(error.message);
           return false;
       }
   },
   name: (value) => {
       try {
           return new RegExp(/^(?! )[a-zA-Z ]{0,}$/).test(value);
       } catch (error) {
           console.log(error.message);
           return false;
       }
   },
   alphaNumericHifen: (value) => {
       try {
           return new RegExp(/^(?! )[a-zA-Z\s\d-,.]{0,}$/).test(value);
       } catch (error) {
           console.log(error.message);
           return false;
       }
   },
}

export default validationRegExp;