import { Button, styled } from "@mui/material";

const ContainedButtonCustomized = styled(Button)(({ theme }) => {
  return {
    color: "#fff",
    backgroundColor: "#41BA8F",
    textTransform: "none",
    height: "30px",
    "&:hover": {
      backgroundColor: "#41BA8F",
    },
    // border: "1px solid #41BA8F",
    "&.Mui-disabled": {
      border: "none !important",
    },
  };
});

const OutlinedButtonCustomized = styled(Button)(({ theme }) => {
  return {
    color: "#000",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      border: "1px solid #000",
    },
    border: "1px solid #C4C4C4",
  };
});

const CancelButtonCustomized = styled(Button)(({ theme }) => {
  return {
    color: "#fff",
    backgroundColor: "#D13C3C",
    textTransform: "none",
    height: "30px",
    borderColor: "#D13C3C",
    "&:hover": {
      backgroundColor: "#D13C3C",
      borderColor: "#D13C3C",
    },
  };
});

const SubmitButtonCustomized = styled(Button)(({ theme }) => {
  return {
    color: "#fff",
    backgroundColor: "#41BA8F",
    borderRadius: 50,
    textTransform: "none",
    fontFamily: "Poppins",
    "&:hover": {
      backgroundColor: "#41BA8F",
    },
  };
});

const TextButtonCustomized = styled(Button)(({ theme, props }) => {
  return {
    color: "#EA7F1D",
    // backgroundColor: "#000",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
    textTransform: "none",
    ".MuiButton-startIcon": {
      margin: "0px !important",
    },
  };
});

export function OutlinedButton(props) {
  const { classes, children, sx, startIcon, endIcon, backgroundColor, color } =
    props;

  return (
    <OutlinedButtonCustomized {...props} variant="outlined">
      <b>{children}</b>
    </OutlinedButtonCustomized>
  );
}

export function BasicButton(props) {
  const { classes, children, sx, startIcon, endIcon, backgroundColor, color } =
    props;

  const BasicButtonCustomized = styled(Button)(({ theme }) => {
    return {
      color: color || "#fff",
      backgroundColor: backgroundColor || "#41BA8F",
      textTransform: "none",
      height: "30px",
      border: "none !important",
      "&:hover": {
        backgroundColor: backgroundColor || "#41BA8F",
      },
      // border: "1px solid #41BA8F",
      "&.Mui-disabled": {
        border: "none !important",
      },
    };
  });

  return (
    <BasicButtonCustomized {...props} variant="outlined">
      <b>{children}</b>
    </BasicButtonCustomized>
  );
}

export function ContainedButton(props) {
  const { classes, children, sx, startIcon, endIcon } = props;
  return (
    <ContainedButtonCustomized {...props} variant="contained">
      <span>{children}</span>
    </ContainedButtonCustomized>
  );
}

export function CancelButton(props) {
  const { classes, children, sx, startIcon, endIcon } = props;
  return (
    <CancelButtonCustomized
      {...props}
      variant="outlined"
      sx={{ ...(sx || {}), padding: "6px 30px" }}
    >
      <b>{children}</b>
    </CancelButtonCustomized>
  );
}

export function SubmitButton(props) {
  const { classes, children, sx, startIcon, endIcon } = props;
  return (
    <SubmitButtonCustomized
      {...props}
      variant="contained"
      sx={{ ...(sx || {}), padding: "7px 40px" }}
    >
      <b>{children}</b>
    </SubmitButtonCustomized>
  );
}

export function TextButton(props) {
  const { classes, children, sx, startIcon, endIcon } = props;
  return (
    <TextButtonCustomized {...props}>
      <b>{children}</b>
    </TextButtonCustomized>
  );
}
