import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  FormLabel,
  Grid,
  LinearProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  BasicMenuItem,
  BasicSelect,
} from "../../ReuseComponents/Select/BasicSelect";
import {
  CancelButton,
  ContainedButton,
} from "../../ReuseComponents/Button/BasicButton";
import Controller from "../../../Controller/ApiController";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { OutlinedTextField } from "../../ReuseComponents/TextField/BasicTextField";
import validationRegExp from "../../../Configuration/ValidationConfig";
import { Alert } from "../../ReuseComponents/Alert/Alertmessage";
import QRIVRBookingPopup from "../../ReuseComponents/Popup/QRIVRBookingPopup";
import UtilDateTime from "../../../Constant/utillDateTime";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import QGLogo from "../../../Assets/qgliderlogo.png";
import InfoIcon from "../../../Assets/icons/infoIcon.svg";

function TimerCircularProgress(props) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: "#D9D9D9",
        }}
        size={90}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={(theme) => ({
          color: "#41BA8F",
          animationDuration: "1000ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
          ...theme.applyStyles("dark", {
            color: "#000",
          }),
        })}
        size={90}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

export default function QRIVRBooking() {
  const initialFormData = {
    patientName: {
      value: "",
      error: false,
    },
    mobileNumber: {
      value: "",
      error: false,
    },
    userUID: {
      value: "",
      error: false,
    },
    doctorName: {
      value: "",
      error: false,
    },
    slotUID: {
      value: "",
      error: false,
    },
    slotTime: {
      value: "",
      error: false,
    },
    slotDate: {
      value: "",
      error: false,
    },
    slot: [],
    slotTimings: "",
    slotCount: "",
  };
  const [loaderOpen, setLoaderOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const { doctor_uid } = useParams();
  const [formData, setFormData] = useState(initialFormData);
  const [popupData, setPopUpData] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [reConfirmation, setReConfirmation] = useState(false);
  const [multiplePatient, setMultiplePatient] = useState(false);
  const [doctorConfirm, setDoctorConfirm] = useState(false);
  const [hospitalName, setHospitalName] = useState("");
  const [timer, setTimer] = useState(0);
  const [startTimer, setStartTimer] = useState(false);
  const [appointmentMessage, setAppointmentMessage] = useState("");
  const [progress, setProgress] = useState(100);
  const handleLoaderOpen = () => {
    setLoaderOpen(true);
  };

  const handleLoaderClose = () => {
    setLoaderOpen(false);
  };

  const initialState = () => {
    setFormData(initialFormData);
    setReConfirmation(false);
    setMultiplePatient(false);
    setDoctorConfirm(false);
    setStartTimer(false);
    setAppointmentMessage("");
    setProgress(100);
    setTimer(0);
  };

  const bookAppointment = async () => {
    try {
      let postData = {
        consultation_type: "NORMAL",
        appointment_date: new Date().toISOString(),
        appointment_type: "SINGLE",
        mobile_number: formData.mobileNumber.value,
        smart_phone: true,
        booking_source: "CLINIC_QR",
        visiting_status: "NEW",
      };

      if (reConfirmation) {
        postData = {
          ...postData,
          confirm: "YES",
        };
        setReConfirmation(false);
      }
      if (formData.slot.length > 0) {
        postData = {
          ...postData,

          patient_name: formData.slot.map((slot) => slot.patientName.value),
          slot_uids: formData.slot.map((slot) => slot.slot_uid),
        };
      }
      console.log(formData, "formDataihformData");
      handleLoaderOpen();
      let res = await Controller.ApiController(
        "POST",
        `/double_appointment/${formData.userUID.value}`,
        "",
        postData
      );
      handleLoaderClose();
      if (res.type == "success") {
        let message = "";
        if (hospitalName) {
          message += `<span style="font-weight: 400;">Your Appointment with <span style="color: #243665;font-weight: 600;">${hospitalName}</span>`;
        }
        if (formData.slotDate.value && formData.slotTimings) {
          message += ` on <span style="color: #243665;font-weight: 600;">${formData.slotDate.value}</span> at <span style="color: #243665;font-weight: 600;">${formData.slotTimings}</span>`;
        }
        if (res?.data?.token) {
          message += ` is confirmed and your token number is <span style="color: #243665;font-weight: 600;">${
            res?.data?.token.join(", ") || "-"
          }</span>. `;
        }
        message += `Your appointment details will be shared through SMS or WhatsApp.\n\n<span style="color: #243665;font-weight: 500;">Powered by QGLIDER</span></span>`;

        setPopUpData({
          open: true,
          type: "BOOKED",
          message: message,
        });
        initialState();
      } else if (res?.error?.status == "ALREADY_BOOKED") {
        setPopUpData({
          open: true,
          type: "ALREADY_BOOKED",
          message: res?.error?.message || "",
        });
      } else {
        handleErrorResponse(res, "Error_MSG");
      }
    } catch (e) {
      handleLoaderClose();
      Alert("error", "An unexpected error occurred. Please try again.");
      console.log("Confirm QR IVR Booking Error: ", e.message);
    }
  };

  const handleConfirmModal = async (data) => {
    try {
      let formValue = formData;
      let formError = false;
      if (!formValue?.userUID?.value) {
        formValue.userUID.error = true;
        formError = true;
      }

      if (doctorConfirm) {
        console.log(formValue, "formValueformValue1");
        if (formValue.slot.some((slot) => !slot.patientName.value)) {
          formValue.slot = formValue.slot.map((slot) => ({
            ...slot,
            patientName: {
              ...slot.patientName,
              error: !slot.patientName.value,
            },
          }));
          formError = true;
        }
        if (!formValue?.mobileNumber?.value) {
          formValue.mobileNumber.error = true;
          formError = true;
        }
      }
      let parseSlotCount = "";
      if (multiplePatient) {
        if (data == "ONE" || data == "TWO") {
          parseSlotCount = data == "ONE" ? "One" : "Two";
          if (data == "TWO") {
            setFormData((prevData) => ({
              ...prevData,
              slotCount: parseSlotCount,
            }));

            setPopUpData({
              open: true,
              type: "CONFIRMATION",
              message: `Are you sure you want to \nbook “${parseSlotCount}” Appointment?`,
            });
            return;
          }
        } else if (
          formValue.slotCount != "One" &&
          formValue.slotCount != "Two"
        ) {
          return;
        }
      }

      if (formError) {
        setFormData({ ...formValue });
        return;
      }

      if (multiplePatient) {
        parseSlotCount =
          formData.slotCount == "One" ? 1 : formData.slotCount == "Two" ? 2 : parseSlotCount == "One" ? 1 : 0;
        handleDoctor("NO_OF_SLOTS", parseSlotCount);
        setPopUpData({
          open: false,
          type: "",
          message: "",
        });
        setMultiplePatient(false);
      } else {
        bookAppointment();
      }
    } catch (e) {
      handleLoaderClose();
      Alert("error", "An unexpected error occurred. Please try again.");
      console.log("Confirm QR IVR Booking Error: ", e.message);
    }
  };

  const handleNext = () => {
    if (formData.userUID.value) {
      if (!doctorConfirm) {
        setDoctorConfirm(true);
        return;
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        userUID: {
          ...formData.userUID,
          error: true,
        },
      }));
    }
  };

  const handleGoBack = () => {
    if (doctorConfirm) {
      setDoctorConfirm(false);
      setReConfirmation(false);
      setFormData((prevData) => ({
        ...prevData,
        patientName: {
          value: "",
          error: false,
        },
        mobileNumber: {
          value: "",
          error: false,
        },
        slotUID: {
          value: "",
          error: false,
        },
      }));
    }
  };

  const handleCloseModal = () => {
    if (popupData.type == "CONFIRMATION") {
      setFormData(initialFormData);
    }
    setPopUpData({
      open: false,
      type: "",
      message: "",
    });
    setReConfirmation(false);
    setMultiplePatient(false);
  };

  const handleChange = (e) => {
    const { name, value, id } = e.target;

    if (!validationRegExp.noEmptySpaceStart(e.target.value)) {
      return;
    }

    if (id && !validationRegExp[id](value)) {
      return;
    }
    let formValueUpdate = {};
    if (name == "userUID") {
      let findUser = users.find((val) => val.user_uid == value);
      formValueUpdate = {
        doctorName: { value: findUser?.full_name || "", error: false },
      };
    }

    setFormData((val) => ({
      ...val,
      ...formValueUpdate,
      [name]: { value: value, error: false },
    }));
  };

  const handlePatientChange = (e, index) => {
    const { name, value, id } = e.target;

    if (!validationRegExp.noEmptySpaceStart(e.target.value)) {
      return;
    }

    if (id && !validationRegExp[id](value)) {
      return;
    }
    let formValueUpdate = formData.slot;

    formValueUpdate[index] = {
      ...formValueUpdate[index],
      patientName: { value: value, error: false },
    };
    setFormData((val) => ({
      ...val,
      slot: formValueUpdate,
    }));
  };

  const handleErrorResponse = (res, type) => {
    if (res?.error?.status && res?.error?.status == "MESSAGE") {
      setPopUpData({
        open: true,
        type: type,
        message: res?.error?.message || "",
      });
    } else if (res?.error?.status && res?.error?.status == "RE_CONFIRM") {
      setPopUpData({
        open: true,
        type: "RE_CONFIRMATION",
        message: res?.error?.message || "",
      });
      setReConfirmation(true);
    } else if (res?.error?.status && res?.error?.status == "NO_OF_SLOTS") {
      setPopUpData({
        open: true,
        type: "NO_OF_SLOTS",
        message: "How many appointments \ndo you need to schedule?",
      });
      setMultiplePatient(true);
    } else {
      Alert(
        "error",
        res?.error?.message || "An unexpected error occurred. Please try again."
      );
    }
  };

  const parseScanDuration = (value) => {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    let parseValue = "";

    if (formattedHours) {
      parseValue += formattedHours + " : ";
    }

    if (formattedMinutes) {
      parseValue += formattedMinutes;
    }
    return parseValue;
  };

  useEffect(() => {
    let intervalId;

    if (startTimer && timer > 0) {
      intervalId = setInterval(() => {
        const addValue = 100 / 120;
        setProgress((prevProgress) =>
          prevProgress >= 120 ? 0 : prevProgress - addValue
        );
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timer <= 0) {
      clearInterval(intervalId);
      initialState();
    }

    return () => clearInterval(intervalId);
  }, [startTimer, timer]);

  const fetchSlot = async () => {
    try {
      handleLoaderOpen();
      let res = await Controller.ApiController(
        "GET",
        `/clinic_qr/${doctor_uid}`,
        "",
        ""
      );

      handleLoaderClose();
      if (res.type == "success") {
        if (res.admin) {
          setHospitalName(res.admin.hospital_name);
        }
        if (Array.isArray(res.data) && res.data.length > 0) {
          const parseUser = res.data.map((val) => ({
            user_uid: val.user_uid,
            full_name: val.full_name,
          }));
          setUsers(parseUser);

          if (parseUser.length == 1) {
            setFormData((prevData) => ({
              ...prevData,
              userUID: {
                value: parseUser[0]?.user_uid || "",
                error: false,
              },
              doctorName: {
                value: parseUser[0]?.full_name || "",
                error: false,
              },
            }));
          }
        }
      } else {
        handleErrorResponse(res, "");
      }
    } catch (e) {
      console.log("Fetch Slot QR IVR Error: ", e.message);
      handleLoaderClose();
      Alert("error", "An unexpected error occurred. Please try again.");
    }
  };

  useEffect(() => {
    fetchSlot();
  }, []);

  useEffect(() => {
    if (formData.userUID.value) {
      handleDoctor("", "");
    }
  }, [formData.userUID.value]);

  const handleDoctor = async (type, data) => {
    try {
      let postData = {
        user_uid: formData?.userUID?.value || "",
      };

      if (formData.slot.length > 0) {
        postData = {
          ...postData,
          previous_slot_uid: formData.slot.map((slot) => slot.slot_uid),
        };
      }

      if (type == "NO_OF_SLOTS") {
        postData = {
          ...postData,
          slot_count: data,
        };
      }

      handleLoaderOpen();
      let res = await Controller.ApiController(
        "POST",
        "/clinic_qr/slot",
        "",
        postData
      );

      handleLoaderClose();
      if (res.type == "success") {
        if (res.status == "SLOT") {
          let slotTimings = "";
          let slotDate = "";
          let updateSlot = [];
          if (Array.isArray(res.slot) && res.slot.length > 0) {
            updateSlot = res.slot?.map((slot, index) => {
              if (slotTimings && res.slot.length - 1 == index) {
                slotTimings += " and ";
              } else if (slotTimings) {
                slotTimings += ", ";
              }
              slotTimings += UtilDateTime.formatTimeAsHHMMTT(
                slot?.slot_start_time || ""
              );
              slotDate = UtilDateTime.formatYearMonthAndDayAsIntInSlash(
                slot?.slot_start_time || ""
              );

              return {
                ...slot,
                parsed_slot_start_time: UtilDateTime.formatTimeAsHHMMTT(
                  slot?.slot_start_time || ""
                ),
                parsed_slot_data:
                  UtilDateTime.formatYearMonthAndDayAsIntInSlash(
                    slot?.slot_start_time || ""
                  ),
                patientName: { value: "", error: false },
              };
            });
          }
          let modalMessage =
            "An Appointment available at HH:MM. \nPlease click Next to Confirm";
          const message = modalMessage?.replace("HH:MM", slotTimings) || "";
          if (message) {
            setAppointmentMessage(message);

            setFormData((val) => ({
              ...val,
              slot: updateSlot,
              slotTimings: slotTimings,
              slotDate: {
                value: slotDate,
                error: false,
              },
            }));
          }
          setTimer(120);
          setStartTimer(true);
          setProgress(100);
        }
      } else {
        if (res.error.status != "NO_OF_SLOTS") {
          initialState();
        }
        handleErrorResponse(res, "Error_MSG");
      }
    } catch (e) {
      handleLoaderClose();
      Alert("error", "An unexpected error occurred. Please try again.");
      console.log("Fetch Slot QR IVR Error: ", e.message);
    }
  };

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box sx={{ width: "100%" }} display={"flex"} justifyContent={"center"}>
      {/* <Backdrop sx={{ color: "#fff", zIndex: 0 }} open invisible={!isMd}> */}
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: { xs: "20px", sm: "30px" },
          borderRadius: "10px",
          textAlign: "center",
          width: { xs: "320px", sm: "400px" },
        }}
      >
        <Box
          component={"img"}
          src={QGLogo}
          sx={{
            width: { xs: "159px", md: "256px" },
            height: { xs: "69px", md: "91px" },
            mb: 4,
          }}
        />
        <Typography
          fontSize={"25px"}
          mb={3}
          fontFamily={"Poppins"}
          color={"#000"}
          fontWeight={700}
        >
          Welcome to {hospitalName}
        </Typography>
        {!doctorConfirm && (
          <Typography
            fontSize={"20px"}
            mb={3}
            fontFamily={"Poppins"}
            color={"#243665"}
            fontWeight={700}
          >
            Book your appointment here
          </Typography>
        )}
        <Box
          component={"form"}
          onSubmit={(e) => {
            e.preventDefault();
            handleConfirmModal();
          }}
        >
          {doctorConfirm ? (
            <Grid container>
              <Grid item xs={12} sx={{ textAlign: "center" }} mb={2}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                >
                  Your Appointment with
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    color: "#243665",
                  }}
                >
                  Dr. {formData.doctorName.value}&nbsp;
                  <span style={{ color: "#000" }}>at</span>&nbsp;
                  {formData.slotTimings}
                </Typography>
              </Grid>
              <Grid item xs={12} mb={1}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "11px",
                  }}
                >
                  Enter patient name and mobile number to confirm
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container mb={2} alignItems="center">
              <Grid item xs={4.5} md={5.5} sx={{ textAlign: "left" }}>
                <FormLabel
                  htmlFor="availableSlot"
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    whiteSpace: "nowrap",
                    color: "#000000",
                    fontFamily: "Poppins",
                  }}
                >
                  {isMd ? "Select the Doctor Name" : "Select Doctor"}
                </FormLabel>
              </Grid>
              <Grid item xs={7.5} md={6.5}>
                {doctorConfirm ? (
                  <Typography sx={{ textAlign: "left", fontSize: "14px" }}>
                    : {formData.doctorName.value}
                  </Typography>
                ) : (
                  <BasicSelect
                    name="userUID"
                    value={formData?.userUID?.value || "default"}
                    error={formData?.userUID?.error}
                    onChange={handleChange}
                    sx={{ textAlign: "left", fontFamily: "Poppins" }}
                  >
                    <BasicMenuItem value="default" style={{ display: "none" }}>
                      <span style={{ color: "#bbb7b7" }}>Select</span>
                    </BasicMenuItem>
                    {Array.isArray(users) && users.length > 0 ? (
                      users.map((val, i) => (
                        <BasicMenuItem key={i} value={val.user_uid}>
                          {val.full_name}
                        </BasicMenuItem>
                      ))
                    ) : (
                      <BasicMenuItem disabled>
                        No Doctors Available
                      </BasicMenuItem>
                    )}
                  </BasicSelect>
                )}
              </Grid>
              {appointmentMessage && (
                <Grid item xs={12} mt={4}>
                  <Typography
                    fontSize={"15px"}
                    mb={3}
                    fontFamily={"Poppins"}
                    color={"#243665"}
                    fontWeight={700}
                  >
                    {appointmentMessage}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
          {doctorConfirm && (
            <>
              {formData.slot?.map((slot, slotIndex) => (
                <Grid container mb={2} alignItems="center">
                  <Grid item xs={4.5} md={5.5} sx={{ textAlign: "left" }}>
                    <FormLabel
                      htmlFor="patientName"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#000000",
                        whiteSpace: "nowrap",
                        fontFamily: "Poppins",
                      }}
                    >
                      {isMd && "Enter the "}Patient Name {slotIndex + 1}
                    </FormLabel>
                  </Grid>
                  <Grid item xs={7.5} md={6.5}>
                    <OutlinedTextField
                      id="name"
                      className="patientname"
                      placeholder="Enter Here"
                      name={"patientName" + slotIndex}
                      variant="outlined"
                      size="small"
                      error={slot?.patientName?.error}
                      value={slot?.patientName?.value || ""}
                      onChange={(e) => handlePatientChange(e, slotIndex)}
                      sx={{ fontFamily: "Poppins" }}
                    />
                  </Grid>
                </Grid>
              ))}
              <Grid container mb={2} alignItems="center">
                <Grid item xs={4.5} md={5.5} sx={{ textAlign: "left" }}>
                  <FormLabel
                    htmlFor="patientName"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#000000",
                      whiteSpace: "nowrap",
                      fontFamily: "Poppins",
                    }}
                  >
                    {isMd && "Enter the "}Mobile Number
                  </FormLabel>
                </Grid>
                <Grid item xs={7.5} md={6.5}>
                  <OutlinedTextField
                    id="mobileNumber"
                    className="patientname"
                    placeholder="Enter Here"
                    name="mobileNumber"
                    variant="outlined"
                    size="small"
                    error={formData?.mobileNumber?.error}
                    value={formData?.mobileNumber?.value || ""}
                    onChange={handleChange}
                    sx={{ fontFamily: "Poppins" }}
                  />
                </Grid>
              </Grid>
            </>
          )}

          {startTimer && (
            <Box
              sx={{
                width: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Stack spacing={2} direction="row">
                <TimerCircularProgress variant="determinate" value={progress} />
                <Box sx={{ position: "absolute", top: 32 }}>
                  <Typography
                    fontSize={"18px"}
                    mb={3}
                    fontFamily={"Poppins"}
                    color={"#41BA8F"}
                    fontWeight={300}
                  >
                    {parseScanDuration(timer)}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          )}
          {startTimer && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <Typography
                sx={{
                  textAlign: "left",
                  width: "320px",
                  fontSize: "11px",
                  border: "1px solid #E1E1E1",
                  borderRadius: "5px",
                  padding: "5px 5px",
                  fontFamily: "Poppins",
                  color: "#243665",
                  fontWeight: "500",
                }}
              >
                <img
                  src={InfoIcon}
                  alt="infoicon"
                  style={{
                    height: "15px",
                    width: "15px",
                    position: "absolute",
                    marginTop: 1,
                  }}
                />
                &emsp;&ensp;Please confirm your appointment within the next
                02:00 minutes. If not, the slot will be released for others.
              </Typography>
            </Box>
          )}
          <Box mt={3}>
            {doctorConfirm ? (
              <Box
                sx={{ display: "flex", justifyContent: "center", width: "100%" }}
              >
                <CancelButton
                  onClick={handleGoBack}
                  sx={{
                    mr: 2,
                    width: "154px",
                    height: "40px",
                    fontFamily: "Poppins",
                  }}
                >
                  Go Back
                </CancelButton>
                <ContainedButton
                  type={"submit"}
                  sx={{
                    height: "40px",
                    fontSize: "15px",
                    width: "154px",
                    height: "40px",
                    lineHeight: 1,
                    fontFamily: "Poppins",
                  }}
                >
                  Book an Appointment
                </ContainedButton>
              </Box>
            ) : (
              <ContainedButton
                onClick={handleNext}
                sx={{
                  height: "40px",
                  width: "154px",
                  fontSize: "15px",
                  fontFamily: "Poppins",
                }}
              >
                Next
              </ContainedButton>
            )}
          </Box>
        </Box>
      </Box>
      {/* </Backdrop> */}
      {popupData.open && (
        <QRIVRBookingPopup
          Data={popupData}
          confirmModal={handleConfirmModal}
          cancelModal={handleCloseModal}
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 100000 }}
        open={loaderOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
