import React, { useEffect, useState } from "react";
import Header from "../../ReuseComponents/Header/Header";
import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  FormLabel,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box, textAlign } from "@mui/system";
import ScanImage from "../../../Assets/images/QrScan/qrscanimg.svg";
import { useLocation, useParams } from "react-router-dom";
import Controller from "../../../Controller/ApiController";
import ScanImageVerify from "../../../Assets/images/QrScan/qrscanimg.svg";
import InqueImg from "../../../Assets/images/QrScan/inqueImage.svg";
import InvalidImg from "../../../Assets/images/QrScan/invalidImg.svg";
import MissedImg from "../../../Assets/images/QrScan/missedImg.svg";
import CancelledImg from "../../../Assets/images/QrScan/cancelImg.svg";
import OnDateImg from "../../../Assets/images/QrScan/ondateImg.svg";
import { Alert } from "../../ReuseComponents/Alert/Alertmessage";
import LogoImae from "../../../Assets/images/QrScan/logoscan.svg";
import NoAppointmentsImg from "../../../Assets/images/QrScan/noappointments.svg";
import CancelImg from "../../../Assets/images/QrScan/cancel.svg";
import UtilDateTime from "../../../Constant/utillDateTime";
import validationRegExp from "../../../Configuration/ValidationConfig";
import ThankYouImg from "../../../Assets/images/QrScan/thankyouImg.svg";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import dayjs from "dayjs";
import {
  BasicMenuItem,
  BasicSelect,
} from "../../ReuseComponents/Select/BasicSelect";
import {
  BasicButton,
  OutlinedButton,
} from "../../ReuseComponents/Button/BasicButton";

export default function PatientScanQr() {
  const { doctor_uid } = useParams();
  const [userdata, setUserdata] = useState();
  const [enterMobileNum, setEnterMobileNum] = useState(true);

  const [verifyPage, setVerifyPage] = useState(false);
  const [inQueData, setInQueData] = useState(false);
  const [invalidStatus, setInvalidStatus] = useState(false);
  const [missedStatus, setMissedStatus] = useState(false);
  const [cancelledStatus, setCancelledStatus] = useState(false);
  const [onDateStatus, setOnDateStatus] = useState(false);
  const [onDateShowMsg, setOnDateShowMsg] = useState("");
  const [onTimeShow, setOnTimeShow] = useState("");
  const [newNumber, setNewNumber] = useState(false);
  const [newNumberTwo, setNewNumberTwo] = useState(false);
  const [multiplePatient, setMultiplePatient] = useState(false);
  const [patientCount, setPatientCount] = useState(0);
  const [getPatientCount, setGetPatientCount] = useState(false);
  const [noAppointments, setNoAppointments] = useState(false);
  const [yourName, setYourName] = useState(false);
  const [successStatus, setSuccessStatus] = useState(false);
  const [cancelStatus, setCancelStatus] = useState(false);
  const [usingMobileSlot, setUsingMobileSlot] = useState(false);
  const [filterDataList, setFilterDataList] = useState({});
  const [patientNameError, setPatientNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [thankYouPage, setThankYouPage] = useState(false);
  const [alreadyInque, setAlreadyInque] = useState(false);
  const [errorAccord, setErrorAccord] = useState(false);
  const [checkInStatus, setCheckInStatus] = useState(false);
  const [alreadyAvailMessage, setAlreadyAvailMessage] = useState("");
  const [checkOutStatus, setCheckOutStatus] = useState(false);
  const [ivrAppoinment, setIvrAppoinment] = useState(false);
  const [ivrAppoinmentUid, setIvrAppoinmentUid] = useState([]);
  const [formDataIvr, setFormDataIvr] = useState([]);
  const [ivrPatientNameError, setIvrPatientNameError] = useState("");
  const [userType, setUserType] = useState("");
  const [formDataNew, setFormDataNew] = useState({
    consultation_type: "NORMAL",
    appointment_date: new Date().toISOString(),
    appointment_type: "SINGLE",
    slot_uid_one: "",
    slot_uid_two: "",
    slot_duration: "",
    patient_name_one: "",
    patient_name_two: "",
    mobile_number: "",
    smart_phone: true,
    booking_source: "QR_WEB",
    visiting_status: "NEW",
    user_uid: doctor_uid,
  });
  const [loaderopen, setloaderOpen] = React.useState(false);
  const [apiResData, setApiResData] = useState(true);
  const [slots, setSlots] = useState("");
  const [lineProgressLoading, setLineProgressLoading] = useState(false);
  const [getSlotDuration, setGetSlotDuration] = useState(false);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };

  const fetchData = async () => {
    let queryParams = { "filter.doctor_uid": doctor_uid };
    let response = await Controller.ApiController(
      "GET",
      "/manage_users/" + doctor_uid,
      "",
      ""
    );
    if (response.type == "success") {
      let doctor = response.data.doctor;
      localStorage.setItem("DoctorUid", doctor.doctor_uid);
      localStorage.setItem("user_type", response.data.user_type);
      setMultiplePatient(!!doctor?.multiple_appoinment_booking_qr);
      setUserdata(doctor);
      setUserType(response.data.user_type);
      //  setEnterMobileNum(true)
    } else {
      console.log(response.error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    let duration = currentUrl.searchParams.get("duration");
    if (duration) {
      setFormDataNew((prevData) => ({
        ...prevData,
        slot_duration: duration,
      }));
    } else {
      setGetSlotDuration(true);
    }
  }, []);

  const getSlotTime = async () => {
    let reqParams = {
      "filter.current_date":
        dayjs(new Date()).startOf("day").toISOString() || "",
    };
    try {
      setLineProgressLoading(true);
      let res = await Controller.ApiController(
        "GET",
        `/get_slot/${doctor_uid}`,
        "?",
        reqParams
      );
      setLineProgressLoading(false);

      if (res && res.data && Array.isArray(res.data)) {
        let slotTimeListdata = [];

        slotTimeListdata = res?.data;
        setSlots(slotTimeListdata || []);
      } else if (res.message) {
        setLineProgressLoading(false);
        setSlots([]);
      }
    } catch (error) {
      // handleLoaderClose();
      setLineProgressLoading(false);
    }
  };

  const validateFields = () => {
    let patientNameValidate = false;
    let mobileValidate = false;
    let ivrPatientNameValidate = false;

    if (formDataNew.patient_name) {
      // setEnterMobileNum(false)
      if (formDataNew.patient_name_one == "") {
        setPatientNameError(" ");
      } else if (formDataNew.patient_name_one) {
        let validInput = validationRegExp.fullName(
          formDataNew.patient_name_one
        );
        if (!validInput) {
          setPatientNameError("Invalid patient name");
        } else {
          setPatientNameError("");
          patientNameValidate = true;
        }
      }
    }

    // if (formDataIvr.patient_name) {
    //   // setEnterMobileNum(false)
    //   if (formDataIvr.patient_name == "") {
    //     setIvrPatientNameError("");
    //   } else if (formDataIvr.patient_name) {
    //     let validInput = validationRegExp.fullName(formDataIvr.patient_name);
    //     if (!validInput) {
    //       setIvrPatientNameError("Invalid patient name");
    //     } else {
    //       setIvrPatientNameError("");
    //       ivrPatientNameValidate = true;
    //     }
    //   }
    // }

    if (formDataNew.mobile_number) {
      // setYourName(false)
      if (formDataNew.mobile_number == "") {
        setMobileError(" ");
      } else if (formDataNew.mobile_number) {
        let validInputMob = validationRegExp.mobileNumberReg.test(
          formDataNew.mobile_number
        );
        if (validInputMob) {
          let mobile = "";
          let firstIndex =
            formDataNew.mobile_number[0] == undefined
              ? ""
              : formDataNew.mobile_number[0];
          var repeat = true;
          for (var i = 0; i < formDataNew.mobile_number.length; i++) {
            if (firstIndex != formDataNew.mobile_number[i]) {
              repeat = false;
            }
          }
          for (let i = 0; i < formDataNew.mobile_number.length; i++) {
            mobile +=
              formDataNew.mobile_number[i] != " "
                ? formDataNew.mobile_number[i]
                : "";
          }
          if (mobile.length > 9 || mobile == "") {
            if (repeat && mobile != "") {
              setMobileError("Invalid mobile number");
            } else {
              setMobileError("");
              mobileValidate = true;
            }
          } else {
            setMobileError("Mobile number must be 10 digit");
          }
        }
      }
    }

    return patientNameValidate || mobileValidate || ivrPatientNameValidate;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitter = event.nativeEvent.submitter;
    let updatePatientCount = 0;
    if (submitter.id == "patientCount") {
      if (submitter.name == "patientCount1") {
        updatePatientCount = 1;
      } else if (submitter.name == "patientCount2") {
        updatePatientCount = 2;
      }
      setPatientCount(updatePatientCount);
    }
    const validation = validateFields();
    if (enterMobileNum || yourName) {
      if (!validation) {
        return;
      }
    }

    handleLoaderOpen();
    if (formDataNew.mobile_number != "") {
      if (enterMobileNum || getPatientCount) {
        let datas = { ...formDataNew };

        let verifyMobileBody = { user_uid: doctor_uid || "" };
        if (getPatientCount) {
          verifyMobileBody = {
            ...verifyMobileBody,
            slot_count: updatePatientCount,
          };
        }

        // let res = { status: "NO", type: "success"  }
        let res = await Controller.ApiController(
          "PUT",
          `/qr_code/verifymobile/${datas.mobile_number}`,
          "",
          verifyMobileBody
        );
        if (res) {
          if (res.type === "success") {
            handleLoaderClose();
            setVerifyPage(false);
            setEnterMobileNum(false);
            if (res.status == "INQUE") {
              setInQueData(true);
            } else if (res.status == "INVALID") {
              setInvalidStatus(true);
            } else if (res.status == "MISSED") {
              setMissedStatus(true);
            } else if (res.status == "CANCELLED") {
              setCancelledStatus(true);
            } else if (res.status == "ONDATE") {
              setOnDateStatus(true);
              setOnDateShowMsg(
                UtilDateTime.formatYearMonthAndDayAsInt(res?.slot_start_time)
              );
              setOnTimeShow(
                UtilDateTime.formatTimeAsHHMMTT(res?.slot_start_time)
              );
            } else if (res.status == "NEW_NUMBER") {
              let slotStartTimeOne =
                UtilDateTime.formatTimeAsHHMMTT(res?.slot_start_time_one) || "";
              let slotUIDOne = "";
              let slotUIDTwo = "";
              if (userType == "DOCTOR") {
                slotUIDOne = res?.slot_uid_one;
              }
              if (getPatientCount) {
                setGetPatientCount(false);
                if (
                  !!res?.slot_start_time_two &&
                  !!UtilDateTime.formatTimeAsHHMMTT(res?.slot_start_time_two)
                ) {
                  setNewNumberTwo(
                    UtilDateTime.formatTimeAsHHMMTT(res?.slot_start_time_two)
                  );
                }
                if (!!res?.slot_uid_two) {
                  slotUIDTwo = res?.slot_uid_two;
                }
              }
              setNewNumber(slotStartTimeOne);
              setFormDataNew({
                ...formDataNew,
                slot_uid_one: slotUIDOne,
                slot_uid_two: slotUIDTwo,
              });
            } else if (res.status == "NO_OF_SLOTS") {
              setGetPatientCount(true);
            } else if (res.status == "NO_APPOINTMENT") {
              setNoAppointments(true);
            } else if (res.status == "USING_MOBILE_SLOT") {
              setUsingMobileSlot(true);
              const patientDataList = [];
              const data = res.data;
              data.map((value) => {
                const slotData = value;
                const patientName = slotData.patient_name;
                const slotStartTime = slotData.from_time;
                patientDataList.push({
                  patientName: patientName,
                  slotStartTime: slotStartTime,
                });
              });
              setFilterDataList({
                appointment_date: res.appointment_date || "",
                data: patientDataList,
              });
            } else if (res.status == "IVR") {
              setIvrAppoinment(true);
              setIvrAppoinmentUid(res.appointment_uid);
              setFormDataIvr(
                res.appointment_uid?.map((val) => ({
                  appointment_uid: val,
                  patient_name: "",
                }))
              );
            } else if (
              res.status == "ALREADY" &&
              res.current_status == "IN_QUE"
            ) {
              setAlreadyInque(true);
            } else if (
              res.status == "ALREADY" &&
              res.current_status == "CHECK_IN"
            ) {
              setCheckInStatus(true);
            } else if (
              res.status == "ALREADY" &&
              res.current_status == "CHECK_OUT"
            ) {
              setCheckOutStatus(true);
            } else {
              setErrorAccord(true);
            }
          } else {
            handleLoaderClose();
            Alert("error", res.error.message);
          }
        }
      } else if (
        (patientCount < 2 && yourName && formDataNew.patient_name_one != "") ||
        (patientCount == 2 &&
          yourName &&
          formDataNew.patient_name_one != "" &&
          formDataNew.patient_name_two != "")
      ) {
        let data = { ...formDataNew };
        let res;
        if (userType == "DOCTOR") {
          if (!!data?.patient_name_two && !!data?.slot_uid_two) {
            data = {
              ...data,
              patient_name: [data.patient_name_one, data.patient_name_two],
              slot_uids: [data.slot_uid_one, data.slot_uid_two],
            };
          } else {
            data = {
              ...data,
              patient_name: [data.patient_name_one],
              slot_uids: [data.slot_uid_one],
            };
          }
          res = await Controller.ApiController(
            "POST",
            `/double_appointment/${data.user_uid}`,
            "",
            data
          );
        } else {
          data = { ...data, patient_name: data.patient_name_one, slot_uid: "" };
          res = await Controller.ApiController(
            "POST",
            "/appointment",
            "",
            data
          );
        }

        if (res) {
          if (res.type === "success") {
            handleLoaderClose();
            setSuccessStatus("Your appointment is confirmed.");
            setYourName(false);
          } else {
            handleLoaderClose();
            // Alert("error", res.error.message);
            setCancelStatus("Please contact the helpdesk.");
            setYourName(false);
          }
          handleLoaderClose();
        }
      } else if (
        ivrAppoinment &&
        !formDataIvr.some((val) => val.patient_name == "")
      ) {
        handleLoaderOpen();
        // let data = { ...formDataIvr };
        let resData = [];
        let successCount = 0;
        for (const postData of formDataIvr) {
          let res = await Controller.ApiController(
            "PUT",
            `/appointment/${postData?.appointment_uid}`,
            "",
            { patient_name: postData.patient_name }
          );
          if (!(res.type === "success")) {
            setApiResData(false);
          } else {
            successCount++;
          }
        }
        handleLoaderOpen();
        if (successCount == formDataIvr.length) {
          handleLoaderOpen();
          await ivrAppoinmentPatientName();
          setVerifyPage(false);
          setEnterMobileNum(false);
          setIvrAppoinment(false);
        } else {
          setCancelStatus("Please contact the helpdesk.");
          setIvrAppoinment(false);
        }

        handleLoaderClose();
      }
    }
  };

  const handleCountClick = async (e) => {
    handleSubmit(e);
  };

  const ivrAppoinmentPatientName = async () => {
    handleLoaderOpen();
    let datas = { ...formDataNew };
    let res = await Controller.ApiController(
      "PUT",
      `/qr_code/verifymobile/${datas.mobile_number}`,
      "",
      { user_uid: doctor_uid || "" }
    );
    if (res.type === "success") {
      handleLoaderClose();
      setVerifyPage(false);
      setEnterMobileNum(false);
      if (res.status == "INQUE") {
        setInQueData(true);
      } else if (res.status == "INVALID") {
        setInvalidStatus(true);
      } else if (res.status == "MISSED") {
        setMissedStatus(true);
      } else if (res.status == "CANCELLED") {
        setCancelledStatus(true);
      } else if (res.status == "ONDATE") {
        setOnDateStatus(true);
        setOnDateShowMsg(
          UtilDateTime.formatYearMonthAndDayAsInt(res?.slot_start_time)
        );
        setOnTimeShow(UtilDateTime.formatTimeAsHHMMTT(res?.slot_start_time));
      } else if (res.status == "NEW_NUMBER") {
        setNewNumber(UtilDateTime.formatTimeAsHHMMTT(res?.slot_start_time));
        if (userType == "DOCTOR") {
          setFormDataNew({ ...formDataNew, slot_uid_one: res?.slot_uid_one });
        }
      } else if (res.status == "NO_APPOINTMENT") {
        setNoAppointments(true);
      } else if (res.status == "USING_MOBILE_SLOT") {
        setUsingMobileSlot(true);
        const patientDataList = [];
        const data = res.data;
        data.map((value) => {
          const slotData = value;
          const patientName = slotData.patient_name;
          const slotStartTime = slotData.from_time;
          patientDataList.push({
            patientName: patientName,
            slotStartTime: slotStartTime,
          });
        });
        setFilterDataList({
          appointment_date: res.appointment_date,
          data: patientDataList,
        });
      } else if (res.status == "IVR") {
        setIvrAppoinment(true);
        setIvrAppoinmentUid(res.appointment_uid);
      } else if (res.status == "ALREADY" && res.current_status == "IN_QUE") {
        setAlreadyInque(true);
      } else if (res.status == "ALREADY" && res.current_status == "CHECK_IN") {
        setCheckInStatus(true);
      } else if (res.status == "ALREADY" && res.current_status == "CHECK_OUT") {
        setCheckOutStatus(true);
      } else {
        setErrorAccord(true);
      }
    } else {
      handleLoaderClose();
      Alert("error", res.error.message);
    }
  };

  const navigatNamePage = () => {
    if (newNumber) {
      if (multiplePatient && !getPatientCount) {
        setGetPatientCount(true);
      } else {
        setYourName(true);
        setNewNumber(false);
        setNewNumberTwo(false);
      }
    }
  };

  // const mobileOnChange = (e) => {
  //   setFormData({
  //     ...formData,mobile_number:e.target.value,
  //   });
  // }

  const patientNameOnChange = (e, name) => {
    if (validationRegExp.fullName(e.target.value)) {
      setFormDataNew({
        ...formDataNew,
        [name]: e.target.value,
      });
      if (patientNameError) {
        setPatientNameError("");
      }
    }
  };

  const ivrPatientNameOnChange = (e, index) => {
    if (validationRegExp.fullName(e.target.value)) {
      setFormDataIvr(
        formDataIvr.map((prevData, prevIndex) => {
          if (index == prevIndex) {
            return {
              ...prevData,
              patient_name: e.target.value,
            };
          } else {
            return prevData;
          }
        })
      );
      // if (ivrPatientNameError) {
      //   setIvrPatientNameError("");
      // }
    }
  };

  const mobileNumberOnChange = (e) => {
    if (validationRegExp.mobileNumberReg.test(e.target.value)) {
      setFormDataNew({
        ...formDataNew,
        mobile_number: e.target.value,
      });
      if (mobileError) {
        setMobileError("");
      }
    }
  };

  const closeTheCurrentPage = () => {
    setEnterMobileNum(true);
    setFormDataNew({
      ...formDataNew,
      mobile_number: "",
      patient_name_one: "",
      patient_name_two: "",
    });
    setFormDataIvr([]);
    setSuccessStatus(false);
    setMissedStatus(false);
    setInvalidStatus(false);
    setCancelledStatus(false);
    setInQueData(false);
    setOnDateStatus(false);
    setNoAppointments(false);
    setUsingMobileSlot(false);
    setThankYouPage(false);
    setCancelStatus(false);
    setAlreadyInque(false);
    setCheckInStatus(false);
    setCheckOutStatus(false);
    setErrorAccord(false);
    setIvrAppoinment(false);
  };

  const closeTheCancelButton = () => {
    setEnterMobileNum(true);
    setFormDataNew({
      ...formDataNew,
      mobile_number: "",
    });
    setFormDataIvr([]);
    setNewNumber(false);
    setNewNumberTwo(false);
    setYourName(false);
    setIvrAppoinment(false);
    setVerifyPage(false);
  };

  const closeTheNoButton = () => {
    setThankYouPage(true);
    setFormDataNew({
      ...formDataNew,
      mobile_number: "",
    });
    setFormDataIvr([]);
    setNewNumber(false);
    setNewNumberTwo(false);
    setYourName(false);
    setIvrAppoinment(false);
    setVerifyPage(false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} mt={3}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img src={LogoImae} alt="logoicon" />
          </Box>
        </Grid>
        <Grid item xs={12} mt={8}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Card
              sx={{
                width: { xs: "95%", sm: "80%", md: "600px" },
                paddingBottom: "20px",
                //height: verifyPage ? "37vh" : onDateStatus ? "37vh" : inQueData ? "37vh" :invalidStatus ? "37vh" :missedStatus ? "40vh" :cancelledStatus ? "37vh" :noAppointments ? "40vh" : successStatus ? "33vh" :cancelStatus ? "33vh" : usingMobileSlot ? "60vh"  :thankYouPage ? "37vh" :enterMobileNum ? {xs:"55vh",sm:"55vh",md:"50vh"} :yourName ? "50vh" : ivrAppoinment ? "50vh" : newNumber ? "37vh" : alreadyInque ? "33vh" : errorAccord ? "33vh" : checkInStatus ? "33vh" : checkOutStatus ? "33vh" : "45vh",
                borderRadius: "10px 10px 10px 10px",
              }}
            >
              <Box
                sx={{
                  height: "60px",
                  backgroundColor: inQueData
                    ? "#FFD8B4"
                    : invalidStatus
                    ? "#FFD7D7"
                    : missedStatus
                    ? "#FFD7D7"
                    : cancelledStatus
                    ? "#FFD7D7"
                    : noAppointments
                    ? "#FFD7D7"
                    : thankYouPage
                    ? "#FFD7D7"
                    : errorAccord
                    ? "#FFD7D7"
                    : "#C2FFEA",
                  borderRadius: "10px 10px 0px 0px",
                }}
              >
                <IconButton
                  sx={{
                    position: "relative",
                    top: "30px",
                    left: { xs: "50%", sm: "50%", md: "50%", lg: "50%" },
                    transform: "translateX(-50%)", // Center the button horizontally
                    backgroundColor: invalidStatus
                      ? "#BC123E"
                      : missedStatus
                      ? "#BC123E"
                      : cancelledStatus
                      ? "#BC123E"
                      : errorAccord
                      ? "#BC123E"
                      : "#41BA8F",
                    height: "54px",
                    width: "55px",
                    "&:hover": {
                      backgroundColor: inQueData
                        ? "#EC7A11"
                        : invalidStatus
                        ? "#BC123E"
                        : missedStatus
                        ? "#BC123E"
                        : cancelledStatus
                        ? "#BC123E"
                        : errorAccord
                        ? "#BC123E"
                        : "#41BA8F",
                    },
                    cursor: "default",
                  }}
                >
                  {verifyPage ? (
                    <img src={ScanImage} alt="scanicon" />
                  ) : inQueData ? (
                    <img src={InqueImg} alt="inqueimg" />
                  ) : invalidStatus ? (
                    <img src={InvalidImg} alt="invaliedImg" />
                  ) : missedStatus ? (
                    <img src={MissedImg} alt="missedImg" />
                  ) : cancelledStatus ? (
                    <img src={CancelledImg} alt="canceledImg" />
                  ) : onDateStatus ? (
                    <img src={OnDateImg} alt="ondateimg" />
                  ) : newNumber ? (
                    <img src={OnDateImg} alt="ondateimg" />
                  ) : noAppointments ? (
                    <img src={NoAppointmentsImg} alt="scanicon" />
                  ) : cancelStatus ? (
                    <img src={CancelImg} alt="cancel" />
                  ) : usingMobileSlot ? (
                    <img src={OnDateImg} alt="slot" />
                  ) : thankYouPage ? (
                    <img src={ThankYouImg} alt="ThankYouImg" />
                  ) : errorAccord ? (
                    <PriorityHighIcon sx={{ color: "white" }} />
                  ) : (
                    <img src={ScanImageVerify} alt="scaniconVerify" />
                  )}
                </IconButton>
              </Box>
              <Box
                sx={{
                  minHeight: "150px",
                  alignContent: "center",
                }}
              >
                <Box
                  sx={{
                    marginTop: "40px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box>
                    {enterMobileNum && (
                      <>
                        {" "}
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          {userdata?.data?.hospital_or_clinic_name}
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "#000000",
                              marginTop: "15px",
                              marginRight: "8px",
                            }}
                          >
                            Enter your mobile number
                          </Typography>
                        </Box>
                      </>
                    )}

                    {getPatientCount && (
                      <>
                        {" "}
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          {userdata?.data?.hospital_or_clinic_name}
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: "15px",
                              color: "#000000",
                              marginTop: "15px",
                              marginRight: "8px",
                              padding: "0px 10px",
                              textAlign: "center",
                            }}
                          >
                            How many appointments do you need to schedule?
                          </Typography>
                        </Box>
                      </>
                    )}

                    {yourName && (
                      <>
                        {" "}
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          {userdata?.data?.hospital_or_clinic_name}
                        </Typography>
                      </>
                    )}
                    {ivrAppoinment && (
                      <>
                        {" "}
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          {userdata?.data?.hospital_or_clinic_name}
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "#000000",
                              marginTop: "15px",
                              marginRight: "17px",
                            }}
                          >
                            Enter the Patient Name
                          </Typography>
                        </Box>{" "}
                      </>
                    )}
                    {verifyPage && (
                      <>
                        {" "}
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Verify
                        </Typography>{" "}
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Appointment
                        </Typography>
                      </>
                    )}
                    {inQueData && (
                      <>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Thank you for arriving.
                        </Typography>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          You are placed in que.
                        </Typography>{" "}
                      </>
                    )}
                    {invalidStatus && (
                      <>
                        {" "}
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Your appointment
                        </Typography>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          is invalid.
                        </Typography>
                      </>
                    )}
                    {missedStatus && (
                      <Typography
                        sx={{
                          color: "#231F20",
                          fontWeight: 600,
                          fontSize: "15px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        You missed your fixed
                      </Typography>
                    )}
                    {missedStatus && (
                      <>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          appointment time.
                        </Typography>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Please contact the helpdesk.
                        </Typography>
                      </>
                    )}
                    {cancelledStatus && (
                      <>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Your appointment is cancelled.
                        </Typography>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Please check with the Helpdesk.
                        </Typography>{" "}
                      </>
                    )}
                    {onDateStatus && (
                      <>
                        {" "}
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Your appointment is on
                        </Typography>
                        <Typography
                          sx={{
                            color: "#41BA8F",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          {onDateShowMsg}
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: "15px",
                              color: "#231F20",
                            }}
                          >
                            &nbsp;at&nbsp;
                          </Typography>
                          {onTimeShow}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Please check with the helpdesk.
                        </Typography>
                      </>
                    )}
                    {newNumber && (
                      <>
                        <Box style={{ textAlign: "center" }}>
                          <Typography
                            sx={{
                              color: "#231F20",
                              fontWeight: 600,
                              fontSize: "15px",
                              lineHeight: "25px",
                            }}
                          >
                            Appointment available at
                            <br />
                            <span style={{ color: "#41BA8F" }}>
                              {newNumber}
                            </span>
                            {!!newNumberTwo && (
                              <>
                                &nbsp;and&nbsp;
                                <span style={{ color: "#41BA8F" }}>
                                  {newNumberTwo}
                                </span>
                              </>
                            )}
                            <br />
                            Do you want to confirm?
                            <br />
                            {userType == "SCAN" &&
                              getSlotDuration &&
                              "Please select the slot duration"}
                          </Typography>
                          {userType == "SCAN" && getSlotDuration && (
                            <BasicSelect
                              value={formDataNew.slot_duration || "default"}
                              onChange={(e) =>
                                setFormDataNew((prevData) => ({
                                  ...prevData,
                                  slot_duration: e.target.value,
                                }))
                              }
                              sx={{
                                textAlign: "left",
                                width: "220px",
                                marginTop: "10px",
                              }}
                              onOpen={getSlotTime}
                            >
                              <BasicMenuItem
                                value="default"
                                style={{ display: "none" }}
                              >
                                <span style={{ color: "#bbb7b7" }}>Select</span>
                              </BasicMenuItem>
                              {lineProgressLoading ? (
                                <Box sx={{ width: "100%" }}>
                                  <LinearProgress />
                                </Box>
                              ) : Array.isArray(slots) && slots.length > 0 ? (
                                slots.map((val, i) => (
                                  <BasicMenuItem key={i} value={val}>
                                    {val + " Minutes"}
                                  </BasicMenuItem>
                                ))
                              ) : (
                                <BasicMenuItem disabled>
                                  No Doctors Available
                                </BasicMenuItem>
                              )}
                            </BasicSelect>
                          )}
                        </Box>
                      </>
                    )}
                    {noAppointments && (
                      <>
                        {" "}
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          No appointments are
                        </Typography>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          available for today.
                        </Typography>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Please contact the helpdesk
                        </Typography>
                      </>
                    )}
                    {successStatus && (
                      <>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {successStatus ? (
                            <span style={{ textAlign: "center" }}>
                              Your appointment is confirmed. <br /> Your
                              appointment details will be shared through SMS or
                              WhatsApp.
                            </span>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </>
                    )}
                    {cancelStatus && (
                      <>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {cancelStatus}
                        </Typography>
                      </>
                    )}
                    {usingMobileSlot && (
                      <>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Appointments booked using mobile{" "}
                          {formDataNew.mobile_number}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          on&nbsp;
                          <Typography
                            sx={{
                              color: "#41BA8F",
                              fontWeight: 600,
                              fontSize: "15px",
                              display: "flex",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            {filterDataList?.appointment_date
                              ? UtilDateTime.formatYearMonthAndDayAsInt(
                                  filterDataList?.appointment_date
                                )
                              : "--"}
                          </Typography>
                        </Typography>

                        <Box
                          sx={{
                            height: "70px",
                            overflowY: "scroll",
                            width: "100%",
                            mt: 1,
                          }}
                        >
                          {filterDataList?.data?.map((patient, index) => (
                            <>
                              <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#231F20",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {patient.patientName} at{" "}
                                  <Typography
                                    sx={{ color: "#41BA8F", marginLeft: "6px" }}
                                  >
                                    {UtilDateTime.formatTimeAsHHMMTT(
                                      patient.slotStartTime
                                    )}
                                  </Typography>
                                </Typography>
                              </Box>
                            </>
                          ))}
                        </Box>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                            mt: 2,
                            textAlign: "center",
                          }}
                        >
                          Please contact the helpdesk.
                        </Typography>
                      </>
                    )}

                    {thankYouPage && (
                      <>
                        <Typography
                          sx={{
                            color: "#231F20",
                            marginTop: "6px",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          You do not have an appointment.
                        </Typography>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Thank you!
                        </Typography>
                      </>
                    )}

                    {alreadyInque && (
                      <>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Already In Que
                        </Typography>
                      </>
                    )}

                    {errorAccord && (
                      <>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Error Occured
                        </Typography>
                      </>
                    )}

                    {checkInStatus && (
                      <>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Already Checked In
                        </Typography>
                      </>
                    )}

                    {checkOutStatus && (
                      <>
                        <Typography
                          sx={{
                            color: "#231F20",
                            fontWeight: 600,
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Already checked out
                        </Typography>
                      </>
                    )}

                    {enterMobileNum && (
                      <Box
                        sx={{
                          marginTop: "13px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Box>
                          <input
                            id=""
                            variant="outlined"
                            placeholder="ex: 99999 99999"
                            size="small"
                            name="mobile_number"
                            value={formDataNew.mobile_number}
                            onChange={(e) => mobileNumberOnChange(e)}
                            style={{
                              paddingLeft: "4px",
                              width: "170px", // Make the input full width
                              height: "40px",
                              fontSize: "14px",
                              border: mobileError
                                ? "1px solid red"
                                : "1px solid #C8C7C7",
                              ".MuiOutlinedInput-notchedOutline": { border: 0 },
                              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                            }}
                          />
                          <Typography className="errorValidationTextMobileQr">
                            {mobileError}
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    {yourName && (
                      <Box
                        sx={{
                          marginTop: "13px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          padding: "0px 5px",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: "14px",
                              color: "#000000",
                              height: "35px",
                              marginRight: "17px",
                              alignContent: " center",
                            }}
                          >
                            Patient Name {patientCount == 2 && "1"}
                          </Typography>
                          <TextField
                            id=""
                            variant="outlined"
                            placeholder="ex: John Doe"
                            size="small"
                            error={patientNameError ? patientNameError : ""}
                            name="patient_name_one"
                            value={formDataNew.patient_name_one}
                            onChange={(e) =>
                              patientNameOnChange(e, "patient_name_one")
                            }
                            sx={{
                              width: "170px",
                              height: "35px",
                              fontSize: "14px !important",
                              "& .MuiOutlinedInput-root": {
                                // '&:hover fieldset': {
                                //   borderColor: 'green', // border color on hover
                                // },

                                "&.Mui-focused fieldset": {
                                  borderColor: "#C8C7C7", // border color on focus
                                },
                              },
                            }}
                          />
                        </Box>
                        {patientCount == 2 && (
                          <Box sx={{ display: "flex" }} mt={2}>
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                color: "#000000",
                                height: "35px",
                                marginRight: "17px",
                                alignContent: " center",
                              }}
                            >
                              Patient Name 2
                            </Typography>

                            <TextField
                              id=""
                              variant="outlined"
                              placeholder="ex: John Doe"
                              size="small"
                              error={patientNameError ? patientNameError : ""}
                              name="patient_name_two"
                              value={formDataNew.patient_name_two}
                              onChange={(e) =>
                                patientNameOnChange(e, "patient_name_two")
                              }
                              sx={{
                                width: "170px",
                                height: "35px",
                                fontSize: "14px !important",
                                "& .MuiOutlinedInput-root": {
                                  // '&:hover fieldset': {
                                  //   borderColor: 'green', // border color on hover
                                  // },

                                  "&.Mui-focused fieldset": {
                                    borderColor: "#C8C7C7", // border color on focus
                                  },
                                },
                              }}
                            />
                          </Box>
                        )}
                        {/* <Typography className="errorValidationTextMobileQr">
                            {patientNameError}
                          </Typography> */}
                      </Box>
                    )}
                    {ivrAppoinment &&
                      formDataIvr?.map((ivrData, index) => (
                        <Box
                          sx={{
                            marginTop: "13px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Grid container mb={2} alignItems="center">
                            <Grid
                              item
                              xs={4.5}
                              md={5.5}
                              sx={{ textAlign: "left" }}
                            >
                              <FormLabel
                                htmlFor="patientName"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#000000",
                                  whiteSpace: "nowrap",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {/* {isMd && "Enter the "} */}
                                Patient Name {index + 1}
                              </FormLabel>
                            </Grid>
                            <Grid item xs={7.5} md={6.5}>
                              <TextField
                                id=""
                                variant="outlined"
                                placeholder="ex: John Doe"
                                size="small"
                                error={!!ivrData.error ? ivrData.error : ""}
                                name="patient_name"
                                value={ivrData.patient_name}
                                onChange={(e) =>
                                  ivrPatientNameOnChange(e, index)
                                }
                                sx={{
                                  // width: "170px",
                                  height: "40px",
                                  fontSize: "14px !important",
                                  "& .MuiOutlinedInput-root": {
                                    // '&:hover fieldset': {
                                    //   borderColor: 'green', // border color on hover
                                    // },

                                    "&.Mui-focused fieldset": {
                                      borderColor: "#C8C7C7", // border color on focus
                                    },
                                  },
                                }}
                              />
                              {/* <Typography className="errorValidationTextMobileQr">
                          {ivrData?.error || ""}
                        </Typography> */}
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                  </Box>
                </Box>

                <Box sx={{ marginTop: "10px" }}>
                  {inQueData ||
                  invalidStatus ||
                  missedStatus ||
                  cancelledStatus ||
                  onDateStatus ||
                  noAppointments ||
                  successStatus ||
                  cancelStatus ||
                  usingMobileSlot ||
                  thankYouPage ||
                  alreadyInque ||
                  errorAccord ||
                  checkInStatus ||
                  checkOutStatus ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        // marginTop: "10px",
                      }}
                    >
                      {/* !successStatus */}
                      {false && (
                        <Button
                          sx={{
                            mt: onDateStatus ? 1 : 3,
                            mb: 2,
                            mr: 1,
                            backgroundColor: "#1A71FF",
                            color: "#FFFFFF",
                            fontSize: "13px",
                            border: "1px solid #FFF",
                            borderRadius: "50px",
                            height: "30px",
                            "&:hover": { background: "#1A71FF" },
                            width: "100px",
                            fontWeight: 600,
                          }}
                          onClick={closeTheCurrentPage}
                        >
                          Ok
                        </Button>
                      )}
                    </Box>
                  ) : getPatientCount ? (
                    <Box
                      sx={{
                        marginTop: "13px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <BasicButton
                        type="submit"
                        name="patientCount1"
                        id="patientCount"
                        sx={{
                          backgroundColor: "#243665 !important",
                          color: "#fff",
                          mr: 2,
                        }}
                      >
                        One
                      </BasicButton>
                      <BasicButton
                        type="submit"
                        name="patientCount2"
                        id="patientCount"
                      >
                        Two
                      </BasicButton>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      {newNumber ? (
                        <Button
                          sx={{
                            mt: 2,
                            mb: 2,
                            mr: 1,
                            backgroundColor: "#D13C3C",
                            color: "#FFFFFF",
                            fontSize: "13px",
                            border: "1px solid #FFF",
                            borderRadius: "50px",
                            height: "30px",
                            "&:hover": { background: "#D13C3C" },
                            width: "100px",
                            fontWeight: 600,
                          }}
                          onClick={closeTheNoButton}
                        >
                          No
                        </Button>
                      ) : !enterMobileNum ? (
                        <Button
                          sx={{
                            mt: 3,
                            mb: 2,
                            mr: 2,
                            backgroundColor: "#D13C3C",
                            color: "#FFFFFF",
                            fontSize: "13px",
                            border: "1px solid #FFF",
                            borderRadius: "50px",
                            height: "30px",
                            "&:hover": { background: "#D13C3C" },
                            width: "100px",
                            fontWeight: 600,
                          }}
                          onClick={closeTheCancelButton}
                        >
                          Cancel
                        </Button>
                      ) : (
                        ""
                      )}
                      <Button
                        type={
                          enterMobileNum
                            ? "submit"
                            : yourName || ivrAppoinment
                            ? "submit"
                            : "button"
                        }
                        disabled={
                          (enterMobileNum &&
                            formDataNew.mobile_number.length == 0) ||
                          (yourName &&
                            formDataNew.patient_name_one.length == 0) ||
                          (patientCount == 2 &&
                            yourName &&
                            formDataNew.patient_name_two.length == 0) ||
                          (newNumber &&
                            userType == "SCAN" &&
                            !formDataNew.slot_duration) ||
                          (ivrAppoinment &&
                            formDataIvr.some((val) => val.patient_name == ""))
                        }
                        sx={{
                          mt: newNumber ? 2 : enterMobileNum ? 2 : 3,
                          mb: 2,
                          backgroundColor: "#41BA8F",
                          color: "#FFFFFF",
                          border: "1px solid #FFF",
                          fontSize: "13px",
                          borderRadius: "50px",
                          height: "30px",
                          "&:hover": { background: "#41BA8F" },
                          width: "100px",
                          fontWeight: 600,
                        }}
                        onClick={navigatNamePage}
                      >
                        {enterMobileNum
                          ? "Submit"
                          : newNumber
                          ? "Yes"
                          : yourName || ivrAppoinment
                          ? "Submit"
                          : ""}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Card>
          </Box>
        </Grid>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loaderopen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </>
  );
}
