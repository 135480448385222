import React, { useEffect, useState } from "react";
import { Backdrop, Box, Grid, Typography } from "@mui/material";
import Controller from "../../../Controller/ApiController";
import CircularProgress from "@mui/material/CircularProgress";
import QGLogo from "../../../Assets/qgliderlogo.png";

export default function TermsAndConditions() {
  const [loaderOpen, setLoaderOpen] = useState(false);
  const [content, setContent] = useState(``);

  const getTermsAndCondition = async () => {
    try {
      setLoaderOpen(true);
      const response = await Controller.ApiController(
        "GET",
        "/privacy_policy",
        "",
        ""
      );
      setLoaderOpen(false);
      if (response.type == "success") {
        setContent(response?.terms_and_condition || "");
      } else {
        console.log(
          "Terms and Condition Response Error: ",
          response.error.message
        );
      }
    } catch (e) {
      setLoaderOpen(false);
      console.log("Terms and Condition Error: ", e.message);
    }
  };

  useEffect(() => {
    getTermsAndCondition();
  }, []);

  return (
    <Box sx={{ width: "100%", padding: 2 }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={2}
          xl={2}
          textAlign={{ xs: "center", sm: "left" }}
        >
          <Box
            component={"img"}
            src={QGLogo}
            sx={{
              width: { xs: "159px", md: "256px" },
              height: { xs: "69px", md: "91px" },
            }}
          />
        </Grid>
        {/* <Grid item xs={12} sm={10} xl={8} textAlign={"center"}>
          <Typography
            sx={{
              height: "92px",
              alignContent: "center",
              fontWeight: 700,
              fontFamily: "Helvetica Neue",
              fontSize: { xs: "30px", md: "45px" },
              color: "#000",
            }}
          >
            Terms and Conditions for QGlider
          </Typography>
        </Grid> */}
        <Grid item xs={12} mt={2}>
          <Typography dangerouslySetInnerHTML={{ __html: content }} />
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 100000 }}
        open={loaderOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
