import React, { useEffect, useRef, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  FormLabel,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Typography,
  IconButton,
  InputAdornment,
  Menu,
} from "@mui/material";
import "../Assets/css/custom-class.css";
import validationRegExp from "../Configuration/ValidationConfig";
import Controller from "../Controller/ApiController";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert } from "../Components/ReuseComponents/Alert/Alertmessage";
import "../Popup/bookappointment.css";
import cancellIcon from "../Assets/images/DeletePopup/cancelIcon.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import {
  BasicMenuItem,
  BasicSelect,
} from "../Components/ReuseComponents/Select/BasicSelect";
import { OutlinedTextField } from "../Components/ReuseComponents/TextField/BasicTextField";
import { ContainedButton } from "../Components/ReuseComponents/Button/BasicButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "650px !important",
  height: "auto",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  "@media (min-width: 720px) ": {
    width: "80%",
    // height:"auto",
  },
};

const paymentForList = [
  {
    value: "VACCINE",
    label: "Vaccine",
  },
  {
    value: "CONSULTATION",
    label: "Consultation",
  },
  {
    value: "VACCINE_AND_CONSULTATION",
    label: "Vaccine and Consultation",
  },
];

const modeOfPaymentList = [
  {
    value: "CASH",
    label: "Cash",
  },
  {
    value: "CARD",
    label: "Card",
  },
  {
    value: "UPI",
    label: "UPI",
  },
  {
    value: "UPI_AND_CASH",
    label: "UPI and Cash",
  },
];

export default function PaymentInformation({
  open,
  onClose,
  appointmentData,
  updateCount,
}) {
  const [openCancelModel, setOpenCancelModel] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuType, setMenuType] = useState("");
  const vaccineRef = useRef();
  const consultationRef = useRef();

  const userDetails = useSelector((state) => state?.userDetails?.data || {});

  const Location = useLocation();

  const [loaderopen, setloaderOpen] = React.useState(false);
  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };

  const handlePopoverOpen = (event, type) => {
    setAnchorEl(event.currentTarget);
    setMenuType(type);
  };

  const handleClose = (type) => {
    setAnchorEl(null);
    if (type == "ADD") {
      if (menuType == "VACCINE") {
        setFormData((prevData) => ({
          ...prevData,
          vaccinationFees: {
            value: "N/A",
            error: "",
          },
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          consultationFees: {
            value: "N/A",
            error: "",
          },
        }));
      }
    }
  };

  const intialFormData = {
    paymentFor: {
      value: "",
      error: false,
    },
    vaccinationName: {
      value: "",
      error: false,
    },
    vaccinationFees: {
      value: "",
      error: false,
    },
    consultationFees: {
      value: "",
      error: false,
    },
    modeOfPayment: {
      value: "",
      error: false,
    },
    cash: {
      value: "",
      error: false,
    },
    upi: {
      value: "",
      error: false,
    },
  };

  const [formData, setFormData] = useState(intialFormData);

  const cancelModel = () => {
    setOpenCancelModel(true);
  };

  const cancelModelclose = () => {
    setOpenCancelModel(false);
    onClose();
  };

  const cancelModelcloseChild = () => {
    setOpenCancelModel(false);
  };

  const convertAmount = (value) => {
    if (!value || isNaN(parseInt(value))) {
      return 0;
    }
    return parseInt(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let updateError = formData;
    let formError = false;

    if (formData.paymentFor.value == "") {
      updateError.paymentFor.error = true;
      formError = true;
    }

    if (formData.modeOfPayment.value == "") {
      updateError.modeOfPayment.error = true;
      formError = true;
    }
    let totalFees = 0;

    //CONDITION FOR VALIDATE VACCINE AND CONSULTATION, CONSULTATION
    if (
      (formData.paymentFor.value == "CONSULTATION" ||
        formData.paymentFor.value == "VACCINE_AND_CONSULTATION") &&
      (formData.consultationFees.value == "" ||
        convertAmount(formData.consultationFees.value) < 1) &&
      formData.consultationFees.value != "N/A"
    ) {
      updateError.consultationFees.error = true;
      formError = true;
    } else {
      if (
        (formData.modeOfPayment.value == "N/A" ||
          formData.modeOfPayment.value == "") &&
        formData.consultationFees.value != "N/A" &&
        formData.consultationFees.value != ""
      ) {
        updateError.modeOfPayment.error = true;
        formError = true;
      }
      totalFees += convertAmount(formData?.consultationFees?.value);
    }

    //CONDITION FOR VALIDATE VACCINE AND CONSULTATION, VACCINE
    if (
      (formData.paymentFor.value == "VACCINE" ||
        formData.paymentFor.value == "VACCINE_AND_CONSULTATION") &&
      formData.vaccinationName.value == ""
    ) {
      updateError.vaccinationName.error = true;
      formError = true;
    }

    if (
      (formData.paymentFor.value == "VACCINE" ||
        formData.paymentFor.value == "VACCINE_AND_CONSULTATION") &&
      (formData.vaccinationFees.value == "" ||
        convertAmount(formData.vaccinationFees.value) < 1) &&
      formData.vaccinationFees.value != "N/A"
    ) {
      updateError.vaccinationFees.error = true;
      formError = true;
    } else {
      if (
        (formData.modeOfPayment.value == "N/A" ||
          formData.modeOfPayment.value == "") &&
        formData.vaccinationFees.value != "N/A" &&
        formData.vaccinationFees.value != ""
      ) {
        updateError.modeOfPayment.error = true;
        formError = true;
      }
      totalFees += convertAmount(formData?.vaccinationFees?.value || 0);
    }
    let totalPayment = 0;

    if (
      formData.modeOfPayment.value == "UPI_AND_CASH" &&
      ((formData.vaccinationFees.value != "N/A" &&
        formData.vaccinationFees.value != "") ||
        (formData.consultationFees.value != "N/A" &&
          formData.consultationFees.value != ""))
    ) {
      if (formData.upi.value == "" || convertAmount(formData.upi.value) < 1) {
        updateError.upi.error = true;
        formError = true;
      } else {
        totalPayment += convertAmount(formData?.upi?.value || 0);
      }
      if (formData.cash.value == "" || convertAmount(formData.cash.value) < 1) {
        updateError.cash.error = true;
        formError = true;
      } else {
        totalPayment += convertAmount(formData?.cash?.value || 0);
      }

      if (
        formData.upi.value != "" &&
        formData.cash.value != "" &&
        totalFees != totalPayment
      ) {
        updateError.upi.error = true;
        updateError.cash.error = true;
        formError = true;
        Alert("error", "The entered value is incorrect");
      }
    }

    if (formError) {
      setFormData({ ...updateError });
      return;
    }

    let parseFormData = formData;

    if (
      formData.paymentFor.value == "VACCINE" &&
      formData.vaccinationFees.value == "N/A"
    ) {
      parseFormData.modeOfPayment.value = "N/A";
      parseFormData.upi.value = "";
      parseFormData.cash.value = "";
    }

    if (
      formData.paymentFor.value == "CONSULTATION" &&
      formData.consultationFees.value == "N/A"
    ) {
      parseFormData.modeOfPayment.value = "N/A";
      parseFormData.upi.value = "";
      parseFormData.cash.value = "";
    }

    if (
      formData.paymentFor.value == "VACCINE_AND_CONSULTATION" &&
      formData.vaccinationFees.value == "N/A" &&
      formData.consultationFees.value == "N/A"
    ) {
      parseFormData.modeOfPayment.value = "N/A";
      parseFormData.upi.value = "";
      parseFormData.cash.value = "";
    }

    handleLoaderOpen();
    const postData = {
      payment_for: parseFormData.paymentFor.value,
      vaccine_fee: parseFormData.vaccinationFees.value,
      vaccine_name: parseFormData.vaccinationName.value,
      consultation_fee: parseFormData.consultationFees.value,
      method_of_payment: parseFormData.modeOfPayment.value,
      upi_amt: parseFormData.upi.value,
      cash_amt: parseFormData.cash.value,
    };

    const response = await Controller.ApiController(
      "PUT",
      `/payment/${appointmentData.appointment_uid}`,
      "?",
      postData
    );

    handleLoaderClose();

    if (response.type == "success") {
      Alert("success", response.message);
      updateCount();
      onClose();
    } else {
      Alert("error", response?.error?.message || "Something Went Wrong");
    }
  };

  useEffect(() => {
    if (appointmentData) {
      setFormData((prevData) => ({
        ...prevData,
        paymentFor: {
          value: appointmentData.payment_for || "",
          error: false,
        },
        vaccinationName: {
          value: appointmentData?.vaccine_name || "",
          error: false,
        },
        vaccinationFees: {
          value: appointmentData.vaccine_fee,
          error: false,
        },
        consultationFees: {
          value: appointmentData.consultation_fee,
          error: false,
        },
        modeOfPayment: {
          value: appointmentData.method_of_payment,
          error: false,
        },
        upi: {
          value: appointmentData?.upi_amt || "",
          error: false,
        },
        cash: {
          value: appointmentData?.cash_amt || "",
          error: false,
        },
      }));
    }
  }, [appointmentData]);

  const handleChange = (e) => {
    const { name, value, id } = e.target;

    if (
      !validationRegExp.noEmptySpaceStart(value) ||
      (id && !validationRegExp[id](value))
    ) {
      if (
        !(
          (name == "vaccinationFees" || name == "consultationFees") &&
          new RegExp(/^N(\/A?)?$/).test(value)
        )
      ) {
        return;
      }
    }

    let updateData = formData;
    if (name == "paymentFor") {
      if (value != "VACCINE" && value != "VACCINE_AND_CONSULTATION") {
        updateData.vaccinationFees.value = "";
        updateData.vaccinationName.value = "";
      }

      if (
        value != "CONSULTATION" &&
        value != "VACCINE_AND_CONSULTATION" &&
        formData.consultationFees.value != ""
      ) {
        updateData.consultationFees.value = "";
      }

      if (
        formData.modeOfPayment.value == "UPI_AND_CASH" &&
        formData.upi.value != ""
      ) {
        updateData.upi.value = "";
      }

      if (
        formData.modeOfPayment.value == "UPI_AND_CASH" &&
        formData.cash.value != ""
      ) {
        updateData.cash.value = "";
      }
    }

    if (name == "modeOfPayment") {
      if (value != "UPI_AND_CASH" && formData.upi.value != "") {
        updateData.upi.value = "";
      }

      if (value != "UPI_AND_CASH" && formData.cash.value != "") {
        updateData.cash.value = "";
      }
    }

    if (name == "upi" && updateData.cash.error) {
      updateData.cash.error = false;
    }

    if (name == "cash" && updateData.upi.error) {
      updateData.upi.error = false;
    }

    setFormData((prevData) => ({
      ...prevData,
      ...updateData,
      [name]: {
        value: value,
        error: false,
      },
    }));
  };

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={open}
      >
        <Backdrop
          open={open}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Paper
            className="bookmodal"
            sx={{
              width: { xs: "95%", sm: "95%" },
              maxWidth: { xs: "500px", md: "1000px" }, // Set the maximum width for larger screens
              margin: "auto",
            }}
          >
            <Grid sx={{ borderRadius: "15px" }} container>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: "#EEF0FA",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                }}
              >
                <Box
                  sx={{
                    padding: "10px 25px",
                    display: "flex",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#243665",
                      marginLeft: "0.6px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Payment Information for Token #
                    {appointmentData?.token || ""}
                  </Typography>
                </Box>
              </Grid>
              <hr />
              <Grid item md={12} xs={12} sm={12}>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{ padding: "0px 25px" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={5} md={6}>
                          <FormLabel
                            htmlFor="paymentFor"
                            sx={{
                              fontSize: "14px",
                              fontWeight: 400,
                              whiteSpace: "nowrap",
                              color: "#000000",
                              // backgroundColor:"red"
                            }}
                          >
                            Payment For
                          </FormLabel>
                        </Grid>
                        <Grid item xs={7} md={6}>
                          <BasicSelect
                            name="paymentFor"
                            value={formData?.paymentFor?.value || "default"}
                            error={formData?.paymentFor?.error}
                            onChange={handleChange}
                          >
                            <MenuItem value="default" sx={{ display: "none" }}>
                              <span style={{ color: "#bbb7b7" }}>Select</span>
                            </MenuItem>
                            {paymentForList.map((mode, index) => (
                              <BasicMenuItem key={index} value={mode.value}>
                                {mode.label}
                              </BasicMenuItem>
                            ))}
                          </BasicSelect>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={5} md={6}>
                          <FormLabel
                            htmlFor="modeOfPayment"
                            sx={{
                              fontSize: "14px",
                              fontWeight: 400,
                              whiteSpace: "nowrap",
                              color: "#000000",
                              // backgroundColor:"red"
                            }}
                          >
                            Mode of Payment
                          </FormLabel>
                        </Grid>
                        <Grid item xs={7} md={6}>
                          <BasicSelect
                            name="modeOfPayment"
                            value={formData?.modeOfPayment?.value || "default"}
                            error={formData?.modeOfPayment?.error}
                            onChange={handleChange}
                          >
                            <MenuItem value="default" sx={{ display: "none" }}>
                              <span style={{ color: "#bbb7b7" }}>Select</span>
                            </MenuItem>
                            <BasicMenuItem value="N/A" sx={{ display: "none" }}>
                              N/A
                            </BasicMenuItem>
                            {modeOfPaymentList.map((mode, index) => (
                              <BasicMenuItem key={index} value={mode.value}>
                                {mode.label}
                              </BasicMenuItem>
                            ))}
                          </BasicSelect>
                        </Grid>
                      </Grid>
                    </Grid>
                    {["VACCINE", "VACCINE_AND_CONSULTATION"].includes(
                      formData.paymentFor.value
                    ) && (
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={5} md={6}>
                            <FormLabel
                              htmlFor="vaccinationName"
                              disabled={
                                ![
                                  "VACCINE",
                                  "VACCINE_AND_CONSULTATION",
                                ].includes(formData.paymentFor.value)
                              }
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#000000",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Vaccination Name
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7} md={6}>
                            <OutlinedTextField
                              id="alphaNumericHifen"
                              variant="outlined"
                              placeholder="Enter here"
                              size="small"
                              name="vaccinationName"
                              error={formData?.vaccinationName?.error}
                              disabled={
                                ![
                                  "VACCINE",
                                  "VACCINE_AND_CONSULTATION",
                                ].includes(formData.paymentFor.value)
                              }
                              sx={{
                                width: "100%",
                                // height: "40px",
                                fontSize: "14px",
                                "& .MuiOutlinedInput-root": {
                                  fontSize: "14px",
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#C8C7C7", // border color on focus
                                  },
                                },
                              }}
                              value={formData?.vaccinationName?.value || ""}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {/* {["VACCINE_AND_CONSULTATION"].includes(
                      formData.paymentFor.value
                    ) && (
                      <Grid item display={{xs: "none", md: "block"}} md={6}></Grid>)} */}

                    {["VACCINE", "VACCINE_AND_CONSULTATION"].includes(
                      formData.paymentFor.value
                    ) && (
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={5} md={6}>
                            <FormLabel
                              htmlFor="vaccinationFees"
                              disabled={
                                ![
                                  "VACCINE",
                                  "VACCINE_AND_CONSULTATION",
                                ].includes(formData.paymentFor.value)
                              }
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#000000",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Vaccination Fees
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7} md={6}>
                            <OutlinedTextField
                              id="number"
                              variant="outlined"
                              placeholder="Enter here"
                              size="small"
                              name="vaccinationFees"
                              error={formData?.vaccinationFees?.error}
                              inputRef={vaccineRef}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={(e) =>
                                        handlePopoverOpen(e, "VACCINE")
                                      }
                                      sx={{ padding: "0px" }}
                                    >
                                      <ArrowDropDownIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              disabled={
                                ![
                                  "VACCINE",
                                  "VACCINE_AND_CONSULTATION",
                                ].includes(formData.paymentFor.value)
                              }
                              inputProps={{
                                maxLength: 6,
                              }}
                              sx={{
                                width: "100%",
                                // height: "40px",
                                fontSize: "14px",
                                "& .MuiOutlinedInput-root": {
                                  fontSize: "14px",
                                  paddingRight: "7px",
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#C8C7C7", // border color on focus
                                  },
                                },
                              }}
                              value={formData?.vaccinationFees?.value || ""}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {["CONSULTATION", "VACCINE_AND_CONSULTATION"].includes(
                      formData.paymentFor.value
                    ) && (
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={5} md={6}>
                            <FormLabel
                              htmlFor="consultationFees"
                              disabled={
                                ![
                                  "CONSULTATION",
                                  "VACCINE_AND_CONSULTATION",
                                ].includes(formData.paymentFor.value)
                              }
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#000000",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Consultation Fees
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7} md={6}>
                            <OutlinedTextField
                              id="number"
                              variant="outlined"
                              placeholder="Enter here"
                              size="small"
                              name="consultationFees"
                              disabled={
                                ![
                                  "CONSULTATION",
                                  "VACCINE_AND_CONSULTATION",
                                ].includes(formData.paymentFor.value)
                              }
                              error={formData?.consultationFees?.error}
                              inputProps={{
                                maxLength: 6,
                              }}
                              inputRef={consultationRef}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={(e) =>
                                        handlePopoverOpen(e, "CONSULTATION")
                                      }
                                      sx={{ padding: "0px" }}
                                    >
                                      <ArrowDropDownIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              sx={{
                                width: "100%",
                                // height: "40px",
                                fontSize: "14px",
                                "& .MuiOutlinedInput-root": {
                                  fontSize: "14px",
                                  paddingRight: "7px",
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#C8C7C7", // border color on focus
                                  },
                                },
                              }}
                              value={formData?.consultationFees?.value || ""}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {/* {["CONSULTATION"].includes(formData.paymentFor.value) &&
                      formData.modeOfPayment.value == "UPI_AND_CASH" && (
                        <Grid item xs={12} md={6}></Grid>
                      )} */}
                    {formData.modeOfPayment.value == "UPI_AND_CASH" && (
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={5} md={6}>
                            <FormLabel
                              htmlFor="UPI"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#000000",
                                whiteSpace: "nowrap",
                              }}
                            >
                              UPI
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7} md={6}>
                            <OutlinedTextField
                              id="number"
                              variant="outlined"
                              placeholder="Enter here"
                              size="small"
                              name="upi"
                              error={formData?.upi?.error}
                              inputProps={{
                                maxLength: 15,
                              }}
                              sx={{
                                width: "100%",
                                // height: "40px",
                                fontSize: "14px",
                                "& .MuiOutlinedInput-root": {
                                  fontSize: "14px",
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#C8C7C7", // border color on focus
                                  },
                                },
                              }}
                              value={formData?.upi?.value || ""}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {formData.modeOfPayment.value == "UPI_AND_CASH" && (
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={5} md={6}>
                            <FormLabel
                              htmlFor="cash"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#000000",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Cash
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7} md={6}>
                            <OutlinedTextField
                              id="number"
                              variant="outlined"
                              placeholder="Enter here"
                              size="small"
                              name="cash"
                              error={formData?.cash?.error}
                              inputProps={{
                                maxLength: 15,
                              }}
                              sx={{
                                width: "100%",
                                // height: "40px",
                                fontSize: "14px",
                                "& .MuiOutlinedInput-root": {
                                  fontSize: "14px",
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#C8C7C7", // border color on focus
                                  },
                                },
                              }}
                              value={formData?.cash?.value || ""}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={5} md={6}>
                          <FormLabel
                            htmlFor="QGCharge"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#000000",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Appointment Service Charge
                          </FormLabel>
                        </Grid>
                        <Grid item xs={7} md={6}>
                          <OutlinedTextField
                            id="number"
                            variant="outlined"
                            placeholder="Enter here"
                            size="small"
                            name="QGCharge"
                            inputProps={{
                              maxLength: 15,
                            }}
                            sx={{
                              width: "100%",
                              // height: "40px",
                              fontSize: "14px",
                              "& .MuiOutlinedInput-root": {
                                fontSize: "14px",
                                "&.Mui-focused fieldset": {
                                  borderColor: "#C8C7C7", // border color on focus
                                },
                              },
                            }}
                            disabled
                            value={userDetails?.qg_charge || ""}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box display={"flex"} alignItems={"center"} mt={2}>
                    <Button
                      sx={{
                        // mt: 3,
                        mb: 2,
                        mr: 2,
                        backgroundColor: "#D13C3C",
                        color: "#FFFFFF",
                        border: "1px solid #FFF",
                        borderRadius: "50px",
                        height: "40px",
                        "&:hover": { background: "#D13C3C" },
                        width: "154px",
                        textTransform: "none",
                      }}
                      onClick={cancelModel}
                    >
                      Cancel
                    </Button>
                    <ContainedButton
                      type="submit"
                      disabled={disabledSubmit}
                      sx={{
                        // mt: 3,
                        mb: 2,
                        borderRadius: "50px",
                        height: "40px",
                        width: "154px",
                      }}
                    >
                      Submit
                    </ContainedButton>
                  </Box>

                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loaderopen}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Backdrop>
      </Modal>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleClose("CLOSE")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ width: "100%" }}
        slotProps={{
          paper: {
            sx: {
              width:
                menuType == "VACCINE" && vaccineRef.current
                  ? vaccineRef.current.offsetWidth
                  : menuType == "CONSULTATION" && consultationRef.current
                  ? consultationRef.current.offsetWidth
                  : "auto", // Set width to match TextField
            },
          },
        }}
      >
        <MenuItem
          onClick={() => handleClose("ADD")}
          sx={{ width: "100%", padding: "0px", pl: "10px" }}
        >
          N/A
        </MenuItem>
      </Menu>

      {openCancelModel && (
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          open={cancelModel}
        >
          <Backdrop
            open={cancelModel}
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <Paper sx={{ width: "350px", borderRadius: "10px" }}>
              <Grid sx={{ borderRadius: "15px" }} container>
                <Grid
                  item
                  xs={12}
                  sx={{ backgroundColor: "#EEF0FA", borderRadius: "10px" }}
                >
                  <Box
                    sx={{
                      height: "70px",
                      backgroundColor: "#FFD7D7",
                      borderRadius: "10px 10px 0px 0px",
                    }}
                  >
                    <IconButton
                      sx={{
                        marginTop: "35px",
                        marginLeft: "142px",
                        backgroundColor: "#D13C3C",
                        height: "54px",
                        width: "55px",
                        "&:hover": { backgroundColor: "#D13C3C" },
                        cursor: "default",
                      }}
                    >
                      <img src={cancellIcon} alt="deleteicon" />
                    </IconButton>
                  </Box>
                </Grid>
                <hr />
                <Grid item md={12} xs={12} sm={12} sx={{ padding: "0% 5% 5%" }}>
                  <Box>
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <Typography
                          mt={2}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Are you sure you want
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          to cancel the activity ?
                        </Typography>
                      </Grid>
                    </Grid>

                    <Box>
                      <Box
                        sx={{ display: "flex", justifyContent: "center" }}
                        mt={2}
                      >
                        <Button
                          sx={{
                            mr: 2,
                            backgroundColor: "#000000",
                            color: "#FFFFFF",
                            height: "25px",
                            "&:hover": { background: "#000000" },
                            width: "95px",
                            fontSize: "13px",
                            fontWeight: 600,
                            borderRadius: "30px",
                            textTransform: "none",
                          }}
                          onClick={cancelModelcloseChild}
                        >
                          No
                        </Button>
                        <Button
                          sx={{
                            backgroundColor: "#41BA8F",
                            color: "#FFFFFF",
                            height: "25px",
                            "&:hover": { background: "#41BA8F" },
                            width: "95px",
                            fontSize: "13px",
                            fontWeight: 600,
                            borderRadius: "30px",
                            textTransform: "none",
                          }}
                          onClick={cancelModelclose}
                        >
                          Yes
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Backdrop>
        </Modal>
      )}
      {/* {openCancelModel && (
        <CancelAppointmentChild
          openModalCancel={cancelModel}
          closeModalCancel={cancelModelclose}
          cancelModelcloseChild={cancelModelcloseChild}
        />
      )} */}
    </>
  );
}
